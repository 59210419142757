import { IFile, TFileId } from '../models/files.model';
import { FileHelper } from '../utils/fileHelper.util';
import axios, { CancelTokenSource } from 'axios';
import { EFileStatus } from '../enums/file-uploader/file-status.enum';

export class FileTemp {
    public progress = 0;
    public error = '';
    public status = EFileStatus.Default;
    public token: CancelTokenSource;
    public tempId: TFileId = FileHelper.getTempId();
    public sourceId: string | null = null;

    constructor(
      public file?: File | IFile,
      public isUploaded = false,
    ) {
        this.sourceId = file?.sourceId;
        this.refreshCancelToken();
    }

    public static getReactiveInstance(file?: File | IFile, isUploaded = false): FileTemp {
        return reactive(new FileTemp(file, isUploaded));
    }

    public setError(error: string): void {
        this.error = error;
    }

    public updateProgress(progress: number): void {
        this.progress = progress;
    }

    public onUploaded(file: IFile): void {
        this.file = file;
        this.isUploaded = true;
    }

    public setStatus(status: EFileStatus): void {
        this.status = status;
        this.refreshCancelToken();
    }

    public refreshCancelToken(): void {
        this.token = axios?.CancelToken?.source();
    }

    public cancelToken(): void {
        this.token?.cancel();
        this.refreshCancelToken();
    }

    public static isFileTempInstance(entity: unknown): boolean {
        return entity instanceof FileTemp;
    }
  }
