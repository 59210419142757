<template>
  <Modal
    title-text="Уточните адрес"
    :max-height="613"
    :max-width="582"
    classes="modal fixed-bottom-modal mm-select-delivery-address-modal"
    hide-cancel-button
    ok-button-text="Подтвердить заказ"
    :disabled-ok-button="!selectedValue"
    @close="$emit('close')"
    @ok="onConfirm"
  >
    <div class="mm-select-delivery-address__container">
      <div class="mm-select-delivery-address_label">
        Уточните точное местоположение получателя по адресу
        <span class="bold">{{ basesName }}:</span>
      </div>

      <TextField
        v-model="searchText"
        placeholder="Поиск"
        :icon-path="EIconPath.NavigationSearchSvg"
        class="mm-select-delivery-address_input"
      />

      <PerfectScrollbar
        v-if="buttonsFowShow.length"
        class="mm-select-delivery-address-results"
      >
        <RadioGroup
          v-model="selectedValue"
          :buttons="buttonsFowShow"
          column
          class="mm-select-delivery-address__radio-group"
          is-disable-emit-value-by-btn-list-change
        />
      </PerfectScrollbar>

      <div
        v-else
        class="mm-select-delivery-address_empty"
      >
        К сожалению, ничего не найдено.<br>Проверьте правильность ввода.
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import Modal from 'shared/components/modals/Modal.vue';
import TextField from 'shared/components/TextField.vue';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { IRadioButton } from 'shared/models/radioGroup.model';
import { IDeliveryDestination } from 'shared/models/bases.model';

const props = defineProps<{
  deliveryDestinations: Array<IDeliveryDestination>;
  basesName: string;
}>();

const emit = defineEmits<{
  (e: 'submit', deliveryDestinationId: number): void;
  (e: 'close'): void;
}>();

const searchText = ref('');
const selectedValue = ref<number>();

const buttons = computed<Array<IRadioButton<number>>>(
  () => props.deliveryDestinations.map((deliveryDestination) => ({
    value: deliveryDestination.id,
    label: deliveryDestination.name,
  })),
);

const buttonsFowShow = computed<Array<IRadioButton<number>>>(
  () => buttons.value
    .filter(
      (button) => button.label?.toLowerCase()?.includes(searchText.value?.toLowerCase()),
    ),
);

function onConfirm(): void {
  if (!selectedValue.value) {
    return;
  }

  emit('submit', selectedValue.value);
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

:deep(.mm-select-delivery-address-modal) {
  .modal-content {
    padding: 0;
    height: 613px;
  }

  .modal-header {
    padding: 40px 40px 0 40px;
  }

  .modal-inner-content {
    padding: 0 40px;
    display: flex;
    overflow-y: auto;
    flex: 1;
  }

  .modal-footer {
    margin-top: 0;
    padding: 20px 40px;
    border-top: 1px solid $dark-gray;
  }

  @media only screen and (min-width: 768px) and (max-width: 1279px) {
    .modal-header {
      padding: 36px 36px 0 36px;
    }

    .modal-inner-content {
      padding: 0 36px;
    }

    .modal-footer {
      padding: 20px 36px
    }
  }

  @media only screen and (max-width: 767px) {
    .modal-header {
      padding: 20px 20px 0 20px;
    }

    .modal-inner-content {
      padding: 0 40px 0 20px;
    }

    .modal-footer {
      padding: 20px;
    }
  }
}

:deep(.mm-select-delivery-address__radio-group) {
  padding-right: 5px;

  .radio-group__item {
    .black {
      font-weight: 500;
    }
  }
}

.mm-select-delivery-address  {
  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-results {
    overflow-y: auto;
    flex: 1;
    min-height: 200px;
  }

  &_label {
    .bold {
      font-weight: 500;
    }
  }

  &_input {
    margin-top: 20px;
    padding-bottom: 0;
    margin-bottom: 24px;

    :deep(.mm-input__icon) {
      width: 24px;
      height: 24px;
    }
  }

  &_empty {
    min-height: 200px;
    flex: 1;
    color: $light-green;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>