import { clientSentry } from '../utils/sentry/clientSentry.util';

export default defineNuxtPlugin((nuxtApp) => {
  clientSentry.init(nuxtApp.vueApp, nuxtApp.$router);

  return {
    provide: {
      sentry: clientSentry,
    },
  };
});
