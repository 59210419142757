export enum EValidatorsMessages {
  Required = 'Обязательное поле',
  Integer = 'Значение должно быть целым',
  Min = 'Значение должно быть больше {min}',
  Max = 'Значение должно быть меньше {max}',
  OnlyNumbers = 'Поле должно содержать только цифры',
  MaxLength = 'Длина строки должна быть не более {maxLength}',
  MinLength = 'Длина строки должна быть не менее {minLength}',
  Email = 'Введен некорректный адрес электронной почты. Проверьте правильность ввода, возможно допущена ошибка',
  EmailTaken = 'Аккаунт с указанной электронной почтой уже существует',
  ChangeEmailTaken = 'Данный адрес электронной почты уже зарегистрирован в системе',
  NeededLengths = 'Длина должна быть равна {neededLength}',
  Inn = 'Неверный номер ИНН',
  Ogrn = 'Неверный номер ОГРН',
  Url = 'Неверный формат веб-адреса',
  PhoneNumber = 'Некорректный номер телефона',
  IsEqual = 'Должен совпадать',
  Password = 'Пароль должен содержать от 8 символов, прописные (A-Z) и строчные (a-z) буквы латинского алфавита, минимум одну цифру, минимум один символ !”№;%:?*()_+-=#$%^&\'',
  ConfirmPassword = 'Пароли должны сопадать',
  Date = 'Некорректная дата',
  PasswordSafetyRequirements = 'Пароль не соответствует требованиям безопасности',
  PasswordsNotMatch = 'Пароли не совпадают, проверьте правильность заполненных полей',
  InnSupplierSignUp = 'Номер ИНН не найден. Проверьте правильность ввода и попробуйте еще раз.',
  UserNotExistence = 'Аккаунт с указанной электронной почтой не найден',
  MinSumSupplier = 'Указана завышенная стоимость доставки. Введите меньшее значение или свяжитесь со службой поддержки',
  RussianString = 'Допустим ввод символов только русской раскладки',
  SupplierMinDateToday = 'В этот день доставка невозможна',
  MaxDigitsAffterComma = 'Допустимо {maxDigits} после запятой',
  NotValidString = 'Неверный формат строки',
}
