<template>
  <component
    :is="currentComponent"
    :class="wrapperClasses"
  />
</template>

<script lang="ts" setup>
/**
 * Данный компонент является оберткой https://github.com/shrpne/vue-inline-svg
 * Для src используется базовая директория иконок src/shared/public/icons
 * Необходимо указать поддиректорию и название иконки
 */
import { ALL_ICONS_BY_PATH } from '../constants/allIconsByPath.const';
import { EIconPath } from '../enums/iconPath.enum';
import { WatchSubscription } from '../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';

const props = defineProps<{
  wrapperClasses?: string | Array<string>;
  src: EIconPath;
}>();

const watchSubscription = new WatchSubscription();
const currentComponent = shallowRef();

function setIcon() {
  currentComponent.value = ALL_ICONS_BY_PATH.get(props.src);
  if (!currentComponent.value) {
    throw Error(`Иконка по пути ${props.src} не найдена. Необходимо добавить её в константы.`);
  }
  return currentComponent;
}

watchSubscription.add(
  watch(() => props.src, setIcon, { immediate: true }),
);

useSSRUnsubscribeWatch(watchSubscription);

</script>
