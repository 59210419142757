<template>
  <div
    ref="rootRef"
    class="mm-input"
    :class="{
      'mm-input--with-label': !!label,
      'mm-input--is-editing': isEditingMode,
      'mm-input--is-disabled': disabled,
      'mm-input--is-readonly': readonly,
      'mm-input--is-invalid': isInvalid,
      'mm-input--is-small': size === 'small',
      'mm-input--required': required,
      'mm-input--multiline': multiline,
    }"
  >
    <div
      class="mm-input__input-container"
      :class="{ 'left-label-input': leftLabel,
                'right-label-input': rightLabel }"
    >
      <SvgIcon
        v-if="showSearchIcon"
        class="icon mm-input__icon"
        :class="{ 'icon--inactive': disabled }"
        :src="iconPath"
        @click="onFocusInput"
      />

      <SvgIcon
        v-if="showButtonIcon"
        class="icon mm-input__icon"
        :class="{ 'icon--inactive': disabled, 'mm-input__icon-disabled': isIconButtonDisabled }"
        :src="iconPath"
        @click="onIconClick"
      />

      <SvgIcon
        v-if="showClearIcon"
        src="navigation/close-20px"
        class="mm-input__icon mm-input__icon--clear"
        @mousedown.capture="onClear"
      />

      <textarea
        v-if="multiline"
        :id="inputSpecId"
        ref="inputRef"
        class="mm-input__input"
        :class="{
          multiline,
        }"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder || '&nbsp;'"
        :rows="rows"
        :name="name"
        :value="modelValue"
        :maxlength="maxLength"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInputTextArea"
        @keyup.exact.enter="onEnter()"
        @keyup.ctrl.enter="onCtrlEnter"
        @click="onClickInput($event)"
      />

      <input
        v-else
        :id="inputSpecId || inputId"
        ref="inputRef"
        class="mm-input__input"
        :type="type"
        :disabled="disabled"
        :readonly="readonly"
        :maxlength="maxLength"
        :placeholder="placeholder || '&nbsp;'"
        :name="name"
        :autocomplete="autocomplete"
        mode="passive"
        @blur="onBlur"
        @focusin="onFocus"
        @keyup.enter="onEnter()"
        @click="onClickInput($event)"
      >

      <slot name="afterInput" />

      <template v-if="(leftLabel && rightLabel)">
        <label
          class="mm-input__label left-label"
          :for="inputId"
          @click="onFocus"
        > <span class="mm-input__label-text">{{ leftLabel }}</span> </label> <label
          v-if="!isEditingMode && !isHideRightLabel"
          class="mm-input__label right-label"
          :for="inputId"
          @click="onFocus"
        > <span class="mm-input__label-text">{{ rightLabel }}</span> <span
          v-if="required"
          class="mm-input__label-icon"
        >
          *
        </span> </label>
      </template>

      <label
        v-if="(label || leftLabel || (rightLabel && !isEditingMode)) && !(leftLabel && rightLabel)"
        :ref="(labelRef) => rightLabelRef = labelRef"
        class="mm-input__label"
        :class="{ 'left-label': leftLabel, 'right-label': rightLabel }"
        :for="inputId"
        @click="onFocus"
      > <span class="mm-input__label-text">{{ inputLabel }}</span> <span
        v-if="required"
        class="mm-input__label-icon"
      >
        *
      </span> </label>
      <button
        v-if="rightButtonText && readonly"
        class="mm-input__right-button btn btn-text"
        @click="onButtonClick"
      >
        {{ rightButtonText }}
      </button>
      <div
        v-if="isInvalid"
        class="mm-input__error"
        v-html="sanitize(validationField?.errorMessage?.value || errorMessage, 'inline')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import IMask from 'imask';
import { sanitize } from '../directives/sanitize';
import { FieldContext } from 'vee-validate';
import SvgIcon from 'shared/components/SvgIcon.vue';
import { randomNumber } from 'shared/utils/randomNumber.util';
import { ETextFieldTypes } from 'shared/enums/textFieldTypes.enum';
import { MAX_NUMBER_LENGTH } from '../constants/number.const';
import { WatchSubscription } from '../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';

const props = withDefaults(
  defineProps<{
    // Тип инпута
    type?: ETextFieldTypes;
    // Значение инпута
    modelValue?: string | number;
    // Значение placeholder инпута
    placeholder?: string;
    // Отключен ли инпут
    disabled?: boolean;
    // Текст label у инпута
    label?: string;
    // Путь до иконки, отображающейся справа
    iconPath?: string;
    // Отображается ли иконка при фокусировке на инпуте
    isIconButton?: boolean;
    // Отключен ли iconButton
    isIconButtonDisabled?: boolean;
    // Предназначен ли инпут для эмита значений при клике на нконку
    showIconOnFocus?: boolean;
    // Должна ли иконка пропадать, когда пользователь не пользуется инпутом
    hideClearIconOnBlur?: boolean;
    // Контекст для валидации с использованием vee validate
    validationField?: FieldContext<string>;
    // Вкл/выкл разделение тысяч
    separateThousands?: boolean;
    // Максимальное количество чисел (для числовых инпутов)
    maxFractionDigits?: number;
    // Стоит ли дополнять числовые поля нулями
    padFractionalZeros?: boolean;
    // Вкл/выкл отрицательные числа
    allowSigned?: boolean;
    // Разделитель разрядов
    radix?: string;
    // Размер инпута
    size?: 'small' | 'normal';
    // Имя инпута
    name?: string;
    /**
     * Подробная информация по маске хранится по ссылке ниже
     * @link https://imask.js.org/
     */
    mask?: string | RegExp | Date;
    // Отключена ли иконка
    iconDisabled?: boolean;
    // Текст label слева от значения в инпуте
    leftLabel?: string;
    // Текст Label справа от инпута
    rightLabel?: string;
    // Флаг, разрешающий отчистку инпута
    clearable?: boolean;
    // Значение по умолчанию для инпута
    defaultValue?: string;
    // Сообщение об ошибке
    errorMessage?: string;
    // Отображение textarea вместо input
    multiline?: boolean;
    // Количество строк (для textarea)
    rows?: number;
    // Опции для маски
    maskOptions?: IMask.AnyMaskedOptions;
    // Максимальная длина значения
    maxLength?: number;
    // label, отображаемый сразу после значения
    afterValueLabel?: string;
    // Предназначен ли инпут только для ввода чисел
    isNumber?: boolean;
    // Включен ли режим "только для чтения"
    readonly?: boolean;
    // Является ли поле обязательным для заполнения
    required?: boolean;
    // Режим автозаполнения
    autocomplete?: string;
    // Следует ли скрывать правый label, когда инпут пуст
    hideRightLabelWhenEmpty?: boolean;
    // Следует ли скрывать label, идущий сразу за значением, когда инпут пуст
    hideAfterLabelWhenEmpty?: boolean;
    // Текст кнопки справа от инпута
    rightButtonText?: string;
    // Стоит ли включить автофокусировку при монтировании инпута
    startAutofocus?: boolean;
    // Параметр необходимо использовать в связке с setTouched и validate на желаемые поля/форму
    isOnlySubmitValidate?: boolean;
    // Устанавливать ли фокус при клике на иконку
    isFocusOnIconClick?: boolean;
    // id для специального ввода
    inputSpecId?: string;
    // Динамический правый label
    isDynamicRightLabel?: boolean;
    // режим обновления информации при двухсторонней привязке
    lazy?: boolean;
  }>(),
  {
    modelValue: '',
    placeholder: '',
    disabled: false,
    label: '',
    iconPath: 'action/pen-edit',
    separateThousands: false,
    maxFractionDigits: 0,
    padFractionalZeros: false,
    allowSigned: false,
    radix: '.',
    size: 'normal',
    iconDisabled: false,
    clearable: true,
    multiline: false,
    rows: 1,
    isNumber: false,
    readonly: false,
    required: false,
    autocomplete: 'off',
    isFocusOnIconClick: true,
    showIconOnFocus: false,
    hideClearIconOnBlur: false,
  },
);

const emits = defineEmits<{
  (e: 'update:modelValue', value: string | number): void;
  (e: 'maskedValue', value: string | number): void;
  (e: 'focusChange', value: boolean): void;
  (e: 'clickIcon'): void;
  (e: 'clickButton'): void;
  (e: 'clickClearButton'): void;
  (e: 'enter'): void;
  (e: 'ctrlEnter'): void;
  (e: 'clickInput', event: MouseEvent): void;
}>();

const isEditingMode = ref<boolean>(false);
const inputRef = ref<HTMLInputElement | HTMLTextAreaElement>();
const rootRef = ref<HTMLInputElement>();
const rightLabelRef = ref<HTMLInputElement>();

const watchSubscription = new WatchSubscription();
const isInvalid = computed(() => props.validationField?.meta.touched && !!props.validationField?.errorMessage.value || !!props.errorMessage);
const mask = ref<IMask.InputMask<IMask.AnyMaskedOptions>>();
const inputLabel = computed<string>(() => (props.leftLabel || props.rightLabel) || props.label);
const inputId = randomNumber(0, 1_000_000);
const isHideRightLabel = computed(() => (props.hideRightLabelWhenEmpty && !props.modelValue?.length));
const showSearchIcon = computed(() =>
  props.iconPath &&
  !props.isIconButton &&
  props.iconPath !== 'none' &&
  (!props.modelValue || props.showIconOnFocus) &&
  !props.iconDisabled &&
  !props.rightLabel,
);

const showButtonIcon = computed(() => props.modelValue && props.isIconButton);

const showClearIcon = computed(() =>
  props.clearable &&
  !!props.modelValue &&
  (!props.hideClearIconOnBlur || isEditingMode.value) &&
  !props.disabled &&
  (!props.leftLabel || !props.rightLabel),
);

function onFocus(): void {
  isEditingMode.value = true;
  props.isOnlySubmitValidate && props.validationField?.setTouched(!props.isOnlySubmitValidate);
  emits('focusChange', true);
  nextTick(initRightLabelPadding);
}

function onEnter(): void {
  onBlur();
  emits('enter');
}

function onCtrlEnter(): void {
  emits('ctrlEnter');
}

function onBlur(): void {
  isEditingMode.value = false;
  const convertedUnmaskedValue = props.type === ETextFieldTypes.Number ? Number(mask.value?.unmaskedValue) : mask.value?.unmaskedValue;
  const unmaskedValue = convertedUnmaskedValue || props.defaultValue || '';
  mask.value.unmaskedValue = unmaskedValue.toString();
  mask.value.updateValue();
  handleInputMask(true);
  props.validationField?.setTouched(!props.isOnlySubmitValidate);
  emits('focusChange', false);
  nextTick(initRightLabelPadding);
}

function onFocusInput(): void {
  if (props.isFocusOnIconClick) {
    inputRef.value?.focus();
  }
  if (!props.isIconButton) {
    emits('clickIcon');
  }
}

function onIconClick(): void {
  if (props.isIconButton) {
    emits('clickIcon');
  }
}

function onClear(): void {
  mask.value.unmaskedValue = props.defaultValue ?? '';
  mask.value.updateValue();
  handleInputMask(true);
  onFocusInput();
  emits('clickClearButton');
}

function onButtonClick(): void {
  emits('clickButton');
}

function handleInputMask(isBlurEvent = false) {
  if (props.lazy && !isBlurEvent) {
    return;
  }
  emits('maskedValue', mask.value?.value);
  emits('update:modelValue', props.isNumber ? mask.value?.unmaskedValue ? +mask.value?.unmaskedValue : null : mask.value?.unmaskedValue);
}

function mapMaskOptions(): IMask.AnyMaskedOptions {
  const mask = props.separateThousands ? Number : props.isNumber ? new RegExp(`^\\d{0,${MAX_NUMBER_LENGTH}}$`, 'g') : props.mask || /.+/;

  let options = props.maskOptions || {
    mask,
  } as IMask.AnyMaskedOptions;

  if (props.separateThousands && !props.isNumber) {
    options = {
      ...options,
      scale: props.maxFractionDigits,
      padFractionalZeros: props.padFractionalZeros,
      signed: props.allowSigned,
      radix: props.radix,
      thousandsSeparator: props.separateThousands ? ' ' : '',
      mapToRadix: ['.', ','],
    } as IMask.AnyMaskedOptions;
  }

  if (props.afterValueLabel) {
    options = {
      ...options,
      mask: `R${!props.hideAfterLabelWhenEmpty ? ` ${props.afterValueLabel}` : (props.hideAfterLabelWhenEmpty && props.modelValue && ` ${props.afterValueLabel}`) || ''}`,
      lazy: false,
      blocks: {
        R: {
          mask: Number || props.mask,
        },
      },
    } as IMask.AnyMaskedOptions;
  }

  return options;
}

function initMask() {
  if (!inputRef.value) {
    return;
  }

  mask.value = IMask(inputRef.value, mapMaskOptions());
  mask.value.on('accept', () => handleInputMask());
  updateUnmaskedValue();
}

function updateUnmaskedValue() {
  if (props.isNumber && String(props.modelValue) === mask.value?.unmaskedValue || props.type === ETextFieldTypes.Number && mask.value?.unmaskedValue) {
    return;
  }

  mask.value.unmaskedValue = String(props.modelValue) || '';
  mask.value.updateValue();
}

function onInputTextArea(): void {
  if (inputRef.value?.style) {
    inputRef.value.style.height = 'auto';
    inputRef.value.style.height = `${inputRef.value.scrollHeight}px`;
  }
}

function initRightLabelPadding() {
  if (!props.isDynamicRightLabel) {
    return;
  }

  const input = inputRef.value;
  const paddingInput = 16;
  const borderInput = 4;
  const rightLabel = rightLabelRef.value;
  const defaultPadding = 42;
  const labelPadding = rightLabel
    ? rightLabel?.clientWidth + paddingInput + borderInput
    : defaultPadding;

  if (input?.style) {
    input.style.paddingRight = `${labelPadding}px`;
  }
}

function onClickInput(event: MouseEvent): void {
  emits('clickInput', event);
}

watchSubscription.add(
  watch(
    () => props.modelValue,
    () => {
      updateUnmaskedValue();
      if (props.afterValueLabel && props.hideAfterLabelWhenEmpty) {
        mask.value?.updateOptions(mapMaskOptions());
      }
    },
  ),

  watch(
    () => props.maskOptions,
    () => mask.value.updateOptions(mapMaskOptions()),
    { deep: true },
  ),

  watch(
    () => props.afterValueLabel,
    () => mask.value?.updateOptions(mapMaskOptions()),
  ),

  watch(
    () => props.startAutofocus,
    (isAutofocus) => isAutofocus && inputRef.value?.focus(),
    { flush: 'post' },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(() => {
  initMask();
  props.validationField?.validate();
  if (props.multiline) {
    onInputTextArea();
  }

  if (props.startAutofocus) {
    nextTick(() => inputRef.value?.focus());
  }

  nextTick(initRightLabelPadding);
});

onBeforeUnmount(() => {
  mask.value?.off('accept', () => handleInputMask());
});

defineExpose({
  mask,
  inputRef,
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/_variables';

$root: '.mm-input';

.mm-input {
  position: relative;
  display: flex;
  padding-bottom: 18px;

  &--is-invalid {
    #{$root}__input {
      border-color: $input-error-border !important;
    }

    #{$root}__input-container {
      height: unset;

      #{$root}__icon {
        top: 39%;
      }
    }
  }

  &--with-label {
    overflow: hidden;

    #{$root}__input {
      padding: 26px 48px 10px 16px;
    }
  }

  &--multiline {
    overflow: auto;

    .mm-input__input-container {
      height: auto;
    }
  }

  &--is-disabled {
    #{$root}__input {
      background-color: $input-disabled-border;
      color: $text-disabled;
      -webkit-text-fill-color: $text-disabled;
    }

    #{$root}__label {
      color: $text-disabled;
    }

    #{$root}__icon {
      pointer-events: none;
    }
  }

  &--is-small {
    #{$root}__input {
      padding: 10px 45px 10px 12px;
      height: 40px;
    }

    #{$root}__input-container {
      #{$root}__icon {
        left: unset;
        transform: translateY(-50%);
      }
    }

    #{$root}__icon {
      height: 24px;
      width: 24px;
      top: 20px;
    }

    .mm-input__after-label {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__label {
    position: absolute;
    color: $input-label-c;
    font-size: 14px;
    line-height: 20px;
    top: 20px;
    left: 16px;
    z-index: 1;
    transform-origin: 0 0;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
    overflow: hidden;
    max-width: calc(100% - 32px);
    display: flex;
    pointer-events: none;

    #{$root}__label-text {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      pointer-events: none;
    }

    #{$root}__label-icon {
      flex: 0;
      color: $required-input-star-c;
      margin-left: 3px;
    }

    &.left-label {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &.right-label {
      top: 50%;
      left: unset;
      right: 20px;
      transform: translateY(-50%);
    }
  }

  &__input-container {
    position: relative;
    width: 100%;
    height: 56px;

    &.left-label-input > input {
      padding-left: 42px;
    }

    &.right-label-input > input {
      padding-right: 42px;
    }
  }

  &__input {
    border-width: 0;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    border-right: 48px solid transparent;
    color: $input-c;
    position: relative;
    z-index: 1;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    height: 56px;
    padding: 16px 48px 16px 16px;
    display: flex;
    transition: border-color 0.3s;
    background-color: transparent;
    user-select: text;

    &[type="password"]:not(:placeholder-shown) {
      font-size: 24px;
      letter-spacing: 2px;
    }

    &::placeholder {
      color: $input-placeholder-c;
    }

    &:placeholder {
      color: $light-green;
      opacity: 1;
    }

    &::placeholder {
      color: $light-green;
      opacity: 1;
    }

    &:not(:disabled):not(:focus):hover {
      border-color: $input-hover-border;
    }

    &:focus {
      border-color: $input-focus-border;

      & + .mm-input__label {
        transform: translate3d(0, -10px, 0) scale(0.85);
      }

      & + .left-label {
        transform: translateY(-9px);
      }

      & + .right-label {
        top: 50%;
        left: unset;
        right: 20px;
        transform: translateY(-50%);
      }
    }

    &:not(:placeholder-shown) {
      & + .mm-input__label {
        transform: translate3d(0, -10px, 0) scale(0.85);
      }

      & + .left-label {
        transform: translateY(-9px);
      }

      & + .right-label {
        top: 50%;
        left: unset;
        right: 20px;
        transform: translateY(-50%);
      }
    }

    &.multiline {
      overflow-y: hidden;
      min-height: 56px;
      height: 100%;
    }

    &.after-label {
      padding-right: 55px;
    }
  }

  &__icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    width: 16px;
    height: 16px;
  }

  :deep(.mm-input__icon-disabled path) {
    fill: $text-disabled;
    pointer-events: none;
  }

  &__error {
    font-size: 12px;
    line-height: 16px;
    color: $input-error-c;
    z-index: 1;
    padding-left: 16px;
  }

  &__right-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    right: 24px;
    padding: 0;
    font-weight: 500;
    cursor: pointer;
  }

  &__after-label {
    position: absolute;
    left: 24px;
    top: 19px;
    color: $input-label-c;
    font-size: 14px;
    line-height: 20px;
    z-index: 1;

    &.left-label {
      left: 50px;
    }
  }
}

//Iphone 13 mini
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .mm-input__input {
    &[type="text"] {
      font-size: 16px;
    }
  }
}

//iPhone 13 and iPhone 13 Pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .mm-input__input {
    &[type="text"] {
      font-size: 16px;
    }
  }
}

//iPhone 13 Pro Max
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .mm-input__input {
    &[type="text"] {
      font-size: 16px;
    }
  }
}

//iPhone 11
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .mm-input__input {
    &[type="text"] {
      font-size: 16px;
    }
  }
}

//iPhone 11 Pro
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .mm-input__input {
    &[type="text"] {
      font-size: 16px;
    }
  }
}

//iPhone 11 Pro Max
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .mm-input__input {
    &[type="text"] {
      font-size: 16px;
    }
  }
}
</style>
