<template>
  <Modal
    :title-text="title"
    z-index="10000"
    width="574px"
    hide-footer
    classes="modal fixed-bottom-modal bottom-0"
    @close="$emit('close')"
  >
    <div class="mm-client-create-order-draft-error">
      <div class="mm-client-create-order-draft-error__content">
        {{ content }}
      </div>
      <button
        class="btn btn-text mm-client-create-order-draft-error-button"
        :disabled="clientCreateOrderFromXlsxService?.downloadingFile.value"
        :style="{
          flexDirection: buttonDirection
        }"
        @click.stop="onClickButton"
      >
        <SvgIcon
          :src="buttonIcon"
          class="mm-client-create-order-draft-error-button__icon"
        />
        {{ buttonText }}
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { EIconPath } from 'shared/enums/iconPath.enum';
import Modal from 'shared/components/modals/Modal.vue';
import { IClientParseXlsxError } from 'models/client/clientCreateOrderXlsx.model';
import { EClientParseXlsxErrorCode } from 'enums/client/clientParseXlsxErrorCode.enum';
import { ClientCreateOrderFromXlsxService } from 'services/client/clientCreateOrderFromXlsx.service';

const props = defineProps<{
  error: IClientParseXlsxError;
}>();

const emit = defineEmits<{
  (e: 'close', disableRedirect?: boolean): void
}>();

const clientCreateOrderFromXlsxService = inject<ClientCreateOrderFromXlsxService>(ClientCreateOrderFromXlsxService.getServiceName());

const titleErrorMap = new Map<EClientParseXlsxErrorCode, string>([
  [EClientParseXlsxErrorCode.ParseError, 'Ошибка загрузки файла'],
  [EClientParseXlsxErrorCode.ValidationError, 'Ошибка обработки файла'],
]);

const contentErrorMap = new Map<EClientParseXlsxErrorCode, string>([
  [EClientParseXlsxErrorCode.ParseError, 'Загруженный файл не корректен. Пожалуйста, проверьте формат и читаемость документа.'],
  [EClientParseXlsxErrorCode.ValidationError, 'Загруженный файл некорректен.  Чтобы узнать причины неудачной обработки и исправить их, скачайте файл с ошибками.'],
]);

const buttonTextMap = new Map<EClientParseXlsxErrorCode, string>([
  [EClientParseXlsxErrorCode.ParseError, 'Вернуться к загрузке файла'],
  [EClientParseXlsxErrorCode.ValidationError, 'Скачать файл с ошибками'],
]);

const buttonIconMap = new Map<EClientParseXlsxErrorCode, EIconPath>([
  [EClientParseXlsxErrorCode.ParseError, EIconPath.NavigationArrowBack],
  [EClientParseXlsxErrorCode.ValidationError, EIconPath.ActionDownloadSvg],
]);

const errorIsParse = computed(
  () => props.error?.code === EClientParseXlsxErrorCode.ParseError,
);

const title = computed(
  () => titleErrorMap.get(props.error?.code),
);

const content = computed(
  () => contentErrorMap.get(props.error?.code),
);

const buttonText = computed(
  () => buttonTextMap.get(props.error?.code),
);

const buttonIcon = computed(
  () => buttonIconMap.get(props.error?.code),
);

const buttonDirection = computed(
  () => errorIsParse.value
    ? 'row'
    : 'row-reverse',
);

async function onClickButton(): Promise<void> {
  if (errorIsParse.value) {
    emit('close', true);
    return clientCreateOrderFromXlsxService?.openCreateOrderDraftModal();
  }

  await clientCreateOrderFromXlsxService?.downdloadErrorFile(props.error);
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-client-create-order-draft-error {
  &__content {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $text-dark-green;
  }

  &-button {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    padding: 0;
    column-gap: 4px;

    &__icon {
      margin: 0 !important;
    }
  }
}
</style>
