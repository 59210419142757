import { ProviderService } from '../../models/providerService.model';
import axios, { AxiosResponse } from 'axios';
import { NamingHelper } from '../../utils/namingHelper.utils';
import { IFile } from '../../models/files.model';
import { promiseAllLimit } from 'shared/utils/promiseAllLimit.util';

export default class FilesService extends ProviderService {
  static readonly serviceName = 'filesService';

  _currentUploadingFile = ref();

  public set currentUploadingFile(value: string) {
    this._currentUploadingFile.value = value;
  }

  public get currentUploadingFile(): string {
    return this._currentUploadingFile.value;
  }

  constructor() {
    super();
  }

  static async fileDownload<AxiosResponse>(
    url: string,
    fetchOptions: {
      method?: 'get' | 'put' | 'post';
      headers?: Record<string, unknown>;
      body?: Record<string, unknown> | FormData | Array<unknown>;
      responseType?: ResponseType;
      params?: object,
    } = {},
  ): Promise<AxiosResponse> {
    const appBaseUrl = useRuntimeConfig()?.public?.appBaseUrl;

    return await axios.request({
      url: (appBaseUrl || '') + url,
      responseType: fetchOptions?.responseType || 'blob',
      method: fetchOptions?.method || 'get',
      headers: {
        ...(fetchOptions.headers || {}),
      },
      params: {
        ...(fetchOptions?.params || {}),
      },
      data: fetchOptions?.body,
    });
  }

  static getFileNameFromResponse(response: AxiosResponse, fileHeaderField = 'content-disposition', regExp = /filename\*=utf-8''(.*)$/): string {
    const parsedHeader = response?.headers[fileHeaderField];
    return parsedHeader?.match(regExp)?.[1] ? decodeURIComponent(parsedHeader?.match(regExp)?.[1]) : '';
  }

  static async fileFetch<T>(
    url: string,
    fetchOptions: {
      method?: 'get' | 'put';
      headers?: Record<string, unknown>;
      body?: Record<string, unknown> | FormData;
      responseType?: string;
    },
  ): Promise<T> {
    return (await axios.put(url, fetchOptions.body, {
      responseType: 'json',
      transformResponse: [function(data) {
        return NamingHelper.responseFileChecked(data);
      }],
      headers: {
        'ContentType': 'Multipart/form-data',
        ...(fetchOptions.headers || {}),
      },
    })).data;
  }

  async downloadFile(id: string, url = `/api/v2/download/${id}/`): Promise<AxiosResponse> {
    return await FilesService.fileDownload(url, { method: 'get' });
  }

  async fileUpload(file, url: string, fileFieldName = 'file') {
    const formData = new FormData();
    formData.append(fileFieldName, file);
    return await FilesService.fileFetch<IFile>(url, { method: 'put', body: formData });
  }

  async deleteFile(id: string, url = `/api/v1/suppliers/files/${id}`) {
    return await $fetch(url, { method: 'DELETE', retry: 0 });
  }

  async fileFetchByUuids(ids: Array<string>) {
    return await axios
      .post(
        '/api/v1/files/',
        { uuids: ids },
        {
          responseType: 'json',
          transformResponse: [function(data) {
            return NamingHelper.responseFileChecked(data);
          },
          ],
        },
      );
  }

  public async downloadTemplateFile(id: string) {
    return await FilesService.fileDownload(`/api/v1/files/templates/${id}/`, { method: 'get' });
  }

  public async uploadCatalogFile(file: File, catalogId: number, type: string) {
    return await this.fileUpload(file, `/api/v2/suppliers/${catalogId}/catalog/${type}/`);
  }

  /**
   * Метод для загрузки файлов пулом. Параметр limit отвечает за то, сколько будет параллельных
   * запросов с загрузкой файлов.
   */
  public async uploadFilesSequentially(files: Array<File>, url: string, fileFieldName = 'file', limit = 5): Promise<Array<IFile>> {
    return await promiseAllLimit<IFile>(files.map((file) => async () => await this.fileUpload(file, url, fileFieldName)), limit);
  }

  public async downloadV2TemplateFile(templateName: string): Promise<AxiosResponse<Blob>> {
    return await FilesService.fileDownload(
      `/api/v2/download/templates/${templateName}/`,
      { method: 'get' },
    );
  }

  public async downloadOrderDraftTemplateFile(): Promise<AxiosResponse<Blob>> {
    return await FilesService.fileDownload(
      '/api/v1/carts/excel_template/',
      { method: 'get' },
    );
  }
}
