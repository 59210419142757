export enum EFilterTypes {
  Enum = 'ENUM',
  Range = 'RANGE',
  Text = 'TEXT',
  Bool = 'BOOL',
  Sort = 'SORT',
  MSort = 'SORT',
  DateRange = 'DATE_RANGE',
  Radio = 'RADIO',
}
