import { SearchParameters } from 'ofetch';

export class SearchParamsUtils {
  public static deleteEmpty(params?: SearchParameters): void {
    if (!params) {
      return;
    }

    SearchParamsUtils.clean(params);
  }

  public static clean(obj: Record<string, unknown>): void {
    Object.entries(obj).forEach(([key, queryParam]) => {
      if (SearchParamsUtils.isObject(queryParam)) {
        SearchParamsUtils.clean(queryParam as Record<string, unknown>);
      }

      if (queryParam == null ||
        queryParam === '' ||
        SearchParamsUtils.isEmptyArray(queryParam) ||
        SearchParamsUtils.isEmptyObject(queryParam)
      ) {
        delete obj[key];
      }
    });
  }

  public static isObject(value: unknown): boolean {
    return value !== null && !Array.isArray(value) && typeof value === 'object';
  }

  public static isEmptyArray(value: unknown): boolean {
    return (Array.isArray(value) && !value.length);
  }

  public static isEmptyObject(value: unknown): boolean {
    return (SearchParamsUtils.isObject(value) && !Object.keys(value as object).length) || value === '{}';
  }
}