import { WatchSubscription } from '../utils/watchSubscription';
import { onBeforeUnmount } from 'vue';

export default function (watchSubscriptions: WatchSubscription) {
  if (process.client) {
    onBeforeUnmount(() => watchSubscriptions.unsubscribe());
  }
  
  useNuxtApp()?.hook('app:rendered', () => {
    watchSubscriptions.unsubscribe();
  });
}
