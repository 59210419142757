import { Observer } from './observer.service';

export class ObserverManager<T = unknown> {
  private _observers: Array<Observer<T>> = [];
  private _uniqueObserverId = 1;

  public subscribe(callback: (item?: T) => void): Observer<T> {
    const observer = new Observer(this._uniqueObserverId, callback);
    this._observers.push(observer);
    this._uniqueObserverId++;
    return observer;
  }

  public unsubscribe(unsubscribeObserver: Observer<T>): void {
    this._observers = this._observers.filter((observer) => observer.id !== unsubscribeObserver.id);
  }

  public notify(item?: T): void {
    this._observers.forEach((observer) => observer.update(item));
  }
}
