import { NuxtProcessWrappers } from '../utils/nuxtProcessWrappers.util';
import { EsdCertHelpers } from '../utils/esdCertHelpers.utils';
import { ICryptoProCert, IMappedCert, IVerifiedCertInfo } from '../models/sign-esd/certificate.model';
import { ECreateObjectActions } from '../enums/sign-esd/createObjectActions.enum';
import { ESignAlgorithms } from '../enums/sign-esd/signAlgorithms.enum';
import { EEncodingTypes } from '../enums/sign-esd/encodingTypes.enum';
import { URLS_SIGN_ESD } from '../constants/sign-esd/urls.const';
import { CustomError } from 'shared/utils/errors/customError.util';
import { NOT_INSTALLED_PLUGIN_ERROR } from 'shared/constants/error.const';
import { ESignEsdErrorCode } from '../enums/services/signEsdErrorCode.enum';
import { SIGN_ESD_ERROR_MAP } from '../constants/services/signEsd.const';
import { clientSentry } from '../utils/sentry/clientSentry.util';
import { Severity } from '@sentry/types';
import Notificator from './notificator.service';
import { SignEsdError } from '../utils/errors/signEsdError.util';
import cadespluginScript from '../public/scripts/cadesplugin_api';

export class SignEsd {
  static async signDetached(message: string, thumbprint: string): Promise<string> {
    return await NuxtProcessWrappers.runOnClient(this.createDetachedSignature, this, message, thumbprint);
  }

  static async signAttached(message: string, thumbprint: string): Promise<string> {
    return await NuxtProcessWrappers.runOnClient(this.createAttachedSignature, this, message, thumbprint);
  }

  static async verify(hash: string, signedMessage: string): Promise<IVerifiedCertInfo> {
    return await NuxtProcessWrappers.runOnClient(this.verifySignature, this, hash, signedMessage);
  }

  static async createHashOnClient(message: string): Promise<string> {
    return await NuxtProcessWrappers.runOnClient(this.createHash, this, message);
  }

  static async getUserCertificatesOnClient(): Promise<Array<IMappedCert>> {
    return await NuxtProcessWrappers.runOnClient(this.getUserCertificates, this);
  }

  static async initPluginOnClient(disableNotificationError = false): Promise<void> {
    return await NuxtProcessWrappers.runOnClient(this.initPlugin, this, disableNotificationError);
  }

  public static isSignEsdError(error: unknown): error is SignEsdError {
    return error instanceof SignEsdError;
  }

  private static async verifySignature(hash: string, signedMessage: string): Promise<IVerifiedCertInfo> {
    await this.afterCadesLoaded();

    const { cadesplugin } = window;
    const oHashedData = await cadesplugin.CreateObjectAsync(ECreateObjectActions.HashedData);

    try {
      await oHashedData.propset_Algorithm(ESignAlgorithms.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256);
      await oHashedData.SetHashValue(hash);
    } catch (error) {
      SignEsd.logError(ESignEsdErrorCode.SetHashToHashContainer, error);
    }

    try {
      const oSignedData = await cadesplugin.CreateObjectAsync(ECreateObjectActions.SignedData);
      await oSignedData.VerifyHash(oHashedData, signedMessage, cadesplugin['CADESCOM_PKCS7_TYPE']);
      const oSigners = await oSignedData.Signers;
      const oSigner = await oSigners.Item(1);
      const signingTime = await oSigner.SigningTime;
      const cert = await oSigner.Certificate;
      const certInfo = await cert.SubjectName;

      return EsdCertHelpers.getVerifiedCertInfo(signingTime, certInfo);
    } catch (error) {
      SignEsd.logError(ESignEsdErrorCode.DuringSignatureVerification, error);
    }
  }

  private static async createHash(message: string, isBinary = true, algorithm = ESignAlgorithms.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256) {
    await this.afterCadesLoaded();

    try {
      const { cadesplugin } = window;
      const oHashedData = await cadesplugin.CreateObjectAsync(ECreateObjectActions.HashedData);

      await oHashedData.propset_Algorithm(algorithm);
      isBinary && await oHashedData.propset_DataEncoding(EEncodingTypes.CADESCOM_BASE64_TO_BINARY);
      await oHashedData.Hash(message);

      return await oHashedData.Value;
    } catch (error) {
      SignEsd.logError(ESignEsdErrorCode.DuringCalculatingHash, error);
    }
  }

  private static async createDetachedSignature(message: string, thumbprint: string, isBinary = true) {
    await this.afterCadesLoaded();

    const { cadesplugin } = window;

    try {
      const signType = cadesplugin['CADESCOM_PKCS7_TYPE'];
      const cert = await this.getUserCertByThumbprint(thumbprint);
      const hash = await this.createHash(message, isBinary);

      const cadesAttrs = await cadesplugin.CreateObjectAsync(ECreateObjectActions.CPAttribute);
      const currentTime = new Date();

      await cadesAttrs.propset_Name(cadesplugin.CADESCOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME);
      await cadesAttrs.propset_Value(currentTime);

      const cadesSigner = await cadesplugin.CreateObjectAsync(ECreateObjectActions.CPSigner);
      await cadesSigner.propset_Certificate(cert);
      await cadesSigner.propset_CheckCertificate(true);
      await cadesSigner.propset_Options(cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN);
      await cadesSigner.propset_TSAAddress(URLS_SIGN_ESD.default);

      const oHashedData = await cadesplugin.CreateObjectAsync(ECreateObjectActions.HashedData);
      await oHashedData.propset_Algorithm(ESignAlgorithms.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256);
      await oHashedData.SetHashValue(hash);

      const cadesSignedData = await cadesplugin.CreateObjectAsync(ECreateObjectActions.SignedData);
      const signedSignature = await cadesSignedData.SignHash(oHashedData, cadesSigner, signType);

      return signedSignature;
    } catch (error) {
      SignEsd.logError(ESignEsdErrorCode.DuringCreateDetachedSignature, error, false, cadesplugin.getLasError(error));
    }
  }

  private static async createAttachedSignature(message: string, thumbprint: string, isBinary = true) {
    await this.afterCadesLoaded();

    const { cadesplugin } = window;

    try {
      const signType = cadesplugin['CADESCOM_PKCS7_TYPE'];
      const cert = await this.getUserCertByThumbprint(thumbprint);

      const cadesAttrs = await cadesplugin.CreateObjectAsync(ECreateObjectActions.CPAttribute);
      const currentTime = new Date();

      await cadesAttrs.propset_Name(cadesplugin.CADESCOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME);
      await cadesAttrs.propset_Value(currentTime);

      const cadesSigner = await cadesplugin.CreateObjectAsync(ECreateObjectActions.CPSigner);
      await cadesSigner.propset_Certificate(cert);
      await cadesSigner.propset_CheckCertificate(true);
      await cadesSigner.propset_Options(cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN);

      // Впоследствии необходимо указать продакшн-адрес TSP сервера. Это тестовый от Крипто ПРО
      await cadesSigner.propset_TSAAddress(URLS_SIGN_ESD.default);

      const cadesSignedData = await cadesplugin.CreateObjectAsync(ECreateObjectActions.SignedData);
      isBinary && await cadesSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
      await cadesSignedData.propset_Content(message);

      const signedSignature = await cadesSignedData.SignCades(cadesSigner, signType);

      return signedSignature;
    } catch (error) {
      SignEsd.logError(ESignEsdErrorCode.DuringCreateAttachedSignature, error, false, cadesplugin.getLastError?.(error));
    }
  }

  private static async getUserCertByThumbprint(thumbprint): Promise<ICryptoProCert> {
    await this.afterCadesLoaded();

    const { cadesplugin } = window;

    const cadesStore = await cadesplugin.CreateObjectAsync(ECreateObjectActions.Store);

    await cadesStore.Open(
      cadesplugin.CAPICOM_CURRENT_USER_STORE,
      cadesplugin.CAPICOM_MY_STORE,
      cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
    );

    if (!cadesStore) {
      throw new Error('Не удалось получить доступ к хранилищу сертификатов');
    }

    let cadesCertificateList = await cadesStore.Certificates;
    const certificatesCount = await cadesCertificateList.Count;

    if (!certificatesCount) {
      throw new Error('Нет доступных сертификатов');
    }

    cadesCertificateList = await cadesCertificateList.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH, thumbprint);
    const count = await cadesCertificateList.Count;

    if (!count) {
      throw new Error(`Сертификат с отпечатком: '${thumbprint}' не найден`);
    }

    const cadesCertificate = await cadesCertificateList.Item(1);
    await cadesStore.Close();

    return cadesCertificate;
  }

  private static async getUserCertificates(): Promise<Array<IMappedCert>> {
    await this.afterCadesLoaded();

    const { cadesplugin } = window;

    try {
      const certs: Array<IMappedCert> = [];
      await cadesplugin;
      const cadesStore = await cadesplugin.CreateObjectAsync(ECreateObjectActions.Store);

      await cadesStore.Open(
        cadesplugin.CAPICOM_CURRENT_USER_STORE,
        cadesplugin.CAPICOM_MY_STORE,
        cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
      );

      const cadesCertificateList = await cadesStore.Certificates;
      const count = await cadesCertificateList.Count;

      for (let i = count; i > 0; i--) {
        const cadesCertificate = await cadesCertificateList.Item(i);
        const [SubjectName, thumbprint, valid, algorithm, Issuer, ValidToDate, ValidFromDate] = await Promise.all([
          cadesCertificate.SubjectName,
          cadesCertificate.Thumbprint,
          cadesCertificate.IsValid().then((v) => v.Result),
          EsdCertHelpers.getAlgorithm(cadesCertificate),
          cadesCertificate.IssuerName,
          cadesCertificate.ValidToDate,
          cadesCertificate.ValidFromDate,
        ]);
        const mappedCert = EsdCertHelpers.getMappedCert({
          Issuer,
          thumbprint,
          ValidFromDate,
          ValidToDate,
          algorithm,
          SubjectName,
          valid,
        });

        certs.push(mappedCert);
      }

      return EsdCertHelpers.getVisibleCerts(certs);
    } catch (error) {
      SignEsd.logError(ESignEsdErrorCode.GetCertificateList, error, false, cadesplugin.getLastError?.(error));
    }
  }

  private static async afterCadesLoaded(): Promise<void> {
    const { cadesplugin } = window;

    if (!cadesplugin) {
      throw new Error('Ошибка инициализации CAdES plugin browser');
    }

    try {
      await cadesplugin;
    } catch (error) {
      throw new CustomError(NOT_INSTALLED_PLUGIN_ERROR, `Плагин CAdES plugin Browser не установлен: ${cadesplugin.getLastError?.(error)}`);
    }
  }

  public static createError(errorCode: ESignEsdErrorCode, originalError?: unknown, ...args: Array<unknown>): SignEsdError {
    return new SignEsdError(errorCode, SignEsd.getErrorMessageByCode(errorCode, ...args), originalError as Error);
  }

  private static getErrorMessageByCode(errorCode: ESignEsdErrorCode, ...args: Array<unknown>): string {
    return SIGN_ESD_ERROR_MAP.get(errorCode)?.(...args) || errorCode.toString();
  }

  private static logError(errorCode: ESignEsdErrorCode, error?: unknown, disableNotificationError = false, ...args: Array<unknown>): void {
    const errorCodeMessage = SignEsd.getErrorMessageByCode(errorCode, args);
    if (!disableNotificationError) {
      Notificator.showDetachedNotification(errorCodeMessage);
    }
    const signEsdError = SignEsd.createError(errorCode, error, ...args);

    clientSentry.captureException(signEsdError, {
      level: Severity.Error,
      tags: {
        service: 'sign-esd',
      },
      extra: {
        error,
      },
    });

    throw signEsdError;
  }

  private static async initPlugin(disableNotificationError = false): Promise<void> {
    try {
      cadespluginScript();
      const { cadesplugin } = window;

      if (!cadesplugin) {
        throw new Error('Ошибка инициализации CAdES plugin browser');
      }

      await cadesplugin;
    } catch (error) {
      if (!disableNotificationError) {
        Notificator.showDetachedNotification('Ошибка инициализации плагина КриптоПро ЭЦП Browser');
      }
      SignEsd.logError(ESignEsdErrorCode.InitPlugin, error, true);
    }
  }
}
