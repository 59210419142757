<template>
  <div
    class="file-uploader"
    :class="{
      'file-uploader--bordered': isBordered,
      'file-uploader--small': isSmallDesign,
      'file-uploader--mobile': isMobileDesign,
    }"
  >
    <div
      class="file-uploader-wrapper"
      :class="{ empty: !fileTempList.length }"
      @drop.prevent="onDropEvent"
      @dragenter.prevent
      @dragover.prevent
      @dragleave.prevent
    >
      <div class="file-uploader-wrapper__control">
        <div class="text d-flex align-items-start">
          <SvgIcon
            v-if="!isSmallDesign && $slots.default && !isMobileDesign"
            class="text__icon mr-8"
            src="action/attach"
          />

          <div class="text-wrapper">
            <span v-if="$slots.default">
              <slot />
            </span>

            <BaseTooltip
              v-if="tooltip || slotTooltip"
              arrow
              hover
              :position="EPopperPlacement.Top"
              :show="isShowMobileDrawer ? false : undefined"
              offset-distance="12"
            >
              <div class="tooltip-circle">
                <SvgIcon
                  class="text__icon__tooltip"
                  src="navigation/info-outline"
                  @click="onTooltipIconClick"
                />
              </div>

              <template #content>
                <span v-html="sanitize(tooltip)" />
                <slot name="tooltip" />
              </template>
            </BaseTooltip>

            <slot name="control-text-end" />
          </div>
        </div>

        <FileUploaderButton
          v-if="!isControlHidden"
          class="file-uploader-wrapper__control__button"
          :button-text="buttonText"
          :button-tooltip-text="buttonTooltipText"
          :is-max-files-count-achieved="isMaxFilesCountAchieved"
          :disabled="isAddBtnDisabled"
          :is-small-design="isSmallDesign"
          @click="onClickAddButton"
        />

        <input
          id="file_upload_input"
          ref="inputRef"
          :accept="accept"
          type="file"
          hidden
          :multiple="multiple"
          @change="onChangeEvent"
        >

        <input
          ref="inputRefReplace"
          :accept="accept"
          type="file"
          hidden
          @change="onChangeReplaceEvent"
        >
      </div>

      <ul
        v-if="fileTempList.length"
        class="file-uploader-wrapper__list"
        :class="{ small: isSmallDesign }"
      >
        <li
          v-for="fileTemp in fileTempList"
          :key="fileTemp.tempId"
          class="file-uploader-wrapper__list-item"
          :title="getFileName(fileTemp)"
          :class="{
            'file-uploader-wrapper__list-item--error': fileTemp.status === EFileStatus.Error,
            'file-uploader-wrapper__list-item--impossible': fileTemp.status === EFileStatus.Impossible,
            'file-uploader-wrapper__list-item--uploaded': fileTemp.isUploaded,
            'file-uploader-wrapper__list-item--uploading': fileTemp.status === EFileStatus.Uploading,
          }"
          @click="onDownloadFile(fileTemp)"
        >
          <slot
            name="prev-icon"
            :file-temp="fileTemp"
            :status="fileTemp.status"
          >
            <SvgIcon
              :key="fileTemp.status"
              class="icon"
              :class="{ animated: FileHelper.isLoadingStatus(fileTemp.status) }"
              :src="getPrevIconSrc(fileTemp)"
            />
          </slot>

          <div class="file-name-wrapper">
            <div class="text">
              <slot
                name="name"
                :file-temp="fileTemp"
                :status="fileTemp.status"
              >
                {{ getFileNameWithoutExtension(getFileName(fileTemp)) }}
              </slot>
            </div>

            <div class="file-extension">
              {{ getFileExtension(getFileName(fileTemp)) }}
            </div>
          </div>

          <slot
            name="status"
            :file-temp="fileTemp"
            :status="fileTemp.status"
            :error="fileTemp.error"
          >
            <span
              v-if="fileTemp.status === EFileStatus.Error"
              class="error-text"
            >
              {{ fileTemp.error }}
            </span>

            <button
              v-if="isReplaceButton && (fileTemp.isUploaded || fileTemp.status === EFileStatus.Default)"
              class="btn btn-text replace-button"
              @click.stop="onReplaceFile(fileTemp)"
            >
              Заменить файл
            </button>

            <SvgIcon
              v-if="!isControlHidden"
              class="button"
              :class="{ 'button--disabled': fileTemp.status === EFileStatus.Deleting }"
              :src="getPostIconSrc(fileTemp)"
              @click.stop="onDeleteFile(fileTemp)"
            />
          </slot>
        </li>
        <slot name="file-list-end" />
      </ul>

      <FileUploaderButton
        v-if="!isControlHidden"
        :button-text="buttonText"
        :is-max-files-count-achieved="isMaxFilesCountAchieved"
        class="file-uploader-wrapper__control__button mobile"
        :disabled="isAddBtnDisabled"
        :is-small-design="isSmallDesign"
        @click="onClickAddButton"
      />
    </div>

    <div
      v-if="errorMessage"
      class="file-uploader__error"
    >
      {{ errorMessage }}
    </div>

    <TextDrawer
      v-if="isShowMobileDrawer"
      :visible="isTextDrawerVisible"
      :drawer-data="mobileDrawer"
      @close="isTextDrawerVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { IFile, TFileId } from '../models/files.model';
import { EFileStatus } from '../enums/file-uploader/file-status.enum';
import { downloadFileByBlob } from '../utils/fileSaver.util';
import SvgIcon from './SvgIcon.vue';
import { FileTemp } from '../services/fileTemp.service';
import { AxiosResponse } from 'axios';
import { computed } from '@vue/reactivity';
import { FileHelper } from '../utils/fileHelper.util';
import { isEqual } from 'lodash-es';
import { getFileNameWithoutExtension, getFileExtension } from 'shared/utils/fileNameEllipsis.util';
import FileUploaderButton from './file-uploader/FileUploaderButton.vue';
import { EPopperPlacement } from 'shared/enums/popperPlacement.enum';
import { IMobileTextDrawer } from '../models/modal.model';
import WindowWidthManager from '../services/windowWidth.service';
import TextDrawer from '../components/TextDrawer.vue';
import { promiseAllLimit } from '../utils/promiseAllLimit.util';
import Notificator from '../services/notificator.service';
import { sanitize } from '../directives/sanitize';
import { WatchSubscription } from '../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';

const props = withDefaults(
  defineProps<{
    // Режим работы загрузчика (true - файлы сразу будут грузиться, false - формируется массив из выбранных файлов)
    isImmediatelyUploadFiles?: boolean;
    // Передавать в onChangeFileList список файлов, обернутых классом FileTemp
    isWrappedFileList?: boolean;
    // Разрешенный тип файлов ('image/*', '.jpg|.png')
    accept?: string;
    // Флаг загрузки нескольких файлов
    multiple?: boolean;
    // Количество разрешенных файлов
    maxAllowedFiles?: number;
    // Url api для загрузки файлов
    action?: string;
    // Максимальный размер файла в МБ
    size?: number;
    // Отображение процесса загрузки файла
    showProgress?: boolean;
    // Отображение ошибки валидатора в компоненте
    errorMessage?: string;
    // Тип файла
    fileType?: string;
    // Текст кнопки загрузки
    buttonText?: string;
    // Текст тултипа для кнопки
    buttonTooltipText?: string;
    // Текст тултипа
    tooltip?: string;
    // Начальный список файлов
    initialFileList?: Array<IFile | FileTemp>;
    // Отключен ли компонент
    isDisabled?: boolean;
    // Текст, отображаемый во время загрузки
    uploadingFileText?: string;
    // Имя для поля файла, когда файл загружен
    uploadedFileFieldName?: string;
    // Нужны ли border вокруг компонента
    isBordered?: boolean;
    // Скрыт ли uploaderButton
    isControlHidden?: boolean;
    // Включаются ли недопустимые файлы в список
    isChangeFileListIncludeInvalid?: boolean;
    // Вкл/выкл минималистичный дизайн
    isSmallDesign?: boolean;
    // Будет ли отображаться тултип
    slotTooltip?: boolean;
    // Вкл/выкл мобильный дизайн
    isMobileDesign?: boolean;
    // Данные для мобильной обертки
    mobileDrawer?: IMobileTextDrawer;
    // Кол-во параллельных загрузок файлов
    limitParallelUpload?: number;
    // Отображать ли кнопку замены
    isReplaceButton?: boolean;
    // Отключена ли загрузка файлов
    disabledUpload?: boolean;
    // Функция для загрузки файла
    uploadFileFunction?: (file: FileTemp) => Promise<IFile>;
    // Функция для обработки загруженного файла
    uploadedFileFunction?: (file: FileTemp) => Promise<void>;
    // Функция для удаления загруженного файла
    deleteFileFunction?: (file: FileTemp) => Promise<unknown>;
    // Функция для обработки удаленного файла
    deletedFileFunction?: (file: FileTemp) => Promise<unknown>;
    // Функция для скачивания файла
    downloadFileFunction?: (file: FileTemp) => Promise<AxiosResponse>;
    // Получить сообщение ошибки, если файл имеет не валидный формат
    getFileExtensionError?: (file?: FileTemp) => string;
  }>(),
  {
    isImmediatelyUploadFiles: false,
    buttonText: 'Прикрепить документ',
    isDisabled: false,
    uploadingFileText: 'Файл загружается',
    isWrappedFileList: false,
    uploadedFileFieldName: 'fileName',
    isBordered: true,
    isControlHidden: false,
    isChangeFileListIncludeInvalid: false,
    limitParallelUpload: 3,
  },
);

const emit = defineEmits<{
  (e: 'onChangeFileList', fileTempList: Array<FileTemp>): void;
  (e: 'onUpload', fileTemp: FileTemp): void;
  (e: 'onUploaded', fileTemp: FileTemp): void;
  (e: 'onUploadError', fileTemp: FileTemp): void;
  (e: 'onDownload', file: IFile): void;
  (e: 'onDownloaded', file: IFile): void;
  (e: 'onDownloadError', file: IFile): void;
  (e: 'onDelete', fileTemp: FileTemp): void;
  (e: 'onDeleted', fileTemp: FileTemp): void;
  (e: 'onDeleteError', fileTemp: FileTemp): void;
  (e: 'allFilesUploaded', value: boolean): void;
}>();

const watchSubscription = new WatchSubscription();
const inputRef = ref<null | HTMLInputElement>(null);
const inputRefReplace = ref<null | HTMLInputElement>(null);
const fileTempList = ref<Array<FileTemp>>([]);
const isAddBtnDisabled = ref<boolean>(getIsAddBtnDisabled());
const { isMobile } = WindowWidthManager.getAdaptivaRefs();
const internalAccept = computed<Array<string>>(() => props.accept?.split(',') || []);
const isAllFilesUploaded = computed(() => !fileTempList.value.some((file) => file.status === EFileStatus.Uploading));
const isShowMobileDrawer = computed(() => isMobile.value && props.mobileDrawer);
const isTextDrawerVisible = ref(false);
const replacementFile = ref<FileTemp>();

function getIsAddBtnDisabled(): boolean {
  return props.isDisabled || isMaxFilesCountAchieved();
}

function onClickAddButton(): void {
  if (isAddBtnDisabled.value) {
    return;
  }
  inputRef.value?.click();
}

function onChangeEvent(event: Event): void {
  if (props.disabledUpload) {
    return;
  }

  if (!(event.target as HTMLInputElement).files) {
    return;
  }
  addFilesToFileTemp(Array.from((event.target as HTMLInputElement).files));
}

async function onChangeReplaceEvent(event: Event): Promise<void> {
  if (props.disabledUpload) {
    return;
  }

  if (!(event.target as HTMLInputElement)?.files?.length) {
    return;
  }

  const file = Array.from((event.target as HTMLInputElement).files)?.[0];

  if (isFileValid(file) && isExtFileAllowed(file)) {
    const replacedFile = replacementFile.value.file;
    replacementFile.value.file = file;

    if (props.isImmediatelyUploadFiles) {
      await uploadFile(replacementFile.value, replacedFile);
    }

    onChangeValidFileTempList();
  } else {
    Notificator.showDetachedNotification('Ошибка при замене файла');
  }
  clearReplaceInput();
}

function onDropEvent(event: DragEvent): void {
  if (props.disabledUpload) {
    return;
  }

  if (!(event as DragEvent).dataTransfer || !(event as DragEvent).dataTransfer?.files) {
    return;
  }
  addFilesToFileTemp(Array.from((event as DragEvent).dataTransfer.files));
}

async function addFilesToFileTemp(addingFiles: Array<File>): Promise<void> {
  if (!props.isImmediatelyUploadFiles) {
    fileTempList.value = [
      ...addingFiles.reduce((acc: Array<FileTemp>, file) => {
        const fileTemp = FileTemp.getReactiveInstance(file);
        correctFileErrorStatus(fileTemp);
        return !isAllowedFile(file, acc.length) ? acc : [...acc, fileTemp];
      }, []),
      ...fileTempList.value,
    ];
    onChangeValidFileTempList();
    clearInput();
    return;
  }

  await promiseAllLimit(
    addingFiles.map((file) => async () => {
      if (!isAllowedFile(file)) {
        return;
      }
      const fileTemp = FileTemp.getReactiveInstance(file);
      const currentFileError = correctFileErrorStatus(fileTemp);
      fileTempList.value.unshift(fileTemp);

      if (!currentFileError) {
        await uploadFile(fileTemp);
      }
    }),
    props.limitParallelUpload,
  );
  clearInput();
}

function isAllowedFile(file: File, additionaItems = 0): boolean {
  return !isMaxFilesCountAchieved(additionaItems) && isFileValid(file);
}

function isExtFileAllowed(file: File): boolean {
  return internalAccept.value.some((ext) => file?.name?.toLowerCase().endsWith(ext));
}

function correctFileErrorStatus(fileTemp: FileTemp): string | null {
  if (!props.accept || isExtFileAllowed(fileTemp.file as File)) {
    return null;
  }
  const errorMessage = props.getFileExtensionError?.(fileTemp) || 'Данный тип файла не поддерживается';

  fileTemp.setError(errorMessage);
  fileTemp.setStatus(EFileStatus.Error);

  return errorMessage;
}

function isMaxFilesCountAchieved(additionalItems = 0): boolean {
  return !!props.maxAllowedFiles && fileTempList.value.length + additionalItems >= props.maxAllowedFiles;
}

function isFileValid(file: File): boolean {
  if (!props.size || FileHelper.isFileSizeValid(file.size, props.size)) {
    return true;
  }
  Notificator.showDetachedNotification('Размер файла превышает установленное ограничение 30 МБ');
  return false;
}

function emitAllFilesUploaded(): void {
  emit('allFilesUploaded', !fileTempList.value.some((file) => file.status === EFileStatus.Uploading));
}

function onTooltipIconClick() {
  if (!isShowMobileDrawer.value) {
    return;
  }
  isTextDrawerVisible.value = true;
}

async function uploadFile(fileTemp: FileTemp, replacedFile?: File | IFile): Promise<void> {
  if (!props.uploadFileFunction) {
    return;
  }
  try {
    fileTemp.setStatus(EFileStatus.Uploading);
    emit('onUpload', fileTemp);
    emitAllFilesUploaded();
    fileTemp.onUploaded(await props.uploadFileFunction(fileTemp));
    fileTemp.setStatus(EFileStatus.Default);
    emit('onUploaded', fileTemp);
    emitAllFilesUploaded();

    if (props.uploadedFileFunction) {
      await props.uploadedFileFunction(fileTemp);
    }

    if (replacedFile) {
      await props.deleteFileFunction({ file: replacedFile } as FileTemp);
      emit('onDeleted', fileTemp);
    }

    onChangeValidFileTempList();
  } catch (errorMessage) {
    if (replacedFile) {
      replacementFile.value.file = replacedFile;
      fileTemp.setStatus(EFileStatus.Default);
      Notificator.showDetachedNotification('Ошибка при замене файла');
      return;
    }

    fileTemp.setError(errorMessage || 'Ошибка при загрузке');
    fileTemp.setStatus(EFileStatus.Error);
    emit('onUploadError', fileTemp);
  }
}

async function onReplaceFile(fileTemp: FileTemp): Promise<void> {
  if (fileTemp.status === EFileStatus.Uploading) {
    return;
  }
  replacementFile.value = fileTemp;
  inputRefReplace.value?.click();
}

async function onDeleteFile(fileTemp: FileTemp): Promise<void> {
  emit('onDelete', fileTemp);
  if (!fileTemp.isUploaded) {
    fileTemp.status === EFileStatus.Uploading && fileTemp.cancelToken();
    deleteFileTempById(fileTemp.tempId);
    onChangeValidFileTempList();
    return;
  }

  if (fileTemp.status === EFileStatus.Error) {
    deleteFileTempById(fileTemp.tempId);
    emit('onDeleted', fileTemp);
    return;
  }

  if (fileTemp.status !== EFileStatus.Default || !props.deleteFileFunction) {
    return;
  }
  try {
    fileTemp.setStatus(EFileStatus.Deleting);
    await props.deleteFileFunction(fileTemp);
    deleteFileTempById(fileTemp.tempId);
    props.deletedFileFunction && props.deletedFileFunction(fileTemp);
    emit('onDeleted', fileTemp);
    onChangeValidFileTempList();
  } catch (errorMessage) {
    fileTemp.setError(errorMessage || 'Ошибка при удалении');
    fileTemp.setStatus(EFileStatus.Error);
    emit('onDeleteError', fileTemp);
  }
}

function deleteFileTempById(id: TFileId): void {
  fileTempList.value = fileTempList.value.filter(({ tempId }) => tempId !== id);
}

function onChangeValidFileTempList(): void {
  const fileList = fileTempList.value.reduce(
    (acc: Array<FileTemp | File | IFile>, fileTemp) =>
      props.isChangeFileListIncludeInvalid || fileTemp.status === EFileStatus.Default
        ? [...acc, props.isWrappedFileList ? fileTemp : FileHelper.getSourceFileByFileTemp(fileTemp)]
        : acc,
    [],
  );
  emit('onChangeFileList', fileList);
}

function clearInput(): void {
  (inputRef.value as HTMLInputElement).value = '';
}

function clearReplaceInput(): void {
  (inputRefReplace.value as HTMLInputElement).value = '';
  replacementFile.value = undefined;
}

async function onDownloadFile(fileTemp: FileTemp) {
  if (!props.downloadFileFunction || fileTemp.status !== EFileStatus.Default || props.isDisabled || !(fileTemp.file as IFile)?.id) {
    return;
  }
  try {
    fileTemp.setStatus(EFileStatus.Downloading);
    emit('onDownload', fileTemp);
    const response = await props.downloadFileFunction(fileTemp);
    downloadFileByBlob(response.data, (fileTemp.file as IFile).fileName);
    emit('onDownloaded', fileTemp);
    fileTemp.setStatus(EFileStatus.Default);
  } catch (error) {
    fileTemp.setStatus(EFileStatus.Error);
    emit('onDownloadError', fileTemp);
  }
}

function getPrevIconSrc(fileTemp: FileTemp): string {
  if (fileTemp.status === EFileStatus.Error) {
    return 'action/file-error';
  }
  if (FileHelper.isLoadingStatus(fileTemp.status)) {
    return 'indicators/progress';
  }
  return 'action/pdf';
}

function getPostIconSrc(fileTemp: FileTemp): string {
  if (fileTemp.error && !fileTemp.isUploaded) {
    return 'action/close';
  }
  return 'action/trash';
}

function getFileName(fileTemp: FileTemp): string {
  if (!props.isImmediatelyUploadFiles) {
    return (fileTemp.file as File)?.name || (fileTemp.file as IFile)?.fileName;
  }
  if (fileTemp.status === EFileStatus.Uploading) {
    return props.uploadingFileText;
  }
  return fileTemp.file?.[fileTemp.isUploaded ? props.uploadedFileFieldName : 'name'];
}

function setFileTempList(entityList: Array<FileTemp | IFile>): void {
  fileTempList.value = entityList.reduce(
    (acc: Array<FileTemp>, file) => [
      ...acc,
      FileTemp.isFileTempInstance(file) ? (file as FileTemp) : FileTemp.getReactiveInstance(file as IFile, true),
    ],
    [],
  );
  emit('onChangeFileList', fileTempList.value);
}

watchSubscription.add(
  watch(
    () => props.initialFileList,
    (fileList, prevFileList) => {
      return !isEqual(fileList, prevFileList) && setFileTempList(fileList);
    },
  ),

  watch(
    () => isAllFilesUploaded.value,
    (newValue) => emit('allFilesUploaded', newValue),
  ),

  watch(
    () => [props.isDisabled, props.maxAllowedFiles, fileTempList.value],
    () => (isAddBtnDisabled.value = getIsAddBtnDisabled()),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

defineExpose({
  onDeleteFile,
  uploadFile,
  onDownloadFile,
});

onMounted(() => {
  if (props.initialFileList) {
    setFileTempList(props.initialFileList);
    emitAllFilesUploaded();
  }
});
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

$root: '.file-uploader';

#{$root} {
  width: 100%;

  .mr-8 {
    margin-right: 8px;
  }

  &-wrapper {
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 400px;

    &.empty {
      border-radius: 8px;
    }

    .file-name-wrapper {
      flex: 1;
      display: flex;
      max-width: 100%;
      flex-shrink: 1;
      min-width: 0;
      margin-right: 150px;

      .text {
        font-style: normal;
        font-size: 14px;
        color: $text-dark-green;
        line-height: 20px;
        margin-right: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .file-extension {
      font-size: 14px;
      line-height: 20px;
    }

    .replace-button {
      margin-right: 56px;
      font-weight: 500;
      padding: 14px 0;
      position: absolute;
      right: 0;
    }

    .text {
      font-style: normal;
      font-size: 14px;
      color: $text-dark-green;
      line-height: 20px;
      margin-right: 15px;

      :deep(.mm-tooltip.mm-tooltip--base) {
        width: unset !important;

        .popper {
          max-width: 380px;
        }
      }

      span {
        font-weight: 500;
        line-height: 20px;
        margin-right: 8px;
      }

      &__icon {
        margin-right: 13px;
        width: 24px;
        height: 24px;
        flex-shrink: 0;

        &.animated {
          animation: progress $loader-transition-speed infinite linear;
          animation-fill-mode: both;
        }
      }

      .tooltip-circle {
        border-radius: 50%;
        background-color: transparent;
        position: relative;
        width: 20px;
        height: 20px;
        transition: $transition-speed;

        &:hover {
          background-color: $light-gray;
        }

        .text__icon__tooltip {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    &__control {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;

      .text-wrapper {
        display: flex;

        & > .text {
          margin-right: 20px;
        }
      }

      &__button {
        display: block;
      }
    }

    &__list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      margin-top: 24px;

      &.small {
        margin-top: 14px;
      }
    }

    &__list-item {
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 14px 16px 14px 17px;
      margin-bottom: 8px;
      background-color: $file-uploader-item-bc;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &--error {
        background-color: $red-error;

        .text {
          color: $text-dark-green;
        }

        .error-text {
          color: $file-uploader-error-c;
          font-size: 12px;
          line-height: 16px;
          margin-right: 37px;
        }
      }

      &--impossible {
        background-color: $orange-error;
      }

      &--uploaded {
        cursor: pointer;
      }

      .icon {
        margin-right: 10px;
        flex-shrink: 0;

        &.animated {
          animation: progress $loader-transition-speed infinite linear;
        }
      }

      .button {
        cursor: pointer;
        flex-shrink: 0;
        position: absolute;
        right: 16px;
        top: 14px;
        width: 24px;
        height: 24px;

        &--disabled {
          opacity: 0.4;
        }

        ::v-deep(path) {
          transition: 0.1s;
        }

        &:hover {
          ::v-deep(path) {
            fill: $link;
          }
        }
      }

      .text {
        flex: initial;
      }
    }
  }

  :deep(.file-uploader-wrapper__control__button.mobile) {
    display: none;
  }

  &--bordered {
    .file-uploader-wrapper {
      padding: 24px 28px;
      border: 1px solid $file-uploader-br;
      transition: border-color $transition-speed ease-out;

      &:hover {
        border-color: $link;
      }

      &.empty {
        border: 0 solid transparent;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E6E6E6FF' stroke-width='2' stroke-dasharray='8%2c 8' stroke-dashoffset='19' stroke-linecap='round'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        overflow: unset;

        &:hover {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%2308A553' stroke-width='2' stroke-dasharray='8%2c 8' stroke-dashoffset='19' stroke-linecap='round'/%3e%3c/svg%3e");

          :deep(.mm-file-uploader__button) {
            color: $link;
          }
        }
      }
    }
  }

  &--small {
    &.empty {
      height: 48px;
      padding: 14px 16px;
    }

    .file-uploader-wrapper {
      padding: 12px 16px;
    }
  }

  &--mobile {
    .file-uploader-wrapper {
      padding: 16px;
      min-height: 136px;

      &__control {
        flex-direction: column;
        align-items: flex-start;

        :deep(.inline-block) {
          width: 100%;
          margin-top: 16px;

          .file-uploader-wrapper__control__button {
            width: 100%;
          }
        }

        .text {
          max-width: 280px;

          .text-wrapper {
            align-items: flex-end;
          }

          :deep(.mm-tooltip--base) {
            height: 20px;
          }
        }
      }
    }
  }

  &__error {
    color: $file-uploader-error-c;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    flex-basis: 100%;
    margin-top: 2px;
  }
}

@keyframes progress {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1599.9px) {
  #{$root} {
    &-wrapper {
      &__list {
        &-item {
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767.9px) {
  #{$root} {
    &-wrapper {
      &__control {
        flex-direction: column;
        align-items: flex-start;

        :deep(.file-uploader-wrapper__control__button) {
          display: none;
        }
      }

      &__list {
        &-item {
          margin-bottom: 20px;
          padding: 12px 16px;
          flex-wrap: wrap;

          &:last-child {
            margin-bottom: 0;
          }

          .error-text {
            width: 100%;
            margin: 6px 0 0;
          }

          .button {
            top: 12px;
          }
        }
      }

      :deep(.file-uploader-wrapper__control__button.mobile) {
        display: inline-block;
        width: 100%;
        margin-top: 20px;

        .mm-file-uploader__button {
          width: 100%;
        }
      }
    }
  }
}
</style>
