import { CustomError } from 'shared/utils/errors/customError.util';
import { ABORTED_BY_ABORT_CONTROLLER_ERROR } from 'shared/constants/error.const';

export class AbortControllerUtil {
  private static abortController: AbortController;
  public static readonly AbortedByAbortControllerError = ABORTED_BY_ABORT_CONTROLLER_ERROR;

  static isRequestAborted(error: Error): boolean {
    return error?.name === ABORTED_BY_ABORT_CONTROLLER_ERROR;
  }

  static createAbortControllerError(message = 'Request aborted by AbortController'): CustomError {
    return new CustomError(ABORTED_BY_ABORT_CONTROLLER_ERROR, message);
  }

  static createAbortController(): AbortController {
    this.abortController = new AbortController();
    return this.abortController;
  }

  static getSignalAbortController(): AbortSignal {
    const signal = this.abortController?.signal;
    this.abortController = undefined;
    return signal;
  }
}