import PerfectScrollbar from 'perfect-scrollbar';
import { IUsePerfectScrollbar } from '../models/composables/usePerfectScrollbar.model';

export function usePerfectScrollbar(
  element?: string | Element,
  options?: PerfectScrollbar.Options,
): IUsePerfectScrollbar {
  const instance = ref<PerfectScrollbar | null>(null);

  function init(el = element): void {
    if (!process.client || !el) {
      return;
    }

    if (instance.value) {
      destroy();
    }

    nextTick(() => {
      if (!el || (typeof el === 'string' && !document.querySelector(el))) {
        return;
      }

      instance.value = new PerfectScrollbar(el, options);
    });
  };

  function destroy(): void {
    instance.value?.destroy();
    instance.value = null;
  }

  function update(el = element): void {
    if (!el) {
      return;
    }

    if (!instance.value) {
      init(el);
    }

    instance.value?.update();
  }

  return {
    instance,
    init,
    destroy,
    update,
  };
}
