import sanitizeHtml from 'sanitize-html';
import pkg, { IDefaults, IOptions } from 'sanitize-html';
import { App } from 'vue';

const { defaults } = pkg;

export const FILTER_BASIC: IDefaults = defaults;
export const FILTER_INLINE: IOptions = {
  allowedTags: ['a', 'b', 'br', 'code', 'em', 'i', 'span', 'strike', 'strong', 'u', 'ul', 'li', 'del'],
  allowedAttributes: {
    a: ['href', 'target', 'style'],
    span: ['style'],
    ul: ['class', 'style'],
  },
  selfClosing: ['br'],
  allowedSchemes: ['ftp', 'http', 'https', 'mailto'],
  parser: {
    decodeEntities: true,
  },
};

export const FILTER_NOTHING: IOptions = {
  allowedTags: false,
  allowedAttributes: false,
};

export const FILTER_STRIP: IOptions = {
  allowedTags: [],
  allowedAttributes: {},
};

type SanitizeModifier = 'strip' | 'basic' | 'inline' | 'nothing';

export function sanitize(dirty: string, modifiers?: SanitizeModifier, opts = undefined): string {
  switch (modifiers) {
    case 'strip':
      return sanitize(dirty, FILTER_STRIP);
    case 'inline':
      return sanitizeHtml(dirty, FILTER_INLINE);
    case 'nothing':
      return sanitizeHtml(dirty, FILTER_NOTHING);
    case 'basic':
    default:
      return sanitizeHtml(dirty, opts);
  }
}

const VSanitize = {
  install(Vue: App<Element>, options: Record<string, string> = {}) {
    const defaultOptions = options;
    const { name = 'html-sanitize' } = defaultOptions;
    delete defaultOptions.name;
    const customOptions = { ...defaultOptions } as IOptions;

    Vue.directive(name, (el, binding) => {
      if (binding.value === binding.oldValue) {
        return;
      }

      if (Array.isArray(binding.value)) {
        el.innerHTML = sanitizeHtml(binding.value[1], binding.value[0]);
        return;
      }

      if (binding.modifiers.strip) {
        el.innerHTML = sanitizeHtml(binding.value, FILTER_STRIP);
      } else if (binding.modifiers.basic) {
        el.innerHTML = sanitizeHtml(binding.value, customOptions);
      } else if (binding.modifiers.inline) {
        el.innerHTML = sanitizeHtml(binding.value, FILTER_INLINE);
      } else if (binding.modifiers.nothing) {
        el.innerHTML = sanitizeHtml(binding.value, FILTER_NOTHING);
      } else {
        el.innerHTML = sanitizeHtml(binding.value, customOptions);
      }

      console.error('Не использовать v-html-sanitize. Вместо этого обратиться к v-html=sanitize()');
    });
  },
  defaults: FILTER_BASIC,
  getSSRProps() {
    // you can provide SSR-specific props here
    return {};
  },
};

export default VSanitize;
