import { DateFormat } from './dateFormat.util';
import { ESDCertsConsts } from '../constants/esdCerts.const';
import { IVerifiedCertInfo, ICertAlgorithmInfo, ICryptoProCert, IMappedCert, IRawSubjectCertInfo, ISubjectCertInfo } from '../models/sign-esd/certificate.model';

export class EsdCertHelpers {
  static getVisibleCerts(certs: Array<IMappedCert>): Array<IMappedCert> {
    return certs.filter((cert) => !!cert.algorithm?.key && cert.valid
    && (cert.subjectInfo?.inn || cert.subjectInfo?.innle));
  }

  static getVerifiedCertInfo(signingTime: string, rawSubjectInfo: string): IVerifiedCertInfo {
    return {
      signingTime: DateFormat.withHoursMinutes(signingTime),
      subjectInfo: this.mapRawSubjectInfo(rawSubjectInfo),
      valid: true,
    } as IVerifiedCertInfo;
  }

  static getMappedCert({ Issuer, algorithm, SubjectName, ValidToDate, ValidFromDate, thumbprint, valid }: ICryptoProCert): IMappedCert {
    return {
      thumbprint,
      valid,
      validFrom: DateFormat.default(ValidFromDate),
      validTo: DateFormat.default(ValidToDate),
      issuerInfo: this.mapRawSubjectInfo(Issuer),
      subjectInfo: this.mapRawSubjectInfo(SubjectName),
      algorithm,
    } as IMappedCert;
  }

  static async getAlgorithm(cadesCertificate) {
    let cadesPublicKey;
    const algorithmInfo: ICertAlgorithmInfo = {
      algorithmName: '',
      oid: '',
      key: '',
    };

    try {
      cadesPublicKey = await cadesCertificate.PublicKey();
      cadesPublicKey = await cadesPublicKey.Algorithm;
      algorithmInfo.algorithmName = await cadesPublicKey.FriendlyName;
      algorithmInfo.oid = await cadesPublicKey.Value;
      algorithmInfo.key = ESDCertsConsts.oidToKey[algorithmInfo.oid];
    } catch (error) {
      return Promise.reject('Ошибка при получении алгоритма');
    }
    return algorithmInfo;
  };

  private static mapRawSubjectInfo(rawSubjectStr: string): ISubjectCertInfo {
    const subjectSplitted = this.mapRawCertStringToInfo(rawSubjectStr);
    const { CN, O, L, C, INN, OGRN, G, SN, T, SNILS, INNLE, E, KPP, OGRNIP } = Object.fromEntries(subjectSplitted) as IRawSubjectCertInfo;

    return {
      certName: CN,
      organization: O,
      location: L,
      country: C,
      inn: INN as unknown as number,
      ogrn: OGRN as unknown as number,
      ogrnip: OGRNIP as unknown as number,
      kpp: KPP as unknown as number,
      firstName: G,
      surname: SN,
      title: T,
      snils: SNILS as unknown as number,
      innle: INNLE as unknown as number,
      email: E,
    } as ISubjectCertInfo;
  }

  private static mapRawCertStringToInfo(rawSubjectStr: string) {
    return rawSubjectStr?.split(', ').map((subjectAttr) => {
      const attrKey = ESDCertsConsts.translatedCertKeys[subjectAttr.split('=')[0]] ?? subjectAttr.split('=')[0];

      return [attrKey, subjectAttr.split('=')[1]];
    });
  }
}
