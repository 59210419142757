import { IApproveRule } from 'models/client/approveRule.model';
import { AuthHelper } from 'utils/authHelper.util';
import { IBasisApproveRule } from 'models/client/approveRule.model';

export class ApproveRuleApiService {
  public static async getApproveRulesByClientId(clientId: number): Promise<Array<IApproveRule>> {
    return await AuthHelper.fetch<Array<IApproveRule>>(`/api/v1/approve_rules/${clientId}/groups`, { method: 'GET' });
  }

  public static async getBasisApproveRuleByBasisId(basisId: number): Promise<IBasisApproveRule |  null> {
    return await AuthHelper.fetch<IBasisApproveRule | null>(`/api/v1/basis/${basisId}/approve_rule`, { method: 'GET' });
  }
}
