import { SentryRequest, Session } from '@sentry/types';
import axios from 'axios';
import { Event, Response, Transports } from '@sentry/vue';
import { getStoreEndpointWithUrlEncodedAuth } from '@sentry/core';
import { CircularJson } from '../../circularJson.util';

export class ClientSentryTransport extends Transports.BaseTransport {
  protected async _sendRequest(sentryRequest: SentryRequest, originalPayload: Event | Session): Promise<Response> {
    try {
      await axios.post('/api/sentry', sentryRequest.body, {
        headers: {
          'content-type': 'text/plain;charset=UTF-8',
        },
        params: {
          transportUrl: getStoreEndpointWithUrlEncodedAuth(this._api.dsn),
          type: sentryRequest.type,
        },
        maxBodyLength: Number.MAX_SAFE_INTEGER,
      });

      return {
        status: 'success',
        event: originalPayload,
      };
    } catch (error) {
      return {
        status: 'failed',
        event: originalPayload,
        reason: (error as Error)?.message,
      };
    }
  }

  sendEvent(event: Event): PromiseLike<Response> {
    const preparedEvent = this.prepareEvent(event);
    return this._sendRequest({
      body: CircularJson.stringify(preparedEvent),
      type: preparedEvent.type || 'event',
      url: getStoreEndpointWithUrlEncodedAuth(this._api.dsn),
    }, preparedEvent);
  }

  sendSession(session: Session): PromiseLike<Response> {
    return this._sendRequest({
      body: CircularJson.stringify(session),
      type: 'session',
      url: getStoreEndpointWithUrlEncodedAuth(this._api.dsn),
    }, session);
  }

  private prepareEvent(event: Event): Event {
    return {
      message: 'Неизвестная ошибка на клиенте',
      ...(event || {}),
      exception: event?.exception?.values?.length
        ? event.exception
        : {
          values: [{
            type: 'Неизвестная ошибка',
            value: 'Произошла неожиданная ошибка на клиенте',
          }],
        },
      extra: {
        ...(event?.extra || {}),
        platform: 'client',
      },
    };
  }
}
