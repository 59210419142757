export class ControlSumChecker {
  static checkInn(value: string | undefined){
    if (!value) {
      return false;
    }
    if ((value.length !== 10 && value.length !== 12) ||
      value.split('').some((symbol) => isNaN(Number(symbol)))
    ) {
      return false;
    }
    if (value.length === 10){
      return Number(value[9]) === (value.split('').slice(0, -1)
        .reduce(
          (summ, symbol, index) =>
            [2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
          0)
        % 11) % 10;
    }

    if (value.length === 12){
      const checkSumOne = (value.split('').slice(0, -2)
        .reduce(
          (summ, symbol, index) =>
            [7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
          0)
        % 11 ) % 10;

      const checkSumTwo = (value.split('').slice(0, -1)
          .reduce(
            (summ, symbol, index) =>
              [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
            0)
        % 11 ) % 10;

      return (checkSumOne === Number(value[10]) && checkSumTwo === Number(value[11]));
    }
  }

  static checkOgrn(ogrn: string | undefined) {
    if (!ogrn) {
      return false;
    }
    if (ogrn.length !== 13 && ogrn.length !== 15) {
      return false;
    }

    if (ogrn.length === 15) {
      const n15 = parseInt((parseInt(ogrn.slice(0, -1)) % 13).toString().slice(-1));
      return n15 === parseInt(ogrn[14]);
    }

    if (ogrn.length === 13) {
      const n13 = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1));
      return n13 === parseInt(ogrn[12]);
    }
  }
};
