export enum EBasketPlacementStatus {
  Active = 'ACTIVE',
  OfferGone = 'OFFER_GONE',
  NoItems = 'NO_ITEMS',
  NoMapping = 'NO_MAPPING',
  BudgetLimit = 'BUDGET_LIMIT',
  OrderMinCost = 'ORDER_MIN_COST',
  OrderMaxCost = 'ORDER_MAX_COST',
  NoStock = 'NO_STOCK',
  QuantOverflow = 'QUANT_OVERFLOW',
  ItemMinCost = 'ITEM_MIN_COST',
  ItemMaxCost = 'ITEM_MAX_COST',
  InvalidDelivery = 'INVALID_DELIVERY',
  MinSum = 'MIN_SUM',
  ErpError = 'ERP_ERROR',
  NotSyncWithDuplicate = 'NOT_SYNC_WITH_DUPLICATE'
}
