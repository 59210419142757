import { IRetryOptions, IRetryRequestOptions } from '../models/apiHelper.model';

export const RETRY_REQUEST_OPTIONS: IRetryRequestOptions<unknown, unknown> = {
  retryErrorTimeout: 300,
  retryTimeout: 1000,
  maxErrorRetry: 0,
};

export const RETRY_OPTIONS: IRetryOptions = {
  currentErrorRetry: 0,
};
