import { WatchStopHandle } from '@vue/runtime-core';

export class WatchSubscription {
  private _subscriptions: Array<WatchStopHandle> = [];

  constructor(subscription?: WatchStopHandle) {
    if (!subscription) {
      return;
    }

    this.add(subscription);
  }

  public add(...subscription: Array<WatchStopHandle>){
    this._subscriptions.push(...subscription);
  }

  public unsubscribe(){
    this._subscriptions.forEach((unwatchFunction)=> unwatchFunction());
  }
}
