export class UrlHelper {
  static isValidUrl(url: string) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '(([a-zA-Z\\d])+\\:([a-zA-Z\\d])+\\@)?' + // authentication
      '((([a-zа-яеёA-ZА-ЯЕЁ\\d]([a-zа-яеёA-ZА-ЯЕЁ\\d-]{0,61}[a-zа-яеёA-ZА-ЯЕЁ\\d])*\\.)+' + // sub-domain + domain name
      '[a-zа-яеёA-ZА-ЯЕЁ]{2,13})' + // extension
      '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
      '|localhost)' + // OR localhost
      '(\\:\\d{1,5})?' + // port
      '(\\/[a-zа-яеёA-ZА-ЯЕЁ\\&\\d%_.~+-:@]*)*' + // path
      '(\\?[a-zа-яеёA-ZА-ЯЕЁ\\&\\d%_.,~+-:@=;&]*)?' + // query string
      '(\\#[-a-zа-яеёA-ZА-ЯЕЁ&\\d_]*)?$'); // fragment locator
    return !!pattern.test(url);
  }

  static getRelativeUrlPath(url: string) {
    return url?.split('/').pop();
  }

  static getRootUrlPath(url: string) {
    return url?.split('/').filter((part) => part).shift();
  }
}
