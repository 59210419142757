import { ref, ComputedRef, computed } from 'vue';
import { ProviderService } from 'shared/models/providerService.model';
import { IBasketCounter } from 'models/basketCounter.model';
import Notificator from 'shared/services/notificator.service';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { UserHelper } from 'utils/userHelper.util';
import { BasketManager } from './basketManager.service';

export class BasketCounter extends ProviderService implements IBasketCounter {
  public counter = ref(0);
  public basisId = ref<number | null>(null);
  public shortedCount: ComputedRef<string>;
  public static readonly serviceName = 'basketCount';
  private static instance: BasketCounter | undefined;

  constructor() {
    super();
    if (BasketCounter.instance) {
      return BasketCounter.instance;
    }

    BasketCounter.instance = this;
    this.shortedCount = computed<string>(() => {
      if (!this.counter.value) {
        return '';
      }

      return this.counter.value > 99 ? '99+' : String(this.counter.value);
    });
  }

  public async loadCounter(): Promise<void> {
    if (process.server || !UserHelper.isClient) {
      return;
    }

    try {
      const basketManager = new BasketManager();
      const data = await basketManager.getCounterAndBasisId();
      this.counter.value = data.count;
      this.basisId.value = data.basisId;
    } catch (error) {
      clientSentry.captureServiceException(error, BasketCounter.getServiceName());
      Notificator.showDetachedNotification('Произошла ошибка при загрузке количества товаров в корзине. Попробуйте обновить страницу');
    }
  }

  public clear(): void {
    this.counter.value = 0;
  }

  public setCount(count: number, basisId?: number): void {
    this.counter.value = count;
    if (basisId) {
      this.basisId.value = basisId;
    }
  }
}
