import { clientSentry } from '../utils/sentry/clientSentry.util';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:error', (error) => {
    console.error(error);
    clientSentry.captureException?.(error);
  });

  nuxtApp.hook('vue:error', (error) => {
    console.error(' => vue:error',error);
  });

  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    console.error(error);
    clientSentry.captureException?.(error, {
      extra: {
        instance,
        info,
      },
    });
  };
});
