import { ISelectOption } from 'shared/models/select.model';
import BasesService from 'services/basesManager.service';
import Loader from 'shared/utils/loaderHelper.util';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { StringsHelper } from 'shared/utils/strings.util';
import { IUseClientBasesForSelect } from 'models/composables/useClientBasesForSelect.model';
import { UserHelper } from 'utils/userHelper.util';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';
import Notificator from 'shared/services/notificator.service';

export function useClientBasesForSelect(
  initialSelectedBasisId?: number,
  initialSubdivisionId?: number,
  initOnMount = false,
  needSubdivision = UserHelper.isAIM || !UserHelper.isIntegration,
): IUseClientBasesForSelect {
  const basesManager = inject<BasesService>(BasesService.getServiceName());

  const watchSubscription = new WatchSubscription();

  const selectedBase = ref<ISelectOption | undefined>();
  const selectedSubdivision = ref<ISelectOption | undefined>();

  const loadingBases = Loader.getReactiveInstance(true);

  const basesToShow = computed<Array<ISelectOption>>(
    () => (basesManager?.userBases || [])
      .sort((baseA, baseB) => {
        const baseAName = baseA.name?.toLowerCase();
        const baseBName = baseB.name?.toLowerCase();

        return baseAName < baseBName
          ? -1
          : baseAName > baseBName
            ? 1
            : 0;
      })
      .map((base) => ({
        label: base.name,
        value: base.id,
        selectedDescription: StringsHelper.arrayToString([base.name, base.locality, base.street, base.home]),
        description: `${StringsHelper.arrayToString([base.locality, base.street, base.home])}<br>${base.businessEntityName}`,
        base,
      })),
  );

  const subdivisionsToShow = computed(
    () => {
      if (!selectedBase.value) {
        return [];
      }

      const bases = (basesManager?.userBases || []).find(
        (bases) => bases.id === selectedBase.value?.value,
      );

      if (!bases) {
        return [];
      }

      return (bases.subdivisions || []).map((subdivision) => ({
        label: subdivision?.name,
        value: subdivision?.id,
        subdivision,
      }));
    },
  );

  async function initBases(): Promise<void> {
    loadingBases.activate();
    try {
      await basesManager?.getUserBases();

      initSelectedBasis();
    } catch (error) {
      clientSentry.captureComposableException(
        error,
        'useClientBasesForSelect',
      );

      Notificator.showDetachedNotification('Ошибка при загрузке базисов');
    } finally {
      loadingBases.deactivate();
    }
  }

  function initSelectedBasis(): void {
    if (initialSelectedBasisId) {
      selectedBase.value = basesToShow.value
        .find((base) => base.value === initialSelectedBasisId);
    }

    if (!selectedBase.value && basesToShow.value.length === 1) {
      selectedBase.value = basesToShow.value[0];
    }
  }

  watchSubscription.add(
    watch(
      subdivisionsToShow,
      (newSubdibisionsToShow) => {
        selectedSubdivision.value = undefined;

        if (!needSubdivision) {
          return;
        }

        if (initialSubdivisionId) {
          selectedSubdivision.value = newSubdibisionsToShow.find(
            (subdivision) => subdivision.value === initialSubdivisionId,
          );
        }

        if (!selectedSubdivision.value && newSubdibisionsToShow.length === 1) {
          selectedSubdivision.value = newSubdibisionsToShow[0];
        }
      },
    ),
  );

  useSSRUnsubscribeWatch(watchSubscription);

  onMounted(async () => initOnMount && await initBases());

  return {
    selectedBase,
    selectedSubdivision,

    loadingBases,

    basesToShow,
    subdivisionsToShow,

    initBases,
  };
}
