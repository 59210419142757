<template>
  <Modal
    click-to-close
    z-index="21"
    title-text="Адрес доставки"
    hide-footer
    :model-value="modelValue"
    classes="modal fixed-bottom-modal bottom-0 delivery-address-modal"
    @update:model-value="$emit('update:modelValue', $event)"
    @close="$emit('close')"
  >
    <div class="delivery-address__main">
      <Select
        v-model="selectedCity"
        :options="searchItemsToShow"
        :clearable="false"
        :type="ESelectType.DescriptionList"
        placeholder="Введите ваш город"
        is-hide-no-results-label
        @search="onCityInput"
        @option:selected="onFindCitySelect"
      >
        <template #open-indicator>
          <SvgIcon src="navigation/search" />
        </template>
      </Select>
      <div class="delivery-address__col">
        <ul
          v-if="cities"
          class="delivery-address__list"
        >
          <li
            v-for="city in cities"
            :key="city.id"
            class="delivery-address__item"
          >
            <NuxtLink
              class="delivery-address__link"
              @click="onCitySelect(city)"
            >
              {{ city?.city ?? city?.region }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from 'shared/components/modals/Modal.vue';
import { IDeliveryCity } from 'shared/models/cities.model';
import BasesService from 'services/basesManager.service';
import { IDeliveryAddress, IDeliveryAddressData } from 'shared/models/deliveryAddress.model';
import { AddressApiService } from 'shared/services/api/addressApi.service';
import { debounce } from 'lodash-es';
import Select from 'shared/components/Select.vue';
import { ESelectType } from 'shared/enums/select.enum';
import { ISelectOption } from 'shared/models/select.model';
import Notificator from 'shared/services/notificator.service';

defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean);
  (e: 'close');
}>();

const basesManager = inject<BasesService>(BasesService.getServiceName());
const cities = ref<Array<IDeliveryCity>>(await basesManager?.getPopulation());
const searchText = ref<string>();
const searchItemsToShow = computed<Array<ISelectOption>>(() =>
  basesManager?.searchedAddresses?.map(
    (base) => ({
      label: base.data.city ?? base.data.cityWithType,
      value: base.data.city ?? base.data.cityWithType,
      regionFiasId: base.data.regionFiasId,
      description: getSearchedAddressDescription(base.data),
    }) ?? []) || [],
);
const selectedCity = ref<ISelectOption>();

function getSearchedAddressDescription({ city, cityWithType, region, regionWithType }: IDeliveryAddressData): string {
  const cityDesc = city ?? cityWithType;
  const regionDesc = regionWithType ?? region;
  return cityDesc === regionDesc ? cityDesc : [cityDesc, regionDesc].filter((value) => !!value).join(', ');
}

function searchLocalities() {
  basesManager?.getSearchedCity(searchText.value);
}

const debounceSearch = debounce(searchLocalities, 300);

function updateCityInCookie(fiasId: string) {
  const base = {
    fiasId,
    name: basesManager?.city,
  };
  basesManager?.setBasesCityInfo(base);
  if (basesManager) {
    basesManager.currentUserBase = base;
  }

  // setTimeout для того, чтобы успело поставиться значение в Cookie
  setTimeout(() => emit('close'));
}

function onCityInput(value: string) {
  if (!value) {
    return;
  }

  searchText.value = value;
  debounceSearch();
}

function onFindCitySelect() {
  if (basesManager) {
    basesManager.city = selectedCity.value.value;
  }
  updateCityInCookie(selectedCity.value.regionFiasId);
}

async function onCitySelect(city: IDeliveryAddress | IDeliveryAddressData) {
  const addressData = ((c): c is IDeliveryAddress => !!(c as IDeliveryAddress)?.data)(city)
    ? city.data
    : city;

  if (basesManager) {
    basesManager.city = addressData?.city ?? addressData.region;
  }

  try {
    const basis = await AddressApiService.getSearchedCity(basesManager?.city);
    const fiasId = basis?.[0]?.data?.regionFiasId;

    updateCityInCookie(fiasId);
  }
  catch (err) {
    Notificator.showDetachedNotification('Ошибка выбора города доставки');
  }
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

:deep(.vfm__overlay){
    background-color: rgba(0 0 0 / 80%) !important;
}

:deep(.modal) {
  .modal-header {
    margin-bottom: 32px;

    .btn {
      height: 36px;
    }
  }
}

:deep(.vs--single) {
  &.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
    opacity: 1
  }
}

.delivery-address__main {
  position: relative;

  :deep(.v-select) {
    .vs__dropdown-toggle {
      height: 58px;
    }
  }

  :deep(.mm-select) {
      padding-bottom: 0 !important;
  }

  .delivery-address__col {
    margin-top: 32px;
  }

  .delivery-address__list {
    margin: 0;
    padding: 0;
    display: flex;
    max-height: 132px;
    flex-flow: wrap column;
  }

  .delivery-address__item {
    list-style-type: none;
    margin-bottom: 2px;
    width: 50%;

    a {
      font-size: 14px;
      line-height: 20px;
    }

    &:nth-child(5),
    &:last-child {
      margin-bottom: 0;
    }
  }

  .delivery-address__link {
    text-decoration: none;
    color: $btn-text-c-h;

    &:hover {
      color: $btn-text-c;
      cursor: pointer;
    }
  }

  :deep(.v-select) {
    .vs__dropdown-toggle {
      padding: 16px;

      .vs__search {
      padding-left: 0;
      }

      .vs__actions {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  :deep(.modal) {
    .modal-header {
      margin-bottom: 24px;
    }
  }

  :deep(.delivery-address-modal) {
    .modal-inner-content {
      overflow-y: unset;

      .vs__dropdown-menu {
        max-height: 325px;
      }
    }
  }

  .delivery-address__main {
    .delivery-address__col {
      margin-top: 10px;
    }
  }

  .delivery-address__main {
    :deep(.mm-input) {
      .mm-input__input {
        height: 40px;
        padding: 8px 12px;
      }
    }

    :deep(.v-select) {
      .vs__dropdown-toggle {
        height: 40px;
        padding: 10px 12px;
      }
    }

    :deep(.vs__dropdown-toggle) {
      .vs__actions {
        padding: 0;
        padding-right: 6px;
      }
    }

    .delivery-address__list {
      display: flex;
      flex-flow: nowrap column;
      max-height: fit-content;

      .delivery-address__item {
        margin-bottom: 6px;

        a {
          font-size: 14px;
          line-height: 20px;
        }

        &:last-child {
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>
