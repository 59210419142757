import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import NotificatorSettings from '../constants/notificator.const';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Toast, {
    ...NotificatorSettings.DEFAULT_NOTIFICATION_PLUGIN_SETTINGS,
  });
});
