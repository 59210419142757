import { $fetch } from 'ofetch';
import { IDeliveryAddress } from 'shared/models/deliveryAddress.model';
import { EAddressBounds } from 'shared/enums/addressBounds.enum';
import { AuthHelper } from 'utils/authHelper.util';
export class AddressApiService {
  static async getSearchedAddress(searchValue: string): Promise<Array<IDeliveryAddress>> {
    return await $fetch('/api/v1/da_data/address/suggest', { method: 'GET', params: { address: searchValue }, retry: 0 }).then((res) => {
      return res.addresses;
    });
  }

  static async getSearchedCity(searchValue: string): Promise<Array<IDeliveryAddress>> {
    return await $fetch('/api/v1/da_data/address/suggest/city', { method: 'GET', params: { address: searchValue }, retry: 0 }).then((res) => {
      return res;
    });
  }

  static async getSearchedAddresessSuggestions(searchValue: string, fromBound: EAddressBounds, toBound: EAddressBounds): Promise<Array<IDeliveryAddress>> {
    return await AuthHelper.fetch('/api/v2/address/suggestions', { method: 'POST', body: {
      address: searchValue,
      fromBound,
      toBound,
    }});
  }
}
