import { INotification } from 'models/notification.model';
import Disabler from 'shared/utils/disablerHelper.util';
import { ProviderService } from 'shared/models/providerService.model';
import { NotificationApiService } from './api/notificationApi.service';
import { ComputedRef } from 'vue';
import { AuthManagerService } from 'services/auth/authManager.service';

export class BackNotificationService extends ProviderService {
  static readonly serviceName = 'backNotificationService';
  public unreadCount = ref<number>(0);
  public notifications = ref<Array<INotification>>([]);
  public isLoadingCount = Disabler.getReactiveInstance();
  public isLoadingNewNotifications = Disabler.getReactiveInstance();
  public isLoadingNotifications = Disabler.getReactiveInstance();
  public shortedUnreadCount: ComputedRef<string>;
  public isVisibleNotificationsDrawer = ref(false);
  public page = ref(0);
  public pageSize = 10;
  public isLast = false;

  private _interval: ReturnType<typeof setTimeout> | null = null;
  private _timeoutFromConfig = useRuntimeConfig()?.public?.backNotificationTimeout?.toString();
  // Время задержки в миллисекундах между запросами на кол-во новых уведомлений
  private _timeout = (parseFloat(this._timeoutFromConfig) || 1) * 60 * 1000;

  private static _instance: BackNotificationService;

  private constructor() {
    super();
    this.initComputedShortedCount();
  }

  public async loadNewNotifications(): Promise<void> {
    if (this.isLoadingNewNotifications.value || this.isLoadingNotifications.value) {
      return;
    }

    try {
      this.isLoadingNewNotifications.activate();
      const { items } = await NotificationApiService.getNotifications(0, this.pageSize);
      this.notifications.value = [...this.filterNotExistsNotifications(items), ...this.notifications.value];
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingNewNotifications.deactivate();
    }
  }

  public async loadNotifications(): Promise<void> {
    if (this.isLoadingNotifications.value || this.isLast) {
      return;
    }

    try {
      this.isLoadingNotifications.activate();
      const { items } = await NotificationApiService.getNotifications(this.page.value, this.pageSize);

      if (!items.length) {
        this.isLast = true;
      } else {
        this.notifications.value = [...this.notifications.value, ...this.filterNotExistsNotifications(items)];
        this.page.value++;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingNotifications.deactivate();
    }
  }

  public static getInstance(): BackNotificationService {
    if (!BackNotificationService._instance) {
      BackNotificationService._instance = new BackNotificationService();
    }

    if (process.client) {
      BackNotificationService._instance.startRefreshNotificationsCount();
    }

    return BackNotificationService._instance;
  }

  public async startRefreshNotificationsCount(): Promise<void> {
    await this.refresh();
  }

  public stopRefreshNotificationsCount(): void {
    if (this._interval) {
      clearTimeout(this._interval);
      this._interval = null;
    }
  }

  public async loadNotificationsCount(): Promise<void> {
    if (this.isLoadingCount.value) {
      return;
    }

    try {
      this.isLoadingCount.activate();
      this.unreadCount.value = (await NotificationApiService.getNotificationsCount())?.unreadCount;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingCount.deactivate();
    }
  }

  public async readNotification(notificationId: number): Promise<void> {
    try {
      await NotificationApiService.readNotification(notificationId);
    } catch (error) {
      console.error(error);
    }
  }

  public async openNotificationsDrawer(): Promise<void> {
    this.isVisibleNotificationsDrawer.value = true;
    await this.loadNotifications();
  }

  public async closeNotificationsDrawer(): Promise<void> {
    this.isVisibleNotificationsDrawer.value = false;
    this.page.value = 0;
    this.isLast = false;
    await this.readNotifications();
    this.clearNotifications();
    this.forceRefresh();
  }

  public forceRefresh(): void {
    this.stopRefreshNotificationsCount();
    this.startRefreshNotificationsCount();
  }

  public async readNotifications(): Promise<void> {
    try {
      await NotificationApiService.readNotification();
    } catch (error) {
      console.error(error);
    }
  }

  public clearNotifications(): void {
    this.notifications.value = [];
  }

  private async refresh(): Promise<void> {
    if (process.server) {
      return;
    }

    if (!AuthManagerService.authStatus.isLoggedIn) {
      return this.stopRefreshNotificationsCount();
    }

    if (this.isVisibleNotificationsDrawer.value) {
      await this.loadNewNotifications();
    }

    await this.loadNotificationsCount();
    if (this._interval) {
      return;
    }

    this._interval = setTimeout(() => {
      this.refresh();
      this._interval = null;
    }, this._timeout);
  }

  private initComputedShortedCount(): void {
    this.shortedUnreadCount = computed<string>(() => {
      if (!this.unreadCount.value) {
        return '';
      }

      return this.unreadCount.value > 99 ? '99+' : this.unreadCount.value?.toString();
    });
  }

  private filterNotExistsNotifications(newNotifications: Array<INotification>): Array<INotification> {
    return newNotifications
      .filter((newNotification) => !this.notifications.value
      .find((notification) => newNotification.id === notification.id));
  }
}
