import { defineStore } from 'pinia';
import { IUserInfoResponse } from 'models/auth/userInfo.model';
import { IUserState } from 'models/userStore.model';
import { IDeliveryBase } from 'shared/models/bases.model';
import { EUserGroup } from 'shared/enums/user/userGroup.enum';
import { IStapiDemandedNeedsResponseData } from 'models/strapi/strapiNews.model';

export const useUserStore = defineStore('user', {
  state: (): IUserState => ({
    userInfo: null,
    supplierStatus: null,
    clientId: null,
    userBases: null,
    selectedBase: null,
    allowMpReport: false,
    token: null,
    offerStatus: null,
    isShownPopupAfterLogin: null,
    mpAdminInfoEmail: null,
    baseToSelect: null,
    sessionId: null,
    sessionTransactionId: null,
    demandedNeeds: null,
  }),
  getters: {
    isUserLoggedIn(state): boolean {
      return !!state.userInfo || this.isOciAuth;
    },
    fullName: (state) => state.userInfo?.name,
    userId: (state) => state.userInfo?.sub,
    clientId: (state) => state.userInfo?.clientId,
    roles: (state) => state.userInfo?.roles,
    beId: (state) => state.userInfo?.beId,
    groups: (state) => state.userInfo?.groups,
    getSelectedBase: (state) => state.selectedBase,
    getAllowMpReport: (state) => state.allowMpReport,
    getSelectedBaseName: (state) => state.selectedBase?.name,
    getUserBases: (state) => state.userBases,
    getSupplierConfirmedOfferDate: (state) => state.userInfo?.supplierInfo?.confirmedOffer && state.userInfo.supplierInfo?.confirmedOfferDate,
    getSupplierCargoSpaceEnabled: (state) => state.userInfo?.supplierInfo?.supplierCargoSpace,
    isOciAuth: (state): boolean => !!(state.sessionId || state.sessionTransactionId),
    getIsIntegration: (state) => state.userInfo?.integration ?? false,
    getDemandedNeeds: (state) => state.demandedNeeds?.data?.[0]?.attributes,
    getIsClientNotAccepted: (state) => !state.userInfo.integration ? !state.userInfo?.isAccepted : false,
    isSupplierAdmin: (state) => !!state.userInfo?.supplierInfo?.isAdmin,
    beLimitsUploadType: (state) => state.userInfo?.beLimitsUploadType,
    beOptions: (state) => state.userInfo?.beOptions,
    supplierId: (state) => state.userInfo?.supplierInfo?.id,
  },
  actions: {
    setUserInfo(userInfo?: IUserInfoResponse) {
      this.userInfo = userInfo;
      const supplierInfo = userInfo?.supplierInfo;
      this.setSupplierStatus(supplierInfo?.kycStatus);
      this.setUserBases(userInfo.bases);
      this.setSelectedBase(userInfo.baseToSelect);
      this.setBaseToSelect(userInfo?.baseToSelect);
      this.setAllowMpReport(userInfo?.allowMpReport);
    },
    setDemandedNeeds(response: IStapiDemandedNeedsResponseData) {
      this.demandedNeeds = response;
    },
    setUserBases(bases: Array<IDeliveryBase>) {
      this.userBases = bases;
    },
    setAllowMpReport(allow?: boolean) {
      this.allowMpReport = allow ?? false;
    },
    setSupplierStatus(status: string) {
      this.supplierStatus = status;
    },
    setOfferStatus(confirmed: boolean, date: string | null) {
      this.offerStatus = { confirmed, date };
    },
    setShownPopupAfterLogin(isNeededToShow: boolean) {
      this.isShownPopupAfterLogin = isNeededToShow;
    },
    setSelectedBase(base) {
      this.selectedBase = {
        id: base?.id,
        name: base?.name,
        fiasId: base?.region?.fiasId ?? base?.fiasId,
        subdivisionName: base?.subdivisionName,
        subdivisionId: base?.subdivisionId,
      };

      this.setBaseToSelect(base);
    },
    setUserLogout() {
      this.userInfo = null;
      this.supplierStatus = null;
      this.mpAdminInfoEmail = null;
    },
    setToken(token: string | null) {
      this.token = token;
    },
    setMpAdminInfoEmail(email: string | null) {
      this.mpAdminInfoEmail = email;
    },
    setBaseToSelect(base: IDeliveryBase | null) {
      this.baseToSelect = base;
    },
    isUserHasGroup(group?: EUserGroup | Array<EUserGroup>): boolean {
      if (!group) {
        return false;
      }

      const accessGroup = Array.isArray(group) ? group : [group];
      return !!(this.groups?.some?.((userGroup) => accessGroup?.includes(userGroup)));
    },
    setOciAuth(sessionId: string | null, sessionTransactionId: string | null): void {
      this.sessionId = sessionId;
      this.sessionTransactionId = sessionTransactionId;
    },
  },
});
