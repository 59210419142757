import { useUserStore } from 'store/user.store';
import { SupplierApiService } from 'services/api/supplierApi.service';
import { ESupplierSignStatuses } from 'enums/supplier/signStatuses.enum';
import { UserHelper } from 'utils/userHelper.util';

export class SupplierHelper {
  static async updateQualificationStatus(): Promise<void> {
    const userStore = useUserStore();

    if (!UserHelper.isSupplier) {
      return;
    }

    try {
      const supplierStatus = await SupplierApiService.getSupplierStatusInfo();
      userStore.setSupplierStatus(supplierStatus.supplierInfo?.kycStatus);
      userStore.setOfferStatus(supplierStatus.supplierInfo?.confirmedOffer, supplierStatus.supplierInfo?.confirmedOfferDate);
    } catch (error) {
      userStore.setSupplierStatus(ESupplierSignStatuses.NotStarted);
    }
  }

  static get isQualificationSigned(): boolean {
    return [ESupplierSignStatuses.Signed].includes(useUserStore().supplierStatus as ESupplierSignStatuses);
  }

  static get isQualificationCompleted(): boolean {
    return [ESupplierSignStatuses.Accepted].includes(useUserStore().supplierStatus as ESupplierSignStatuses);
  }

  static get isQualificationAccepted(): boolean {
    return (useUserStore().supplierStatus as ESupplierSignStatuses) === ESupplierSignStatuses.Accepted;
  }

  static get isOfferAccepted(): boolean | undefined {
    return useUserStore().offerStatus?.confirmed;
  }

  static get isShownPopupAfterLogin(): boolean {
    return useUserStore().isShownPopupAfterLogin;
  }

  static setIsShownPopupAfterLogin(isNeededToShow: boolean): void {
    useUserStore().setShownPopupAfterLogin(isNeededToShow);
  }

  static get isShowOfferPopUp(): boolean {
    return SupplierHelper.isOfferAccepted === false && SupplierHelper.isQualificationAccepted;
  }
}
