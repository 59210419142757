import CartModalManager from 'shared/services/cartModal.service';
import FilesService from 'shared/services/api/files.service';
import { BasketCounter } from 'services/basket-services/basket/basketCounter.service';
import WindowWidthManager from 'shared/services/windowWidth.service';
import { ClientCreateOrderFromXlsxService } from 'services/client/clientCreateOrderFromXlsx.service';

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.provide(CartModalManager.getServiceName(), new CartModalManager());
  vueApp.provide(FilesService.getServiceName(), new FilesService());
  vueApp.provide(BasketCounter.getServiceName(), new BasketCounter());
  vueApp.provide(WindowWidthManager.getServiceName(), new WindowWidthManager());
  vueApp.provide(ClientCreateOrderFromXlsxService.getServiceName(), new ClientCreateOrderFromXlsxService());
});
