export class NuxtProcessWrappers {
  public static runOnServer<T extends Array<unknown>>(func: (...args: T) => unknown, context: unknown, ...args: T) {
    if (process.client) {
      return;
    }

    return func.bind(context)(...args);
  }

  public static runOnClient<T extends Array<unknown>>(func: (...args: T) => unknown, context: unknown, ...args: T) {
    if (process.server) {
      return;
    }

    return func.bind(context)(...args);
  }
}

