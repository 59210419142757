export enum ELayout {
  Marketplace = 'marketplace',
  MarketplaceNoSearchAndBottomMenu = 'marketplace-no-search-and-bottom-menu',
  UiKit = 'ui-kit',
  Supplier = 'supplier',
  Default = 'default',
  DefaultNew = 'defaultNew',
  Seller = 'seller',
  Client = 'client',
  Error = 'error',
  ERP = 'erp-layout',
  NoSearch = 'no-search',
  TransparentHead = 'transparent-head',
  Clear = 'clear',
  Subscription = 'subscription'
}
