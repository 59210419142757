import { AuthHelper } from 'utils/authHelper.util';

export default defineNuxtRouteMiddleware(async (to) => {
  const path = to.path?.replace(/\/+/g,'/');
  /**
   * Иногда nuxt прокидывает сюда путь к файлам.
   * Чтобы они не редиректились добавлен этот обработчик.
   **/
  if (process.server) {
    console.warn('route.global.ts: ', path);
  }

  if (process.client && Boolean(useRuntimeConfig()?.public?.routeLoggerEnabled)) {
    await AuthHelper.fetch('/api/v1/route_logger', {
      method: 'POST',
      body: { path },
    });
  }

  if (path.match(/(.+)\.(jpg|jpeg|gif|png|ico|svg|css|js|mjs|txt)/) || path?.startsWith('/api')) {
    return abortNavigation();
  }

  if (!path.endsWith('/')) {
    return await navigateTo({
      path: `${path}/`,
      query: to.query,
      hash: to.hash,
    }, {
      replace: true,
      redirectCode: 301,
    });
  }
});
