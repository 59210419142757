export enum EErrorType {
  SingleAdministratorError = 'SINGLE_ADMINISTRATOR_ERROR',
  EmailConflictError = 'EMAIL_CONFLICT_ERROR',
  NotDirectCatalogConflictError = 'NOT_DIRECT_CATALOG_CONFLICT_ERROR',
  BasisSettingsConflictError = 'BASIS_SETTINGS_CONFLICT_ERROR',
  InvalidOperationForDistributingCart = 'InvalidOperationForDistributingCart',
  SupplierOrderBossApproved = 'SUPPLIER_ORDER_BOSS_APPROVED',
  SupplierOrderBossDeclined = 'SUPPLIER_ORDER_BOSS_DECLINED',
  UserApprovementNotNeeded = 'USER_APPROVEMENT_NOT_NEEDED',
  NameTemplateError = 'NAME_TEMPLATE_ERROR',
}
