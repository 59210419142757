import { NuxtProcessWrappers } from './nuxtProcessWrappers.util';
export class DocumentBreakpointsUtils {
  static readonly mobileBreakpoint = 360;
  static readonly tabletBreakpoint = 768;
  static readonly desktopBreakpoint = 1279;
  static readonly largeDesktopBreakpoint = 1599;

  static getIsRenderedMobile(): boolean {
    return NuxtProcessWrappers.runOnClient(this.getIsMobile, this);
  }

  static getIsRenderedInValue(value: number) {
    return NuxtProcessWrappers.runOnClient(this.getIsInValue, this, value);
  }

  static getIsRenderedTablet(): boolean {
    return NuxtProcessWrappers.runOnClient(this.getIsTablet, this);
  }

  static getIsRenderedDesktop(): boolean {
    return NuxtProcessWrappers.runOnClient(this.getIsDesktop, this);
  }

  static getIsRenderLargeDesktop(): boolean {
    return NuxtProcessWrappers.runOnClient(this.getIsLargeDesktop, this);
  }

  static getIsRenderedSmallMobile(): boolean {
    return NuxtProcessWrappers.runOnClient(this.getIsSmallMobile, this);
  }

  // document is not defined на сервер-сайде, поэтому статические методы-геттеры декорируются этой функцией

  private static getIsInValue(value: number): boolean {
    return window.innerWidth < value;
  }

  private static getIsMobile(): boolean {
    return window.innerWidth >= this.mobileBreakpoint && window.innerWidth < this.tabletBreakpoint;
  }

  private static getIsTablet(): boolean {
    return window.innerWidth >= this.tabletBreakpoint && window.innerWidth < this.desktopBreakpoint;
  }

  private static getIsDesktop(): boolean {
    return window.innerWidth >= this.desktopBreakpoint && window.innerWidth < this.largeDesktopBreakpoint;
  }

  private static getIsLargeDesktop(): boolean {
    return window.innerWidth >= this.largeDesktopBreakpoint;
  }

  private static getIsSmallMobile(): boolean {
    return window.innerWidth <= this.mobileBreakpoint;
  }
}
