export enum ESignEsdErrorCode {
  SetHashToHashContainer,
  DuringSignatureVerification,
  DuringCalculatingHash,
  DuringCreateDetachedSignature,
  DuringCreateAttachedSignature,
  FailedAccessCertificateContainer,
  NoCertificatesAvailable,
  ThumbpringCertificateNotFound,
  GetCertificateList,
  PluginInitialization,
  PluginNotInstalled,
  InitPlugin,
}
