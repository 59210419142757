import { IExpiresAuthResponse } from 'models/auth/auth.model';
import { IUserInfoResponse } from 'models/auth/userInfo.model';
import { BasketCounter } from 'services/basket-services/basket/basketCounter.service';
import { BackNotificationService } from 'services/notification.service';
import { IAuthStatus } from 'shared/models/authCookieStatus.model';
import { UserHelper } from 'utils/userHelper.util';
import { useUserStore } from 'store/user.store';
import { SupplierHelper } from 'utils/supplierHelper.util';
import { CookieManager } from 'shared/utils/cookieManager.util';
import jwt_decode from 'jwt-decode';
import { AuthHelper } from 'utils/authHelper.util';
import { GOD_MODE_USER_TOKEN_NAME } from 'shared/constants/auth/godMode.const';
import { appendResponseHeader } from 'h3';
import { ParseSetCookie } from 'shared/server/utils/parseSetCookie.util';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import BasesService from 'services/basesManager.service';

export class AuthManagerService {
  private static _isAuthProcessActive = ref(true);
  static readonly remainderMilliSec = 20000; // запас в миллисекундах перед истечением времени жизни токена

  static get authStatus(): IAuthStatus {
    const userStore = useUserStore();
    return {
      isLoggedIn: !!userStore?.isUserLoggedIn && !userStore?.isOciAuth,
    };
  }

  public static get isAuthProcessActive(): boolean {
    return this._isAuthProcessActive.value;
  }

  public static onAuthProcessCancel(): void {
    this._isAuthProcessActive.value = false;
  }

  static async login(
    email: string,
    password: string,
    successCallbackFn?: () => void,
    errorCallbackFn?: (error) => void,
    isNeedCheckSupplierRedirect = true,
  ) {
    await this.startLogin(
      () => AuthHelper.fetch<IExpiresAuthResponse>('/api/v1/auth/login', { method: 'POST', body: { email, password } }),
      successCallbackFn,
      errorCallbackFn,
      isNeedCheckSupplierRedirect,
    );
  }

  static async startLogin(
    loginCallbackFn: () => Promise<IExpiresAuthResponse>,
    successCallbackFn?: () => void,
    errorCallbackFn?: (error) => void,
    isNeedCheckSupplierRedirect = true,
  ): Promise<void> {
    if (!loginCallbackFn) {
      return;
    }
    try {
      await loginCallbackFn();
      await this.setUserInfo();
      await this.loadBasketCount();
      await this.startNotifications();
      await this.setSentryUser();
      this.startCheckBasis();
      successCallbackFn && successCallbackFn(); // before available redirect
      isNeedCheckSupplierRedirect && (await this.checkSupplierRedirect());
    } catch (error) {
      errorCallbackFn && errorCallbackFn(error);
      throw new Error(error);
    }
  }

  /**
   * Метод работает только на стороне сервера (SSR). Вызывать его на самом верхнем
   * уровне компонента, иначе функции useRequestEvent и useRequestHeaders не будут работать. Это
   * особенность nuxt.
   */
  static async getServerUserInfo(): Promise<IUserInfoResponse | undefined> {
    if (!process.server) {
      return;
    }

    try {
      const cookieName = 'cookie';
      const setCookieName = 'set-cookie';
      const event = useRequestEvent();
      const headers = event.node.res.getHeader(setCookieName);
      const setCookie = Array.isArray(headers) ? headers?.join?.(';') : '' || '';
      const cookie = useRequestHeaders([cookieName]);
      const accessTokenName = useRuntimeConfig()?.public.accessTokenName;
      const refreshTokenName = useRuntimeConfig()?.public.refreshTokenName;

      if (cookie[cookieName] && !!setCookie) {
        cookie[cookieName] = setCookie && cookie[cookieName] || '';
      }

      if (
        !cookie
        || (
          !cookie?.[cookieName]?.includes?.(accessTokenName)
          && !cookie?.[cookieName]?.includes?.(refreshTokenName)
        )) {
        return;
      }

      const response = await $fetch.raw('/api/v1/auth/user_info', {
        headers: cookie,
        method: 'GET',
      });

      const accessToken = CookieManager.getCookieValueByName(response.headers.get(setCookieName), accessTokenName);
      const godModeToken = CookieManager.getCookieValueByName(cookie.cookie, GOD_MODE_USER_TOKEN_NAME);

      event.node.res.removeHeader(setCookieName);
      ParseSetCookie.parseCookie(response?.headers?.get(setCookieName))
        .forEach((cookieValue) => appendResponseHeader(event, setCookieName, cookieValue));

      const userAuthInfo = response._data;

      if (godModeToken && accessToken) {
        const decodedAccessToken = jwt_decode<IUserInfoResponse>(accessToken);

        useUserStore()?.setMpAdminInfoEmail(decodedAccessToken?.email);
      }

      return userAuthInfo?.userInfo;
    } catch (error) {
      console.error('Error - getServerUserInfo: ', error);
    }
  }

  static async setUserInfo(): Promise<IUserInfoResponse | undefined> {
    try {
      const { userInfo } = await AuthHelper.fetch<{ userInfo: IUserInfoResponse }>('/api/v1/auth/user_info');
      useUserStore()?.setUserInfo(userInfo);
      await AuthManagerService.setSentryUser();
      return userInfo;
    } catch (error) {
      console.error(error);
    }
    return;
  }

  static async checkAuth(): Promise<boolean> {
    try {
      return await AuthHelper.fetch<boolean>('/api/v1/auth/check', undefined, true);
    } catch {
      return false;
    }
  }

  public static async loadBasketCount(): Promise<void> {
    await new BasketCounter()?.loadCounter();
  }

  static async startNotifications(): Promise<void> {
    await BackNotificationService.getInstance().startRefreshNotificationsCount();
  }

  static async initSupplierQualification(): Promise<void> {
    await SupplierHelper.updateQualificationStatus();
  }

  static async checkSupplierRedirect(successPath = 'orders'): Promise<void> {
    if (UserHelper.isSupplier) {
      try {
        useUserStore().setShownPopupAfterLogin(true);
        await navigateTo(`/supplier/${SupplierHelper.isQualificationCompleted ? successPath : 'qualifications/intro'}`);
      } catch (err) {
        console.error(err);
      }
    }
  }

  static redirectToAppWithAuth(needAuth?: boolean) {
    const { appHost } = useRuntimeConfig().public;

    const url = new URL('', location.protocol + '//' + appHost);

    if (needAuth) {
      url.searchParams.append('auth', true);
    }

    window.location.replace(url.toString());
  }

  static async logout(needAuth?: boolean) {
    try {
      if (useUserStore().mpAdminInfoEmail) {
        await AuthHelper.fetch('/api/auth/deleteCookie', { method: 'DELETE' });
      } else {
        await AuthHelper.fetch('/api/v1/auth/logout', { method: 'POST' });
      }

      clientSentry.setUser(null);
      new BasketCounter().clear();
      this.stopCheckBasis();

      this.redirectToAppWithAuth(needAuth);
    } catch (error) {
      console.error(error);
    }
  }

  public static async setSentryUser(): Promise<void> {
    await nextTick();
    const userStore = useUserStore();
    clientSentry.setUser({
      id: userStore.userId,
      supplierId: userStore.supplierId,
      clientId: userStore.clientId,
      email: userStore.userInfo?.email,
      beId: userStore.userInfo?.beId,
    });
  }

  public static startCheckBasis(): void {
    BasesService.getInstance().startCheckUserBasis();
  }

  public static stopCheckBasis(): void {
    BasesService.getInstance().clearCheckUserBasis();
  }
}
