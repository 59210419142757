<template>
  <div class="error-wrapper">
    <div class="error-container">
      <slot />
      <div class="content">
        <div>
          <div
            v-if="title"
            class="title"
            v-html="sanitize(title)"
          />
          <div
            v-if="message"
            class="message"
            v-html="sanitize(message)"
          />
          <div class="buttonWrapper">
            <button
              v-if="redirectButton"
              class="btn btn-text"
              @click="redirect(redirectButton?.path ?? '/')"
            >
              <span>
                {{ redirectButton.text }}
              </span>
              <SvgIcon
                src="navigation/main-arrow"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface _VTI_TYPE_IErrorRedirectButton {
  text: string;
  path: string;
}
interface IErrorDetails {
  title?: string;
  image?: string;
  message?: string;
  redirectButton?: _VTI_TYPE_IErrorRedirectButton;
}
import { sanitize } from '../directives/sanitize'
defineProps<IErrorDetails>();
function redirect(path: string) {
  clearError({ redirect: path });
}
</script>

<style lang="scss" scoped>
  @import 'styles/base/common/variables';

  .error-wrapper {
    display: flex;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }

  .error-container {
    display: flex;
    gap: 127px;
    align-content: center;
    padding: 24px 0 35px;

    .content {
      display: flex;
      align-items: center;

      .title {
        font-size: 32px;
        line-height: 36px;
        font-weight: 500;
        color: $text-black;
      }

      .message {
        margin-top: 20px;
        font-weight: 400;
        font-size: 14px;
        color: $text-black;
      }

      .buttonWrapper {
        margin-top: 40px;
        display: inline-block;
        font-weight: 500;
        font-size: 14px;

        button {
          outline-color: $link;
          display: flex;
          align-items: center;
          border: none;
          background: none;
          color: $link;
        }
      }

      .btn-text {
        padding: 0;
        font-weight: 500;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .error-wrapper{
      margin-left: 115px;
    }

    .error-container {
      gap: 96px;
    }
  }
  @media screen and (max-width: 1279px){
    .error-wrapper{
      margin-left: 0;
      padding-right: 20px;
    }

    .error-container {
      gap: 48px;

      .content {
        .title {
         font-size: 24px;
       }
      }
    }
  }
  @media screen and (min-width: 360px) and (max-width: 1279px){
    .error-wrapper{
      margin-left: 0;
      padding-left: 20px;
    }

    .error-container {
      gap: 48px;
    }
  }
  @media screen and (max-width: 620px){
    .error-wrapper{
      margin-left: 0;
    }

    .error-container {
      gap: 48px;
      width: 100%;
      flex-direction: column;
      justify-items: center;
      align-items: center;
    }
  }
</style>
