import { ProviderService } from 'shared/models/providerService.model';
import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { NamingHelper } from 'shared/utils/namingHelper.utils';

export default class FilesService extends ProviderService {
  static readonly serviceName = 'filesService';

  _currentUploadingFile = ref();

  public set currentUploadingFile(value: string) {
    this._currentUploadingFile.value = value;
  }

  constructor() {
    super();
  }

  static async fileDownload<AxiosResponse>(
    fileId: string,
    fetchOptions: {
      method?: 'get' | 'put';
      headers?: Record<string, unknown>;
      body?: Record<string, unknown> | FormData;
      responseType?: string;
    },
    customUrl?: string,
  ): Promise<AxiosResponse> {
    return await axios.get(customUrl ?? `/api/v1/files/download/${fileId}/`, {
      responseType: 'blob',
      headers: (fetchOptions.headers || {}) as AxiosRequestHeaders,
    });
  }

  static async fileFetch<T>(
    supplierId: number,
    fileType: string,
    fetchOptions: {
      method?: 'get' | 'put';
      headers?: Record<string, unknown>;
      body?: Record<string, unknown> | FormData;
      responseType?: string;
    },
  ): Promise<T> {
    return (await axios.put(`/api/v1/files/upload/supplier/${supplierId}/${fileType}/`, fetchOptions.body, {
      responseType: 'json',
      transformResponse: [function(data) {
        return NamingHelper.responseFileChecked(data);
      }],
      headers: {
        'ContentType': 'Multipart/form-data',
        ...(fetchOptions.headers || {}),
      },
    })).data;
  }

  async downloadFile(id: string): Promise<AxiosResponse> {
    return await FilesService.fileDownload(id, { method: 'get' });
  }

  async fileUpload(file) {
    const formData = new FormData();
    formData.append('file', file);
    // TODO: Убрать хардкод id поставщика после подключения апи поставщика 19.07.2022
    return await FilesService.fileFetch(1, this._currentUploadingFile.value, { method: 'put', body: formData });
  }
}
