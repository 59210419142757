import { useUserStore } from 'store/user.store';
import { CLIENT_GROUPS, SUPPLIER_GROUPS } from 'shared/constants/user/groups.const';
import { EClientId } from 'enums/client/clientIdName.enum';

export class UserHelper {
  public static get isClient(): boolean {
    return useUserStore()?.groups?.some((group) => CLIENT_GROUPS.has(group)) ?? false;
  }

  public static get isSupplier(): boolean {
    return useUserStore()?.groups?.some((group) => SUPPLIER_GROUPS.has(group)) ?? false;
  }

  public static get isPolus(): boolean {
    return this.isClient && useUserStore()?.clientId === EClientId.Polus;
  }

  public static get isAIM(): boolean {
    return this.isClient && useUserStore()?.clientId === EClientId.AIM;
  }

  public static get isNLMK(): boolean {
    return this.isClient && useUserStore()?.clientId === EClientId.NLMK;
  }

  public static get isIntegration(): boolean {
    return useUserStore()?.userInfo?.integration ?? false;
  }
}
