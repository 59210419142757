import { App } from 'vue';
import { sanitizeUrl } from '@braintree/sanitize-url';

const VSanitizeURL = {
  install(Vue: App<Element>, options: Record<string, string> = {}) {
    const { name = 'href-sanitize' } = options;

    Vue.directive(name,
      {
        mounted(el, binding) {
          if (binding.value === binding.oldValue) {
            return;
          }

          el.href = sanitizeUrl(binding.value);

          console.error('Не использовать директиву v-href-sanitize. Вместо этого используем' +
            ' href="${sanitizeUrl(businessEntity.website)}"');
        },
        updated(el, binding) {
          if (binding.value === binding.oldValue) {
            return;
          }

          el.href = sanitizeUrl(binding.value);
        },
        getSSRProps() {
          return {};
        },
      },
    );
  },
};

export default VSanitizeURL;
