import { ProviderService } from 'shared/models/providerService.model';
import ModalManager from 'shared/services/modalManager.service';
import { useUserStore } from 'store/user.store';
import FilesService from 'shared/services/api/files.service';
import Loader from 'shared/utils/loaderHelper.util';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import Notificator from 'shared/services/notificator.service';
import { downloadFileByBlob } from 'shared/utils/fileSaver.util';
import ClientCreateOrderXlsxModal from 'components/modals/client/create-order-xlsx/ClientCreateOrderXlsxModal.vue';
import ClientCreateOrderXlsxErrorModal from 'components/modals/client/create-order-xlsx/ClientCreateOrderXlsxErrorModal.vue';
import { ReportApiService } from '../api/reportApi.service';
import {
  IClientCreatedOrderFromXlxsResponse,
  IClientCreateOrderFromXlxsParams,
  IClientParseXlsxError,
  IClientParseXlsxResponse,
} from 'models/client/clientCreateOrderXlsx.model';
import { EQueryParam } from 'shared/enums/queryParam.enum';

export class ClientCreateOrderFromXlsxService extends ProviderService {
  protected static readonly serviceName = 'clientCreateOrderFromXlsxService';

  public downloadingFile = Loader.getReactiveInstance();
  public loadingCreateOrder = Loader.getReactiveInstance();

  public clientCanLoadCartFromExcel = computed(
    () => this.userStore.beOptions?.canLoadCartFromExcel,
  );

  constructor(
    private readonly userStore = useUserStore(),
    private readonly modalManager = inject<ModalManager>(ModalManager.getServiceName()),
    private readonly filesService = inject<FilesService>(FilesService.getServiceName()),
  ) {
    super();
  }

  public async createOrderDraft(
    file: File,
    params: IClientCreateOrderFromXlxsParams,
  ): Promise<void> {
    if (this.loadingCreateOrder.value
      || !this.userStore.clientId
    ) {
      return;
    }

    this.loadingCreateOrder.activate();

    this.goToDraftPage();

    const formData = new FormData();
    formData.append('file', file, file.name);

    try {
      await this.handleCreateResponse(
        await ReportApiService.createOrderDraftFromXlsx(
          this.userStore.clientId,
          formData,
          params,
        ),
      );
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        ClientCreateOrderFromXlsxService.getServiceName(),
      );

      Notificator.showDetachedNotification('Ошибка при создании черновика заказа');
    } finally {
      this.loadingCreateOrder.deactivate();
    }
  }

  public openCreateOrderDraftModal(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const currentObject = this;
    const modalName = 'clientCreateOrderDraft';

    this.modalManager?.show({
      bind: {
        name: modalName,
      },
      component: ClientCreateOrderXlsxModal,
      on: {
        close: () => currentObject.modalManager?.hide(modalName),
        async create(file: File, params: IClientCreateOrderFromXlxsParams): Promise<void> {
          await currentObject.modalManager?.hide(modalName);
          await currentObject.createOrderDraft(file, params);
        },
      },
    });
  }

  public async downloadTemplate(): Promise<void> {
    if (this.downloadingFile.value) {
      return;
    }

    this.downloadingFile.activate();

    try {
      const response = await this.filesService?.downloadOrderDraftTemplateFile();

      if (!response?.data) {
        throw Error('Файл не найден в ответе');
      }

      downloadFileByBlob(response.data, FilesService.getFileNameFromResponse(response));
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        ClientCreateOrderFromXlsxService.getServiceName(),
      );

      Notificator.showDetachedNotification('Ошибка при скачивании шаблона');
    } finally {
      this.downloadingFile.deactivate();
    }
  }

  public async downdloadErrorFile(parseError: IClientParseXlsxError): Promise<void> {
    if (this.downloadingFile.value
      || !parseError.fileId
    ) {
      return;
    }

    this.downloadingFile.activate();

    try {
      const response = await this.filesService?.downloadFile(
        parseError.fileId,
      );

      if (!response?.data) {
        throw Error('Файл не найден в ответе');
      }

      downloadFileByBlob(response.data, FilesService.getFileNameFromResponse(response));
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        ClientCreateOrderFromXlsxService.getServiceName(),
        undefined,
        {
          extra: {
            parseError,
          },
        },
      );

      Notificator.showDetachedNotification('Ошибка при скачивании файла');
    } finally {
      this.downloadingFile.deactivate();
    }
  }

  private handleCreateResponse(
    response: IClientParseXlsxResponse | IClientCreatedOrderFromXlxsResponse,
  ): void {
    if (response.error) {
      return this.displayErrorModal(response.error);
    }

    navigateTo({
      query: {
        [EQueryParam.OrderNumber]: response.mpOrderNumber,
      },
      replace: true,
    });
  }

  private displayErrorModal(error: IClientParseXlsxError): void {
    const modalManager = this.modalManager;
    const modalName = 'clientCreateOrderXlsxErrorModal';

    modalManager?.show({
      component: ClientCreateOrderXlsxErrorModal,
      bind: {
        name: modalName,
        error,
      },
      on: {
        close(disableRedirect: boolean) {
          modalManager.hide(modalName);
          !disableRedirect && navigateTo('/client/orders/');
        },
      },
    });
  }

  private goToDraftPage(): void {
    navigateTo('/client/orders/draft/');
  }
}
