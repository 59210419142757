import { reactive } from 'vue';
import { BaseReactiveHelper } from './baseReactiveHelper.util';

export default class Disabler extends BaseReactiveHelper {
  public constructor(value = false) {
    super(value);
  }

  static getReactiveInstance(value = false): Disabler {
    return reactive<Disabler>(new Disabler(value));
  }
}
