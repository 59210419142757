import { ComputedRef } from 'vue';
import { EPopupManagerLoadingStatuses } from '../enums/popupLoadingStatuses.enum';
import { ProviderService } from '../models/providerService.model';
import { readBodyClassModifier } from '../composables/readBodyClassModifier';
import { POPUP_MANAGER_STATE_DEFAULTS } from '../constants/popupManager.const';
import { IPopupManagerErrorObject, IPopupManager } from '../models/popupManager.model';

export default class PopupManager extends ProviderService implements IPopupManager {
  static readonly serviceName = 'popupManager';

  private openedPopupId = ref(POPUP_MANAGER_STATE_DEFAULTS.openedPopupId);
  private popupContentLoadingStatus = ref(POPUP_MANAGER_STATE_DEFAULTS.popupContentLoadingStatus);
  private errorMessage = ref(POPUP_MANAGER_STATE_DEFAULTS.errorMessage);

  constructor() {
    super();
    this.applyBodyClassModifier();
  }

  getIsPopupOpened(): ComputedRef<boolean> {
    return computed(() => {
      return this.openedPopupId.value && this.isLoadingCompleted;
    });
  }

  getIsPopupLoading(popupId: string, isAsync: boolean): boolean {
    const isPopupIdsEqual = this.getIsPopupsEqual(popupId);

    return isAsync ? isPopupIdsEqual && this.isLoadingStarted : isPopupIdsEqual;
  }

  getIsPopupOpenedById(popupId: string, isAsync: boolean): boolean {
    const isPopupIdsEqual = this.getIsPopupsEqual(popupId);

    return isAsync ? isPopupIdsEqual && this.isLoadingCompleted : isPopupIdsEqual;
  }

  getIsPopupErrored(popupId: string): IPopupManagerErrorObject {
    return this.getIsPopupsEqual(popupId)
      ? ({
          isErrored: this.isLoadingErrored,
          errorMessage: this.errorMessage.value,
        } as IPopupManagerErrorObject)
      : {};
  }

  openPopup(popupId: string, isAsync: boolean): void {
    this.openedPopupId.value = popupId;
    if (isAsync) {
      this.popupContentLoadingStatus.value = EPopupManagerLoadingStatuses.Loading;
    }
  }

  updateLoadingStatus(error?: string): void {
    if (!error) {
      this.popupContentLoadingStatus.value = EPopupManagerLoadingStatuses.Completed;
      return;
    }

    this.popupContentLoadingStatus.value = EPopupManagerLoadingStatuses.Error;
    this.errorMessage.value = error;
  }

  closePopup(): void {
    this.openedPopupId.value = POPUP_MANAGER_STATE_DEFAULTS.openedPopupId;
    this.popupContentLoadingStatus.value = POPUP_MANAGER_STATE_DEFAULTS.popupContentLoadingStatus;
    this.errorMessage.value = POPUP_MANAGER_STATE_DEFAULTS.errorMessage;
  }

  private applyBodyClassModifier(): void {
    readBodyClassModifier(this.getIsPopupOpened(), 'popup--opened');
  }

  private getIsPopupsEqual(popupId: string): boolean {
    return this.openedPopupId.value === popupId;
  }

  private get isLoadingStarted(): boolean {
    return this.popupContentLoadingStatus.value === EPopupManagerLoadingStatuses.Loading;
  }

  private get isLoadingErrored(): boolean {
    return this.popupContentLoadingStatus.value === EPopupManagerLoadingStatuses.Error;
  }

  private get isLoadingCompleted(): boolean {
    return this.popupContentLoadingStatus.value === EPopupManagerLoadingStatuses.Completed;
  }
}
