<template>
  <BaseModal
    v-slot="{ params, close }"
    v-bind="$props"
    :content-class="['modal-content', contentClass]"
    :classes="`modal ${classes || ''}`"
    :content-style="{ width }"
    @update:model-value="$emit('update:modelValue', $event)"
    @click-outside="$emit('clickOutside')"
    @before-open="$emit('beforeOpen')"
    @opened="$emit('opened')"
    @before-close="$emit('beforeClose')"
    @closed="$emit('closed')"
  >
    <div
      v-if="!hideHeader"
      class="modal-header"
      :class="{ [headerClass as string]: headerClass }"
    >
      <slot
        name="header"
        :close="close"
      >
        <slot name="title">
          <h3
            v-if="!hideTitle"
            class="modal-header__title"
            :class="{
              [titleClass as string]: titleClass,
            }"
            v-html="sanitize(titleText ?? '')"
          />
        </slot>
        <button
          v-if="!hideCloseButton"
          :id="closeModalId"
          class="btn btn-secondary"
          :disabled="disabledCloseButton"
          :class="{ [closeButtonClass as string]: closeButtonClass }"
          @click="$emit('close', close)"
        >
          {{ closeButtonText }}
          <SvgIcon
            class="close-icon"
            :src="isMobile ? 'navigation/close-36px' : 'navigation/close-20px'"
          />
        </button>
      </slot>
    </div>
    <div
      class="modal-inner-content"
      :class="{ [contentTextClass as string]: contentTextClass }"
    >
      <slot :params="params">
        <div
          class="modal-inner-content__text"
          v-html="sanitize(contentText ?? '', 'nothing')"
        />
      </slot>
    </div>
    <div
      v-if="!hideFooter"
      class="modal-footer"
      :class="{
        [footerButtonsDirection]: footerButtonsDirection && !hideCancelButton && !hideOkButton,
        [footerClass as string]: footerClass,
      }"
    >
      <slot name="footer">
        <slot
          v-if="!hideOkButton"
          name="ok-button"
        >
          <button
            :id="okBtnId"
            :disabled="disabledOkButton"
            class="btn btn-primary"
            @click="$emit('ok', close)"
          >
            <slot name="okButtonContent">
              {{ okButtonText }}
            </slot>
          </button>
        </slot>
      </slot>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
interface _VTI_TYPE_CSSProperties {
    /**
     * The index signature was removed to enable closed typing for style
     * using CSSType. You're able to use type assertion or module augmentation
     * to add properties or an index signature of your own.
     *
     * For examples and more information, visit:
     * https://github.com/frenic/csstype#what-should-i-do-when-i-get-type-errors
     */
    [v: `--${string}`]: string | number | undefined;
}
type _VTI_TYPE_StyleValue = false | null | undefined | string | _VTI_TYPE_CSSProperties | _VTI_TYPE_Array
interface IModalProps {
  contentClass?: string | object | _VTI_TYPE_Array;
  overlayClass?: string | object | _VTI_TYPE_Array;
  classes?: string | object | _VTI_TYPE_Array;
  styles?: _VTI_TYPE_StyleValue;
  overlayStyle?: _VTI_TYPE_StyleValue;
  contentStyle?: _VTI_TYPE_StyleValue;
  modelValue?: boolean;
  lockScroll?: boolean;
  hideOverlay?: boolean;
  clickToClose?: boolean;
  escToClose?: boolean;
  preventClick?: boolean;
  attach?: boolean;
  transition?: string;
  overlayTransition?: string;
  zIndexAuto?: boolean;
  zIndexBase?: number | string;
  zIndex?: string | number;
  focusRetain?: boolean;
  focusTrap?: boolean;
  ssr?: boolean;
  name?: string;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  keepOverlay?: boolean;
  closeModalId?: string;
  okBtnId?: string;
  cancelBtnId?: string;
  footerButtonsDirection?: 'row' | 'column' | 'column-reverse'; // расположение кнопок в футере
  okButtonText?: string; // Текст кнопки принятия
  cancelButtonText?: string; // Текст кнопки отмены
  cancelButtonIcon?: string; // Иконка кнопки отмены
  closeButtonText?: string; // Текс кнопки закрытия
  hideOkButton?: boolean; // Флаг скрытия кнопки принятия
  hideCancelButton?: boolean; // Флаг скрытия кнопки отмены
  hideTitle?: boolean; // Флаг скрытия заголовка
  titleText?: string; // Текст заголовка
  hideCloseButton?: boolean; // Скрытие кнопки закрытия в хидере
  hideFooter?: boolean; // Флаг скрытия футера
  hideHeader?: boolean; // Флаг скрытия хидера
  disabledCancelButton?: boolean; // Флаг дизейбла кнопки отмена
  disabledOkButton?: boolean; // Флаг дизейбла кнопки принятия
  disabledCloseButton?: boolean; // Флаг дизейбла кнопки закрытия
  width?: string; // Ширина модального окна
  headerClass?: string; // Класс стилей хидера
  closeButtonClass?: string; // Класс стилей кнопки закрытия
  modalClass?: string; // Доп.класс для обертки модального окна
  footerClass?: string; // Класс стилей футера
  contentTextClass?: string; // Класс стилей текста контента
  contentText?: string; // Текст модалки
  titleClass?: string; // Класс стилей заголовка
  contentClass?: string; // Класс контента модалки
  hideInnerContent?: boolean; // Скрыть блок контента
  disableCloseOnChangeRoute?: boolean // Заблокировать закрытие модалки при смене роута
  okButtonStyle?: _VTI_TYPE_StyleValue; // Стили для кнопки подтверждения
  cancelButtonStyle?: _VTI_TYPE_StyleValue; // Стили для кнопки отмены
  innerContentStyle?: _VTI_TYPE_StyleValue; // Стили для контейнера контента
  innerContentTextStyle?: _VTI_TYPE_StyleValue; // Стили для текста контента
}
import { DocumentBreakpointsUtils } from '../../utils/documentBreakpoints.util'
import BaseModal from './BaseModal.vue'
import { sanitize } from '../../directives/sanitize'
import { WatchSubscription } from '../../utils/watchSubscription'
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch'
const isMobile = DocumentBreakpointsUtils.getIsRenderedMobile();
const route = useRoute();
/**
 * Слоты:
 * - header
 * - title
 * - default
 * - footer
 * - cancelButtonContent
 * - okButtonContent
 */
const props = withDefaults(defineProps<IModalProps>(), {
  okButtonText: 'Подтвердить',
  cancelButtonText: 'Отменить',
  width: '582px',
  footerButtonsDirection: 'row',
  closeButtonText: 'Закрыть',
});
const emit = defineEmits<{
  (e: 'clickOutside'): void;
  (e: 'beforeOpen'): void;
  (e: 'opened'): void;
  (e: 'beforeClose'): void;
  (e: 'closed'): void;
  (e: 'update:modelValue', value: boolean): void;
  (e: 'ok', close: () => void): void;
  (e: 'cancel', close: () => void): void;
  (e: 'close', close: () => void): void;
}>();
const watchSubscription = new WatchSubscription();
watchSubscription.add(
  watch(route, () => {
    if (!props.disableCloseOnChangeRoute) {
      emit('close');
    }
  }),
);
useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

:deep(.modal) {
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    height: max-content;
    background-color: $modal-bg;
    box-shadow: -2px 2px 16px $modal-bs;
    border-radius: 12px;
    margin: 0 auto;
    padding: 40px;

    .supplier--modal__text,
    .delete--modal__text,
    .admin--modal__text,
    .decline--modal__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .modal-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 34px;
    border: none;
    padding: 0;

    .modal-header__title {
      &.admin-modal--header {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }
    }

    &.delete--modal__header,
    &.supplier--modal__header,
    &.model-view__confirm-change-category-modal-header,
    &.admin-modal__header,
    &.mm-create-models-modal__header,
    &.decline-modal__header {
      margin-bottom: 20px;
      align-items: flex-start;

      .modal-header__title {
        font-weight: 500;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: $modal-title-c;
      margin-bottom: 0;
    }

    .btn {
      margin-left: auto;
      font-weight: 500;
      padding: 8px;
      padding-left: 16px;

      &.delete--modal__close {
        padding: 8px 16px;
      }

      &.moderation-error--close,
      &.supplier--modal__close,
      &.model-view__confirm-change-category-modal-close,
      &.decline-modal__close,
      &.admin-modal__close {
        padding: 8px 8px 8px 16px;
      }

      .close-icon {
        flex-shrink: 0;

        path {
          fill: $text-dark-green !important;
        }
      }

      &:hover {
        svg {
          path {
            fill: $btn-secondary-c-h !important;
          }
        }
      }
    }
  }

  .modal-footer {
    padding: 0;
    border: none;
    display: flex;
    margin-top: 40px;
    margin-left: 0;
    margin-right: 0;

    &.moderation-error--footer {
      margin-top: 22px;
    }

    &.delete--modal__footer,
    &.decline-modal__footer,
    &.model-view__confirm-change-category-modal-footer,
    &.admin-modal__footer {
      margin-top: 32px;
    }

    &.mm-create-models-modal__footer {
      margin-top: 20px;
    }

    button {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 18px 0;
      width: 100%;
      margin: 0;
      font-weight: 500;
      transition: none;
    }

    &.row {
      flex-direction: row;

      button {
        &:first-child {
          margin-right: 8px;
        }
      }
    }

    &.column {
      flex-direction: column;

      button {
        &:first-child {
          margin-bottom: 8px;
        }
      }
    }

    &.column-reverse {
      flex-direction: column-reverse;

      button {
        &:first-child {
          margin-top: 8px;
        }
      }
    }

    &.supplier--modal__footer-declined {
      .btn-primary {
        background: $light-gray;
        color: $red;
      }
    }
  }

  .model-view__confirm-change-category-modal-text {
    font-size: 14px;
    line-height: 20px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1279px) {
    .modal-content {
      padding: 36px;
    }

    .modal-inner-content {
      overflow-y: inherit;
    }

    .modal-header {
      .btn {
        padding: 0;

        svg {
          margin-left: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .modal-content {
      width: 100%;
      padding: 20px;
      border-radius: 20px;
      border: $white;
      bottom: 0;
    }

    .modal-inner-content {
      overflow-y: auto;
      margin-right: -20px;
      padding-right: 20px;
    }

    .modal-header {
      align-items: center;

      .btn {
        font-size: 0;
        width: 36px;
        height: 36px;
        padding: 0;
        align-items: center;
        justify-content: center;

        svg {
          margin-left: 0;
        }
      }
    }

    .modal-footer {
      button {
        padding: 10px 0;
      }
    }
  }
}
</style>
