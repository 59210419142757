import { IFile, IResponseError } from '../models/files.model';
import { FileTemp } from '../services/fileTemp.service';
import { FetchError } from 'ofetch';
import { EFileStatus } from '../enums/file-uploader/file-status.enum';

export class FileHelper {
  protected static idCounter = 0;
  protected static fileUrlQueue: Map<string, number> = new Map();

  public static clearFileUrlQueue(): void {
    FileHelper.fileUrlQueue.clear();
  }

  public static getTempId(): number {
    return FileHelper.idCounter++;
  }

  public static getFileUrl(url: string): string {
    const fileNameFromUrl = url.split('/').pop();
    const [fileName, domain] = fileNameFromUrl.split('.');
    FileHelper.fileUrlQueue.set(fileName, !FileHelper.fileUrlQueue.has(fileName) ? 0 : FileHelper.fileUrlQueue.get(fileName) + 1);
    return `${fileName}_${FileHelper.fileUrlQueue.get(fileName)}.${domain}`;
  }

  public static getSourceFileByFileTemp(fileTemp: FileTemp): File | IFile {
      return fileTemp.file;
  }

  // Проверка, является ли размер файла удовлетворительным
  public static isFileSizeValid(fileSize: number, allowedSize: number): boolean {
    return fileSize / 1024 / 1024 <= allowedSize;
  }

  public static isLoadingStatus(status: EFileStatus): boolean {
    return [EFileStatus.Downloading, EFileStatus.Uploading].includes(status);
  }

  // Забирать "сырые" файлы через FileHelper.getSourceFileListByFileTempList снаружи компонента при отправке данных на бэк.
  // Нужно для того, чтобы по айдишнику (.tempId) можно было оперировать данными снаружи
  public static getSourceFileListByFileTempList(fileTempList: Array<FileTemp>, isOnlyUploaded = false): Array<File | IFile> {
      return fileTempList.reduce((acc, fileTemp) => (
        !isOnlyUploaded || (isOnlyUploaded && fileTemp.status === EFileStatus.Default) ? [...acc, fileTemp.file] : acc
      ), []);
  }

  public static getFilesFormatByAccept(accept: string): string {
    return accept?.replaceAll?.('.', '').replaceAll(',', ', ').toUpperCase() || '';
  }

  // Вынес из компонента апплоадера, мне кажется, это должно быть снаружи
  public static getUploadErrorMessage(error): string {
    const errData = ((error as FetchError<IResponseError>)?.data as IResponseError)?.detail?.[0];
    return `${errData?.loc?.[1]} ${errData?.msg}`;
  }
}
