export class Observer<T = unknown> {
  constructor(
    private readonly _id: number,
    private readonly _update: (item?: T) => void,
  ) {
  }

  public get id(): number {
    return this._id;
  }

  public get update(): (item?: T) => void {
    return this._update;
  }
}
