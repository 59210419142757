export function downloadFileByBlob(blob: Blob, name: string, type?: string): void {
  const link = document.createElement('a');
  link.setAttribute('href', window.URL.createObjectURL(blob));
  link.setAttribute('download', type ? `${name}.${type}` : name);
  link.click();
}

export function downloadFileByUrl(url: string, name = ''): void {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', name);
  link.click();
}
