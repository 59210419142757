<template>
  <div class="mm-notification-message mm-notification-accept-cookies-message">
    <div class="description">
      Мы используем файлы cookie для сбора и хранения информации об использовании этого сайта на вашем устройстве.
      Продолжая использование нашего сайта, вы даете согласие на обработку ваших персональных данных в соответствии с
      <NuxtLink
        class="mm-link"
        :to="ECommonFile.PrivacyPolicy"
        target="_blank"
      >
        Политикой обработки персональных данных
      </NuxtLink>
      посетителей сайта.
    </div>

    <div>
      <div
        v-if="isMobile || isSmallMobile"
        class="close-icon"
        @click="onClose"
      >
        <SvgIcon :src="EIconPath.NavigationClose20PxSvg" />
      </div>

      <button
        v-else
        type="button"
        class="btn btn-primary close-btn"
        @click="onClose"
      >
        Закрыть
        <SvgIcon
          class="close-icon"
          :src="EIconPath.NavigationClose20PxSvg"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SvgIcon from 'shared/components/SvgIcon.vue';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { ECookieKeys } from 'shared/enums/cookieKeys';
import { ECommonFile } from 'shared/enums/commonFile.enum';
import WindowWidthManager from 'shared/services/windowWidth.service';

const emit = defineEmits<{
  (e: 'close-toast'): void;
}>();

const { isMobile, isSmallMobile } = WindowWidthManager.getAdaptivaRefs();
const isAcceptCookie = useCookie<boolean>(ECookieKeys.AcceptCookies, { maxAge: new Date().setDate(400) });

function onClose(): void {
  isAcceptCookie.value = true;
  emit('close-toast');
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-notification-accept-cookies-message {
  max-width: 1520px;
  display: flex;
  column-gap: 32px;

  .mm-link {
    &:hover {
      color: $link;
    }
  }

  .description {
    padding: 4px 0;
  }

  .close-btn {
    padding: 8px 10px 8px 16px;
    color: $text-dark-green;
    background: $white;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .close-icon {
    :deep(path) {
      fill: $text-dark-green;
    }
  }
}

:global(.mm-notification .mm-notification-accept-cookies-message__toast) {
  align-items: flex-start !important;
  width: 1520px;
}

:global(.mm-notification:has(.mm-notification-accept-cookies-message__toast)) {
  width: 100% !important;
}

@media screen and (max-width: 1599px) {
  :global(.mm-notification .mm-notification-accept-cookies-message__toast) {
    width: 1216px;
  }
}

@media screen and (max-width: 1279px) {
  :global(.mm-notification .mm-notification-accept-cookies-message__toast) {
    width: 728px;
  }

  .mm-notification-accept-cookies-message {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-right: 16px;
    padding: 2px 0;
  }
}

@media screen and (max-width: 767px) {
  .mm-notification-accept-cookies-message {
    margin-right: 6px;
    padding: 2px 0;
    column-gap: 0;

    .close-icon {
      :deep(path) {
        fill: $notification-text;
      }
    }
  }

  :global(.mm-notification .mm-notification-accept-cookies-message__toast) {
    width: 320px !important;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
