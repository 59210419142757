export class CircularJson {
  /** 
   * Метод для преобразования объекта который ссылается сам на себя в JSON строку.
   * Для решения проблемы "Converting circular structure to JSON"
   */
  public static stringify(obj: unknown): string {
    let cache: Array<unknown> = [];
    const str = JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache?.indexOf(value) !== -1) {
          return;
        }
        cache?.push(value);
      }
      return value;
    });
    cache = [];
    return str;
  }
}