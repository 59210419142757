import SearchService from 'shared/services/search.service';
import BasesService from 'services/basesManager.service';
import { BackNotificationService } from 'services/notification.service';
import QueryParamsService from 'shared/services/queryParams.service';

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.provide(SearchService.getServiceName(), SearchService.getInstance());
  vueApp.provide(BasesService.getServiceName(), BasesService.getInstance());
  vueApp.provide(BackNotificationService.getServiceName(), BackNotificationService.getInstance());
  vueApp.provide(QueryParamsService.getServiceName(), QueryParamsService.getInstance());
});
