import { computed, ComputedRef, ref, Ref } from 'vue';
import { ICartModalItem, ICartModalManager } from '../models/cartModal.model';
import { ProviderService } from '../models/providerService.model';

export default class CartModalManager extends ProviderService implements ICartModalManager {
  static readonly serviceName = 'cartModalManager';
  private readonly clearInterval = 2000;

  private closeTimeoutId: NodeJS.Timeout;
  private isOpened = ref(false);
  private items = ref<Array<ICartModalItem>>([]);
  private currentItem = computed<ICartModalItem>(() => this.items?.value[this.items?.value?.length - 1]);
  private itemsCount = computed<number>(() => this.items?.value?.length);

  public currentAddItem: ICartModalItem;

  constructor() {
    super();
  }

  getIsModalOpened(): Ref<boolean> {
    return this.isOpened;
  }

  getCurrentItem(): ComputedRef<ICartModalItem> {
    return this.currentItem;
  }

  getCartItemsCount(): ComputedRef<number> {
    return this.itemsCount;
  }

  getItems(): Ref<Array<ICartModalItem>> {
    if (!this.items) {
      this.items = ref<Array<ICartModalItem>>([]);
      this.loadData().then();
    }

    return this.items;
  }

  addItem(item: ICartModalItem): void {
    if (!this.items.value) {
      this.items.value = [];
    }
    this.currentAddItem = item;
    this.items?.value?.push(item);
    this.open();
    this.close(true);
  }

  open(): void {
    this.isOpened.value = true;
  }

  close(withTimeout?: boolean): void {
    if (!withTimeout) {
      this.isOpened.value = false;
      return;
    }

    if (this.closeTimeoutId) {
      clearTimeout(this.closeTimeoutId);
    }

    this.closeTimeoutId = setTimeout(() => (this.isOpened.value = false), this.clearInterval);
  }

  clearCloseTimeout(): void {
    clearTimeout(this.closeTimeoutId);
  }

  async loadData(): Promise<void> {
    this.items.value = [];
  }
}
