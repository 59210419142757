import axios, { AxiosResponse } from 'axios';
import FilesService from 'shared/services/api/files.service';
import { ILimitFilterParams } from 'models/client/limitFilter.model';
import { IReport } from 'models/client/report.model';
import { IBasePagedResponse } from 'shared/models/baseApi.model';
import { FileTemp } from 'shared/services/fileTemp.service';
import { NamingHelper } from 'shared/utils/namingHelper.utils';
import { ISupplierOrdersRequestData, ISupplierOrdersRequestParams, ISupplierPriceOrdersRequestData } from 'models/supplier/order.model';
import { IReportsConfig } from 'server/models/reportsConfig.model';
import { IClientCreatedOrderFromXlxsResponse, IClientCreateOrderFromXlxsParams, IClientParseXlsxResponse } from 'models/client/clientCreateOrderXlsx.model';

export class ReportApiService {
  public static async downloadTtn(supplierOrderId: number): Promise<AxiosResponse<Blob>> {
    return await FilesService.fileDownload(`/api/v1/ttn/${supplierOrderId}/`, { method: 'get' });
  }

  public static async downloadXls(supplierOrderId: number): Promise<AxiosResponse<Blob>> {
    return await FilesService.fileDownload(`/api/v1/supplier_order/${supplierOrderId}/`, { method: 'get' });
  }

  public static async getLimitsReport(body?: Omit<ILimitFilterParams, 'page' | 'pageSize'>): Promise<void> {
    return await AuthHelper.fetch('/api/v1/reports/limits', {
      method: 'POST',
      body,
    });
  }

  public static async getOrdersReport(
    orderType: string,
    body: Record<string, unknown>,
    params?: Record<string, unknown>,
  ): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/reports/orders/${orderType}`, {
      method: 'POST',
      params,
      body,
    });
  }

  public static async getReports(page: number, pageSize: number, sort: string | undefined = undefined): Promise<IBasePagedResponse<IReport>> {
    return await AuthHelper.fetch('/api/v1/reports', {
      method: 'GET',
      params: {
        page,
        pageSize,
        sort,
      },
    });
  }

  public static async getOrdersCompositionReport(
    orderType: string,
    body: Record<string, unknown>,
    params?: Record<string, unknown>,
  ): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/reports/orders/${orderType}/composition`, {
      method: 'POST',
      params,
      body,
    });
  }

  public static async downloadLimitsTemplate(withCurrentLimits = false) {
    return await FilesService.fileDownload('/api/v1/limits/template/', {
      method: 'get',
      params: {
        with_current_limits: withCurrentLimits,
      },
    });
  }

  public static async uploadLimitsFile(file: FileTemp) {
    const formData = new FormData();
    formData.append('file', file);
    return await axios.post('/api/v1/limit/import_limits_from_file/', formData, {
      responseType: 'json',
      transformResponse: [function(data) {
        return NamingHelper.responseFileChecked(data);
      }],
    });
  }

  public static async createSupplierOrderReport(
    body: ISupplierOrdersRequestData,
    params?: ISupplierOrdersRequestParams,
  ): Promise<void> {
    return await AuthHelper.fetch('/api/v1/reports/supplier_orders', {
      method: 'POST',
      body,
      params,
    });
  }

  public static async createSupplierPriceOrderReport(params: ISupplierPriceOrdersRequestData): Promise<void> {
    return await AuthHelper.fetch('/api/v1/reports/price_report', {
      method: 'POST',
      params,
    });
  }

  public static async checkSelectedSupplierOrdersCount(body: ISupplierOrdersRequestData): Promise<IReportsConfig | undefined> {
    return await AuthHelper.fetch('/api/v1/reports/supplier_orders/check', {
      method: 'POST',
      body,
    });
  }

  public static async getReportsConfig(): Promise<IReportsConfig> {
    return await AuthHelper.fetch('/api/v1/reports/config', {
      method: 'GET',
    });
  }

  public static async getReportStatuses(reportsIds: Array<number>): Promise<IBasePagedResponse<IReport>> {
    return await AuthHelper.fetch('/api/v1/reports/status', {
      method: 'POST',
      body: reportsIds,
    });
  }

  public static async getOrdersCompositionByNeedsReport(
    orderType: string,
    body: Record<string, unknown>,
    params?: Record<string, unknown>,
  ): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/reports/orders/${orderType}/composition/erp`, {
      method: 'POST',
      params,
      body,
    });
  }

  public static async createOrderDraftFromXlsx(
      clientId: number,
      body: FormData,
      params: IClientCreateOrderFromXlxsParams,
  ): Promise<IClientParseXlsxResponse | IClientCreatedOrderFromXlxsResponse> {
    return await AuthHelper.fetch(`/api/v1/carts/${clientId}/upload`, {
      method: 'PUT',
      body,
      params,
      timeout: 60 * 1000,
    });
  }

  public static async getOrderConfirmationDocumentReport(orderId: number): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/reports/orders/${orderId}/ready`, {
      method: 'POST',
    });
  }
}
