import { ProviderService } from 'shared/models/providerService.model';
import Disabler from 'shared/utils/disablerHelper.util';
import { EBasketType } from 'enums/basket/basketType.enum';
import { EBasketStatus } from 'enums/basket/basketStatus.enum';
import { ComputedRef, Ref, ShallowRef } from 'vue';
import BasesService from 'services/basesManager.service';
import { ERuLocales } from 'shared/enums/ruLocales.enum';
import { BasketCounter } from 'services/basket-services/basket/basketCounter.service';
import { IBaseBasketError, IBasket, IBasketLoader, IBasketSum, IDeclinedErpItem, IPlaceOrderRecipient } from 'models/basket.model';
import { BASKET_TYPE } from 'constants/basket/basket.const';
import { BaseBasketCategory } from './baseBasketCategory.service';
import { formatPrice } from 'shared/utils/priceValueFormat.util';
import ModalManager from 'shared/services/modalManager.service';
import { TranslateOptions } from '@nuxtjs/i18n/dist/runtime/composables';
import { BasketApi } from 'services/api/basketApi.service';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import Notificator from 'shared/services/notificator.service';
import { ApiHelper } from 'shared/services/api/apiHelper.service';
import { IBasePagedResponse } from 'shared/models/baseApi.model';
import { IReport } from 'models/client/report.model';
import { FetchError } from 'ofetch';
import { ReportApiService } from 'services/api/reportApi.service';
import { EReportStatus } from 'enums/client/reportStatus.enum';
import FilesService from 'shared/services/api/files.service';
import Loader from 'shared/utils/loaderHelper.util';
import { BasketErpApi } from 'services/api/basketErpApi.service';
import { downloadFileByBlob } from 'shared/utils/fileSaver.util';
import { useUserStore } from 'store/user.store';
import { IBasisApproveRule } from 'models/client/approveRule.model';

export abstract class BaseBasket<D, T = EBasketStatus> extends ProviderService {
  protected static serviceName = 'basketService';
  protected static readonly basketPagePath: string;
  protected static readonly basketCounter = new BasketCounter();
  protected translateFn: (key: string, plural?: number, options?: TranslateOptions<string>) => string;

  public status = ref<string>('');
  public isDisableFooterLoader = Disabler.getReactiveInstance();
  public loaderInfo = ref<IBasketLoader>();
  public selectedType = ref<EBasketType>(EBasketType.Base);
  public isDivisible = ref(false);
  public recipient = ref<IPlaceOrderRecipient>();
  public basketStatus = ref<T>();
  public orderInfo?: ComputedRef<string>;
  public declinedItems = ref<Array<IDeclinedErpItem>>([]);
  public typeList: Ref<Array<IBasketSum>> = ref([]);
  public errors = ref<Array<IBaseBasketError>>([]);
  public deliveryDestinationId = ref<number | undefined>();
  public approverSub = ref<string>();
  public approveRule = shallowRef<IBasisApproveRule | null>(null);
  public isNeedApproval = ref(false);
  public isInventory = ref(false);
  public beId = ref(0);
  public basketReport = ref<IReport | undefined>();
  public data = ref<D | undefined>();

  protected readonly basesManager = BasesService.getInstance();

  public isLoading = Disabler.getReactiveInstance();
  public loadingInit = Loader.getReactiveInstance(true);
  public loadingDownloadAnalogs = Loader.getReactiveInstance();

  abstract categoryList: ShallowRef<Array<BaseBasketCategory>>;

  protected basketId?: number;
  protected isNeedAbortPollingReports = false;
  constructor(
    translateFn: (key: string, plural?: number, options?: TranslateOptions<string>) => string,
    basketId?: number,
    protected readonly modalManager = inject<ModalManager>(ModalManager.getServiceName()),
    protected readonly filesService = inject<FilesService>(FilesService.getServiceName()),
    protected readonly userStore = useUserStore(),
  ) {
    super();
    this.translateFn = translateFn;
    this.basketId = basketId;
  }

  abstract loadBasket(): Promise<void>;
  abstract saveBasket(modalManager?: ModalManager): Promise<void>;

  static getTypeNameByTypeId(typeId: EBasketType): string {
    return BASKET_TYPE[typeId];
  }

  static getCorrectedSumValue(value: number): string {
    return `${formatPrice(value ?? 0)}`;
  }

  static getDeliveryDate(date: string): string {
    return date ? DateFormat.default(date) : '-';
  }

  static getDeltaText(limit: number, delta: number, isOverPermissible?: boolean): string {
    if (!isOverPermissible && delta < 0) {
      return `превышение ${this.getCorrectedSumValue(Math.abs(delta))} в рамках допустимого, вы можете оформить заказ`;
    }

    if (delta < 0) {
      return `${'перерасход'} ${this.getCorrectedSumValue(Math.abs(delta))}`;
    }
    return `${'осталось'} ${this.getCorrectedSumValue(delta)} из ${this.getCorrectedSumValue(limit)}`;
  }

  static goToBasketPage(): void {
    if (!this.basketPagePath) {
      throw new Error('Укажите путь до корзины');
    }
    navigateTo(this.basketPagePath);
  }

  public setBasketId(basketId: number): void {
    this.basketId = basketId;
  }

  public getBasketId(): number | undefined {
    return this.basketId;
  }

  public killBasket(): void {
    this.isNeedAbortPollingReports = true;
  }

  public async selectBasketType(type: EBasketType): Promise<void> {
    if (this.isLoading.value) {
      return;
    }
    this.selectedType.value = type;
    await this.loadBasket();
  }

  public getTypeDataByTypeId(typeId: EBasketType): IBasketSum | undefined {
    return this.typeList.value.find(({ type }) => type === typeId);
  }

  public setBasketRecipient(recipient: IPlaceOrderRecipient): void {
    this.recipient.value = { ...recipient, mobilePhone: recipient?.mobilePhone || null };
  }

  public setApprover(sub: string): void {
    this.approverSub.value = sub;
  }

  public setApproveRule(rule: IBasisApproveRule | null): void {
    this.approveRule.value = rule;
  }

  /**
   * метод для "корректировки" типа корзины. Если в типах корзины нет текущего выбранного типа,
   * то меняем на альтернативный тип ((EBasketType.Price -> EBasketType.Delivery, EBasketType.Delivery -> EBasketType.Price)) и перезапрашиваем корзину.
   * Если тип === EBasketType.Base - ничего не фиксим.
   */
  protected async correctBasketType(basketData: IBasket | Record<string, unknown>): Promise<boolean> {
    if (this.selectedType.value !== EBasketType.Base && !(basketData as IBasket).sum.some((sum) => sum.type === this.selectedType.value)) {
      this.isLoading.deactivate();
      this.selectBasketType(this.selectedType.value === EBasketType.Price ? EBasketType.Delivery : EBasketType.Price);
      await this.loadBasket();
      return true;
    }
    return false;
  }

  protected setTypeList(typeList: Array<IBasketSum> = []): void {
    this.typeList.value = typeList;
  }

  protected setBasketStatus(status: T): void {
    this.basketStatus.value = status;
  }

  protected initEmptyBasket(): void {
    this.data.value = undefined;
    this.status.value = '';
    this.setTypeList([]);
    this.categoryList.value = [];
    this.isDivisible.value = false;
    this.isNeedApproval.value = false;
  }

  initOrderInfo(): void {
    this.orderInfo = computed<string>(
      () =>
        `${this.translateFn(ERuLocales.Item, BaseBasket.basketCounter.counter.value)}${
          this.basesManager?.base ? ` с доставкой в ${this.basesManager.base}` : ''
        }`,
    );
  }

  public async downloadAnalogs(erp = false): Promise<void> {
    if (!this.basketId) {
      return;
    }

    this.loadingDownloadAnalogs.activate();

    try {
      this.basketReport.value = erp
        ? await BasketErpApi.getErpOrderReportById(this.basketId)
        : await BasketApi.getBasketReportById(this.basketId);

      await this.startPollingAnalogsReport(this.basketReport.value.id);
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        BaseBasket.getServiceName(),
        undefined,
        {
          extra: {
            basket: this.basketId,
          },
        },
      );

      Notificator.showDetachedNotification('Ошибка при формировании отчета');
    } finally {
      this.loadingDownloadAnalogs.deactivate();
    }
  }

  private async startPollingAnalogsReport(reportId: number): Promise<void> {
    this.loadingDownloadAnalogs.activate();
    const { public: { reportsFormingTimeout } } = useRuntimeConfig();

    try {
      const response = await ApiHelper.retryRequest<IBasePagedResponse<IReport>, FetchError>(
        ReportApiService.getReportStatuses.bind(ReportApiService),
        [[reportId]],
        {
          retryTimeout: (reportsFormingTimeout ?? 3) * 1000,
          isNeedRetry: (res) => !this.isNeedAbortPollingReports
            && ![EReportStatus.Formed, EReportStatus.Failed].includes(res.items?.[0]?.status),
        },
      );

      if (this.isNeedAbortPollingReports) {
        return;
      }

      if (!response?.items?.length) {
        return Notificator.showDetachedNotification('Ошибка при проверке статуса формирования отчета');
      }

      this.basketReport.value = response.items[0];

      if (this.basketReport.value.status === EReportStatus.Failed) {
        return;
      }

      if (!await this.modalManager?.showConfirmModal(
        'Файл с аналогами готов к скачиванию',
        'Вы также можете скачать его в Личном кабинете в разделе «Отчеты»',
        'Скачать',
        undefined,
        {
          hideCancelButton: true,
          contentTextClass: 'mm-fs-14px',
        },
      )) {
        return;
      }

      const downloadedFile = await this.filesService?.downloadFile(
        this.basketReport.value.fileId,
        `/api/v1/files/download/client/reports/${this.basketReport.value.fileId}`,
      );

      if (!downloadedFile?.data) {
        return Notificator.showDetachedNotification('Не удалось скачать файл');
      }

      downloadFileByBlob(downloadedFile.data, this.basketReport.value.fileData?.fileName || 'Отчет');
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        BaseBasket.getServiceName(),
        undefined,
        {
          extra: {
            reportId,
          },
        },
      );

      Notificator.showDetachedNotification('Ошибка при проверке статуса формирования отчета');
    } finally {
      this.loadingDownloadAnalogs.deactivate();
    }
  }

  protected async checkCartReportStatus(
    basketReport?: IReport,
  ): Promise<void> {
    if (basketReport?.status !== EReportStatus.Forming) {
      return;
    }

    this.basketReport.value = basketReport;

    await this.startPollingAnalogsReport(basketReport.id);
  }
}
