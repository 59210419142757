<template>
  <Select
    v-model="selectedUser"
    :value="modelValue"
    taggable
    :filterable="false"
    :options="searchUsersToShow"
    :is-clearable="props.isClearable"
    :hide-clear="!props.isClearable"
    :type="ESelectType.DescriptionList"
    :filter-by="() => true"
    :disabled="disabled"
    :create-option="createOption"
    :label="placeholder"
    :validation-field="validationField"
    :clear-search-on-blur="() => false"
    :clear-search-on-select="false"
    searchable
    :lazy="props.isSearchLoading"
    :is-has-lazy-load="props.isSearchLoading && isLoading.value"
    :loading-label="props.loadingLabel"
    :is-hide-no-results-label="!searchText || props.isHideNoResultsLabel || isLoading.value"
    :no-results-label="props.noResultsLabel"
    class="mm-address-select"
    :is-italics="props.isItalics"
    :class="{ 'mm-searching': searchText, 'search-loading': props.isSearchLoading }"
    @close="onClose"
    @search="onUserInput"
    @option:selected="onSelected"
    @option:deselected="onDeselected"
  >
    <template
      v-if="isCustomIndicator"
      #open-indicator
    >
      <SvgIcon
        v-if="(!hideIndicator || !selectedUser) && !hideEmptyIndicator"
        src="navigation/search"
      />
    </template>
    <template #search="{ attributes, events }">
      <component
        :is="`input`"
        :id="attributes.inputId"
        ref="searchComponentRef"
        class="vs__search"
        :disabled="attributes.disabled"
        type="search"
        :aria-autocomplete="attributes['aria-autocomplete']"
        :aria-labelledby="attributes['aria-labelledby']"
        :aria-controls="attributes['aria-controls']"
        :aria-activedescendant="attributes['aria-activedescendant']"
        :tabindex="attributes.tabindex"
        :value="searchText"
        :maxlength="maxLength"
        @input="onUserInput($event.target.value)"
        @compositionstart="events.compositionstart"
        @compositionend="events.compositionend"
        @keydown="events.keydown"
        @blur="events.blur"
        @focus="events.focus"
      />
    </template>

    <template #selected-option>
      <div />
    </template>

    <template #selected-option-container>
      <div />
    </template>

    <template
      v-if="props.isSearchLoading"
      #no-options
    >
      <div />
    </template>
  </Select>
</template>

<script lang="ts" setup>
import { FieldContext } from 'vee-validate';
import Select from 'shared/components/Select.vue';
import { ESelectType } from 'shared/enums/select.enum';
import { ISelectOptionDeputy } from 'shared/models/select.model';
import { IUserInfoResponse } from 'models/auth/userInfo.model';
import { debounce } from 'lodash-es';
import { ClientApi } from 'services/api/clientApi.service';
import { AbortControllerUtil } from 'shared/utils/abortController.util';
import Loader from 'shared/utils/loaderHelper.util';
import { StringsHelper } from 'shared/utils/strings.util';
import Notificator from 'shared/services/notificator.service';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';

const props = withDefaults(
  defineProps<{
    validationField?: FieldContext<string>;
    modelValue?: string;
    label?: string;
    isIconHidden?: boolean;
    placeholder: string;
    hideIndicator?: boolean;
    disabled?: boolean;
    maxLength?: number;
    component?: 'input' | 'textarea';
    getOption?: (item: IUserInfoResponse) => ISelectOptionDeputy; // Получить кастомный объект для опции
    filterOption?: (item: IUserInfoResponse) => boolean; // Отфильтровать данные после получения с API
    getSearchText?: (searchText: string) => string; // Подготовить поисковую фразу перед отправкой
    createOption?: (value: string) => ISelectOptionDeputy; // Создать значение из вводимой строки
    getInitOption?: () => ISelectOptionDeputy;
    methodApi: (searchText: string) => Promise<Array<IUserInfoResponse>> | Array<IUserInfoResponse>;
    isClearable?: boolean;
    isSearchLoading?: boolean;
    isCustomIndicator?: boolean;
    loadingLabel?: string;
    isHideNoResultsLabel?: boolean;
    noResultsLabel?: string;
    hideEmptyIndicator?: boolean;
    allowEmptySearch?: boolean;
    hideDescription?: boolean;
    isItalics?: boolean
  }>(),
  {
    component: 'input',
    methodApi: ClientApi.searchClientDelegatees,
    isClearable: true,
    isCustomIndicator: true,
    isHideNoResultsLabel: true,
  },
);

const selectedUser = ref<ISelectOptionDeputy>();
const searchComponentRef = ref<HTMLInputElement | HTMLTextAreaElement>();

let abortController: AbortController;

const emits = defineEmits<{
  (e: 'update:modelValue', value: string | number): void;
  (e: 'selectedOption', option: ISelectOptionDeputy): void;
  (e: 'deselectedOption', option: ISelectOptionDeputy): void;
}>();

const watchSubscription = new WatchSubscription();
const searchUsersToShow = ref<Array<ISelectOptionDeputy>>([]);
const searchText = ref(props.modelValue);
const isLoading = Loader.getReactiveInstance();
const searchLoadingVisibility = computed<string>(() => isLoading.value || searchUsersToShow.value?.length ? 'visible' : 'hidden');

function onUserInput(value: string) {
  searchText.value = value;
  emits('update:modelValue', searchText.value);
  debounceSearch();
}

function onClose() {
  emits('update:modelValue', selectedUser.value?.value ?? searchText.value);
}

async function searchUsers() {
  searchUsersToShow.value = [];
  abortController?.abort();

  if (!props.allowEmptySearch && !searchText.value) {
    return;
  }

  try {
    isLoading.activate();
    abortController = AbortControllerUtil.createAbortController();
    const users: Array<IUserInfoResponse> = await props.methodApi(searchText.value);
    searchUsersToShow.value =
      users
        ?.filter((user) => (props.filterOption ? props.filterOption(user) : true))
        .map((user) => getDefaultOption(user)) || [];
  } catch (error) {
    if (AbortControllerUtil.isRequestAborted(error)) {
      return;
    }
    console.error(error);
    Notificator.showDetachedNotification('Ошибка при поиске пользователя');
  } finally {
    isLoading.deactivate();
  }
}

function getDefaultOption(user: IUserInfoResponse): ISelectOptionDeputy {
  return {
    label: StringsHelper.arrayToString([user.lastName, user.name, user.patronymic], ' '),
    value: user.email,
    email: user.email,
    description: props.hideDescription ? '' : user.email,
    sub: user.sub,
    additionalCode: user.additionalCode,
    phone: user.phone,
    workPhone: user.workPhone,
  };
}

function init(): void {
  if (!props.modelValue) {
    debounceSearch();
    return;
  }

  const inititalValue: ISelectOptionDeputy = {
    value: props.modelValue,
    label: props.modelValue,
    email: '',
    sub: '',
  };
  searchUsersToShow.value = [inititalValue];
  selectedUser.value = inititalValue;
  searchText.value = inititalValue.label;
}

function onSelected(option: ISelectOptionDeputy): void {
  emits('selectedOption', option);
  searchText.value = option.label;
}

function onDeselected(): void {
  emits('deselectedOption', undefined);
  searchText.value = '';
}

const debounceSearch = debounce(searchUsers, 300);

watchSubscription.add(
  watch(
    () => props.modelValue,
    (newValue, preValue) => {
      if (!newValue && preValue) {
        selectedUser.value = undefined;
      }
      debounceSearch();
    },
  ),

  watch(selectedUser, (newValue) => {
    emits('update:modelValue', newValue?.value ?? '');
  }),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(async () => {
  init();
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-address-select {
  :deep(.v-select) {
    .vs__actions {
      transform: translateY(-50%);
      top: auto !important;
      margin-top: 3px;

      .mm-select__deselect {
        margin-right: 0;

        path {
          fill: $text-disabled;
        }
      }
    }
  }

  &.mm-searching {
    :deep(.mm-select__label) {
      transform: translate3d(0, -85%, 0) scale(0.85);
    }
  }

  :deep(.vs__dropdown-menu) {
    max-height: 228px;
  }
}

textarea[class="vs__search"] {
  height: 20px
}

.vs__search {
  resize: none;
  transition: height 0.1ms ease-in-out;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

}

:deep(.select-option__label) {
  font-weight: 400 !important;
}

.search-loading {
  :deep([role="listbox"]) {
    visibility: v-bind(searchLoadingVisibility) !important;
  }

  :deep(.footer-load) {
    font-size: 14px;
    line-height: 20px;
    color: $light-green;
  }
}
</style>
