import { format, parse, intervalToDuration, Duration, isToday, formatISO } from 'date-fns';
import utcToZonedTime from 'date-fns-tz/esm/utcToZonedTime';
import ru from 'date-fns/locale/ru/index.js';
import { ETimezones } from '../enums/timezones.enum';
import dateFnsTz from 'date-fns-tz';

const formatOptions = { locale: ru };

export class DateFormat {
  public static default(value: string | Date): string {
    if (value instanceof Date) {
      return format(value, 'dd.MM.yyyy', formatOptions);
    }
    if (!value || value.length <= 3) {
      return value;
    }

    return this.formatDate(value, 'dd.MM.yyyy', formatOptions);
  }

  public static iso(value: string | Date): string {
    if (value instanceof Date) {
      return format(value, 'yyyy-MM-dd', formatOptions);
    }
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, 'yyyy-MM-dd', formatOptions);
  }

  public static withHoursMinutes(value: string): string {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, 'dd.MM.yyyy HH:mm', formatOptions);
  }

  public static withTime(value: string): string {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, 'dd.MM.yyyy, HH:mm:ss', formatOptions);
  }

  public static withTimeAndMscWithoutMs(value: string): string {
    if (!value || value.length <= 3) {
      return value;
    }

    return this.formatDate(value, 'dd.MM.yyyy, HH:mm (МСК)', formatOptions);
  }

  public static withTimeReversed(value: string, needSeconds = true) {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, `HH:mm${needSeconds ? ':ss' : ''}, dd.MM.yyyy`, formatOptions);
  }

  public static withoutYear(value: string | Date): string {
    if (value instanceof Date) {
      return format(value, 'dd.MM', formatOptions);
    }
    if (!value || value.length <= 3) {
      return value;
    }

    return this.formatDate(value, 'dd.MM', formatOptions);
  }

  public static withoutDay(value: string): string {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, 'MM.yyyy', formatOptions);
  }

  public static year(value: string): string {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, 'yyyy', formatOptions);
  }

  public static nameMonthYear(value: string) {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, 'LLLL yyyy', formatOptions);
  }

  public static time(value: string, customFormat = 'HH:mm:ss') {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, customFormat, formatOptions);
  }

  public static timeWithoutSeconds(value: string) {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, 'HH:mm', formatOptions);
  }

  public static intervalToDuration(ms: number): Duration {
    return intervalToDuration({ start: 0, end: ms });
  }

  public static parse(value: string, formatString: string, timezone?: string) {
    const currentTimezone = timezone ?? useRuntimeConfig().public?.currentTimezone ?? ETimezones.Moscow;
    return parse(utcToZonedTime(`${value}Z`, currentTimezone).toString(), formatString, new Date());
  }

  public static formatDate(value: string, formatString: string, formatOptions: dateFnsTz.OptionsWithTZ, timezone?: string) {
    return dateFnsTz.formatInTimeZone(
      new Date(value),
      timezone || useRuntimeConfig().public?.currentTimezone || ETimezones.Moscow,
      formatString,
      formatOptions,
    );
  }

  public static todayOrDefault(value: string): string {
    if (isToday(new Date(value))) {
      return 'Сегодня';
    }

    return this.default(value);
  }

  public static withSemiHoursMinutes(value: string): string {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, 'dd.MM.yyyy, HH:mm', formatOptions);
  }

  public static todayOrDefaultWithHoursMinutes(value: string): string {
    if (isToday(new Date(value))) {
      return `Сегодня, ${DateFormat.timeWithoutSeconds(value)}`;
    }

    return DateFormat.withSemiHoursMinutes(value);
  }

  public static shortDate(value: string | Date): string {
    if (value instanceof Date) {
      return format(value, 'dd.MM.yy', formatOptions);
    }
    if (!value || value.length <= 3) {
      return value;
    }

    return this.formatDate(value, 'dd.MM.yy', formatOptions);
  }

  public static formatLocaDateTimeToISO(date: number | Date | string): string {
    if (date instanceof Date) {
      return formatISO(date);
    }

    return formatISO(new Date(date));
  }
}
