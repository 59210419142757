export class FileUtil {
  public static arrayBufferToBuffer(arrayBuffer: ArrayBuffer): Buffer {
    const resultBuffer = Buffer.alloc(arrayBuffer.byteLength);
    const viewBuffer = new Uint8Array(arrayBuffer);
    for (let i = 0; i < resultBuffer.length; ++i) {
      resultBuffer[i] = viewBuffer[i];
    }

    return resultBuffer;
  }

  public static async fileToBase64(blob: Blob | File): Promise<string | ArrayBuffer> {
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onloadend = function () {
        resolve(reader.result);
      };

      reader.readAsDataURL(blob);
    });
  }
}
