export const NOT_INSTALLED_PLUGIN_ERROR = 'NotInstalledPluginError';
export const ABORTED_BY_ABORT_CONTROLLER_ERROR = 'AbortedByAbortControllerError';
export const SUPPLIER_CHANGE_SELF_STATUS_ERROR = 'SupplierChangeSelfStatusError';
export const SUPPLIER_DELETE_SELF_ERROR = 'SupplierDeleteSelfError';

export const NOT_FOUND_ERROR_CODE = 404;
export const FORBIDDEN_ERROR_CODE = 403;
export const CONFLICT_ERROR_CODE = 409;
export const BAD_REQUEST_ERROR_CODE = 400;
export const INTERNAL_SERVER_ERROR_CODE = 500;
