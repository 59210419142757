export enum ESignAlgorithms {
  CADESCOM_HASH_ALGORITHM_SHA1,
  CADESCOM_HASH_ALGORITHM_MD2,
  CADESCOM_HASH_ALGORITHM_MD4,
  CADESCOM_HASH_ALGORITHM_MD5,
  CADESCOM_HASH_ALGORITHM_SHA_256,
  CADESCOM_HASH_ALGORITHM_SHA_384,
  CADESCOM_HASH_ALGORITHM_SHA_512,

  CADESCOM_HASH_ALGORITHM_CP_GOST_3411 = 100,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512,

  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_HMAC = 110,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256_HMAC,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512_HMAC,
}
