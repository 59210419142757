import { ProviderService } from '../models/providerService.model';
import { FilterUtils } from '../utils/filterHelper.util';
import { IFilterItem, IFiltersMakeQueryParams } from '../models/filters.model';
import { ESortState } from '../enums/sortState.enum';
import { ITableSort } from '../models/table.model';
import { NavigationFailure } from 'vue-router';
import { EQueryParam } from '../enums/queryParam.enum';

export interface IQueryParams {
  search?: string;
  sort?: ITableSort | string;
  page?: string | number;
  filter?: IFilterItem[] | undefined;
  categoryId?: string | number;
}

export default class QueryParamsService extends ProviderService {
  static readonly serviceName = 'queryParamsService';
  route = useRoute();
  router = useRouter();

  private static instance: QueryParamsService;

  private constructor() {
    super();
  }

  public static getInstance(): QueryParamsService {
    if (!QueryParamsService.instance) {
      QueryParamsService.instance = new QueryParamsService();
    }

    return QueryParamsService.instance;
  }

  async saveQueryParams(
    params: IQueryParams,
    filtersMakeQueryParams?: IFiltersMakeQueryParams,
    saveFilterFromRoute?: boolean,
  ): Promise<void | NavigationFailure | undefined> {
    return await this.router.replace({
      query: {
        ...this.route.query,
        search: params.search || undefined,
        sort: this.getSort(params.sort),
        page: params.page !== 1 ? params.page || undefined : undefined,
        categoryId: params.categoryId || undefined,
        filter:
          params.filter && !!FilterUtils.getSelectedFilters(params.filter)?.length
            ? FilterUtils.makeQueryObjectString(params.filter, filtersMakeQueryParams)
            : saveFilterFromRoute
              ? this.route.query.filter || undefined
              : undefined,
      },
    });
  }

  async clearQueryParam(nameParams: string): Promise<void> {
    await this.router.replace({
      query: {
        ...this.route.query,
        [nameParams]: undefined,
      },
    });
  }

  async saveQueryParamItem(nameParams: string, value: string): Promise<void> {
    await this.router.replace({
      query: {
        ...this.route.query,
        [nameParams]: value,
      },
    });
  }

  async clear(): Promise<void> {
    await this.router.replace({
      query: {
        ...this.route.query,
        [EQueryParam.Search]: undefined,
        [EQueryParam.Sort]: undefined,
        [EQueryParam.Page]: undefined,
        [EQueryParam.Filter]: undefined,
        [EQueryParam.CategoryId]: undefined,
      },
    });
  }

  getQueryParams(): IQueryParams {
    this.route = useRoute();
    const result: IQueryParams = {};

    if (this.route.query[EQueryParam.Page]) {
      result.page = Number(this.route.query[EQueryParam.Page]);
    }

    if (this.route.query[EQueryParam.Sort]) {
      const tempSort = (this.route.query[EQueryParam.Sort] as string).split('-');
      result.sort = tempSort.length
        ? {
          field: tempSort[0],
          order: tempSort[1] as ESortState,
        }
        : undefined;
    }

    if (this.route.query[EQueryParam.Search]) {
      result.search = this.route.query[EQueryParam.Search] as string;
    }

    if (this.route.query[EQueryParam.CategoryId]) {
      result.categoryId = this.route.query[EQueryParam.CategoryId] as string;
    }

    return result;
  }

  getOriginalFiltersQueryParams(): Record<string, string | null | Array<string | null>> {
    this.route = useRoute();
    return {
      search: this.route.query[EQueryParam.Search],
      sort: this.route.query[EQueryParam.Sort],
      page: this.route.query[EQueryParam.Page],
      filter: this.route.query[EQueryParam.Filter],
      categoryId: this.route.query[EQueryParam.CategoryId],
    };
  }

  getFilterFromQueryParams(filters: IFilterItem[], tempQueryFilter?: string): IFilterItem[] {
    const queryFilters = tempQueryFilter || this?.route?.query?.[EQueryParam.Filter];
    if (!queryFilters) {
      return filters;
    }

    return FilterUtils.setFilterValuesFromQuery(filters, queryFilters);
  }

  public getFilter(): Record<string, unknown> | undefined {
    this.route = useRoute();
    const queryParamFilter = this.route?.query?.[EQueryParam.Filter];

    if (!queryParamFilter) {
      return;
    }

    return JSON.parse(queryParamFilter.toString());
  }

  getFieldValueFromQueryParams(field: string): string {
    return this.getFilter()?.[field] as string;
  }

  private getSort(sort: ITableSort | string | undefined): string | undefined {
    if (typeof sort !== 'string' && (sort?.field == null || sort?.order == null)) {
      return;
    }

    return typeof sort === 'string' ? sort : sort.field + '-' + sort.order;
  }
}
