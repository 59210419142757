import { EUserGroup } from '../../enums/user/userGroup.enum';

export const CLIENT_GROUPS = new Map<EUserGroup, true>([
  [EUserGroup.ClientAdmin, true],
  [EUserGroup.ClientAuditor, true],
  [EUserGroup.ClientBoss, true],
  [EUserGroup.ClientErp, true],
  [EUserGroup.ClientFinContr, true],
  [EUserGroup.ClientMpErp, true],
  [EUserGroup.ClientCategoryManager, true],
]);

export const SUPPLIER_GROUPS = new Map<EUserGroup, true>([
  [EUserGroup.Supplier, true],
]);