import { EIconPath } from '../enums/iconPath.enum';
import { Component } from 'vue';

/**
 * Icons
 */
import ActionAttachSvg from '../public/icons/action/attach.svg';
import ActionCartMiniSvg from '../public/icons/action/cart-mini.svg';
import ActionCartSvg from '../public/icons/action/cart.svg';
import ActionCatalog from '../public/icons/action/catalogs.svg';
import ActionCloseSvg from '../public/icons/action/close.svg';
import ActionComment24PxSvg from '../public/icons/action/comment-24px.svg';
import ActionCommentReview24PxSvg from '../public/icons/action/comment-review-24px.svg';
import ActionCommentReviewSvg from '../public/icons/action/comment-review.svg';
import ActionCommentSvg from '../public/icons/action/comment.svg';
import ActionCompanySvg from '../public/icons/action/company.svg';
import ActionCompareSvg from '../public/icons/action/compare.svg';
import ActionCopySvg from '../public/icons/action/copy.svg';
import ActionDateSvg from '../public/icons/action/date.svg';
import ActionDeliverySvg from '../public/icons/action/delivery.svg';
import ActionDownloadSvg from '../public/icons/action/download.svg';
import ActionDownloadYellowSvg from '../public/icons/action/download-yellow.svg';
import ActionEyeSvg from '../public/icons/action/eye.svg';
import ActionFavouritesSvg from '../public/icons/action/favourites.svg';
import ActionFileErrorSvg from '../public/icons/action/file-error.svg';
import ActionGalleryViewSvg from '../public/icons/action/gallery-view.svg';
import ActionGluingSvg from '../public/icons/action/gluing.svg';
import ActionIconMinusDarkSvg from '../public/icons/action/icon-minus-dark.svg';
import ActionIconMinusSvg from '../public/icons/action/icon-minus.svg';
import ActionIconPlusDarkSvg from '../public/icons/action/icon-plus-dark.svg';
import ActionIconPlusSvg from '../public/icons/action/icon-plus.svg';
import ActionInfoSvg from '../public/icons/action/info.svg';
import ActionLimitsSvg from '../public/icons/action/limits.svg';
import ActionLinkSvg from '../public/icons/action/link.svg';
import ActionListingViewMiniSvg from '../public/icons/action/listing-view-mini.svg';
import ActionListingViewSvg from '../public/icons/action/listing-view.svg';
import ActionMinusSvg from '../public/icons/action/minus.svg';
import ActionModerationSvg from '../public/icons/action/moderation.svg';
import ActionMoreDotsSvg from '../public/icons/action/more-dots.svg';
import ActionOrderSvg from '../public/icons/action/order.svg';
import ActionPauseSvg from '../public/icons/action/pause.svg';
import ActionPdfPerforatedSvg from '../public/icons/action/pdf-perforated.svg';
import ActionPdfSvg from '../public/icons/action/pdf.svg';
import ActionPenEditSvg from '../public/icons/action/pen-edit.svg';
import ActionPlusSvg from '../public/icons/action/plus.svg';
import ActionPriceRangeSvg from '../public/icons/action/price-range.svg';
import ActionPrintSvg from '../public/icons/action/print.svg';
import ActionRangeSvg from '../public/icons/action/range.svg';
import ActionRepeatSvg from '../public/icons/action/repeat.svg';
import ActionReportSvg from '../public/icons/action/reports.svg';
import ActionReviewSvg from '../public/icons/action/review.svg';
import ActionSettingsSvg from '../public/icons/action/settings.svg';
import ActionSettings2Svg from '../public/icons/action/settings2.svg';
import ActionSpinnerSvg from '../public/icons/action/spinner.svg';
import ActionTaskListSvg from '../public/icons/action/task-list.svg';
import ActionQuestionSvg from '../public/icons/action/question.svg';
import ActionThumbsDownSvg from '../public/icons/action/thumbs-down.svg';
import ActionThumbsUpSvg from '../public/icons/action/thumbs-up.svg';
import ActionTrashSvg from '../public/icons/action/trash.svg';
import ActionTrashTransparentSvg from '../public/icons/action/trash-transparent.svg';
import ActionTransferSvg from '../public/icons/action/transfer.svg';
import ActionUploadProcessingSvg from '../public/icons/action/upload-processing.svg';
import ActionUploadSvg from '../public/icons/action/upload.svg';
import ActionZoomInSvg from '../public/icons/action/zoom-in.svg';
import ActionVideoWhiteSvg from '../public/icons/action/video-icon-white.svg';
import ActionUpdateSvg from '../public/icons/action/update.svg';
import ActionVideoGradientSvg from '../public/icons/action/video-icon-gradient.svg';
import ActionNotificationSvg from '../public/icons/action/notification.svg';
import ActionTimeSvg from '../public/icons/action/time.svg';
import ActionTimeAccentSvg from '../public/icons/action/time-accent.svg';
import ActionMagicSvg from '../public/icons/action/magic.svg';
import DragHandlerSvg from '../public/icons/action/drag-handler.svg';
import IndicatorsBestPriceSvg from '../public/icons/indicators/best-price.svg';
import IndicatorsBulletDotSvg from '../public/icons/indicators/bullet-dot.svg';
import IndicatorsBulletNumberSvg from '../public/icons/indicators/bullet-number.svg';
import IndicatorsCartItemsSvg from '../public/icons/indicators/cart-items.svg';
import IndicatorsCheckmarkRoundErrorSvg from '../public/icons/indicators/checkmark-round-error.svg';
import IndicatorsCheckmarkRoundFileSvg from '../public/icons/indicators/checkmark-round-file.svg';
import IndicatorsCheckmarkRoundWatchSvg from '../public/icons/indicators/checkmark-round-watch.svg';
import IndicatorsCheckmarkRoundSvg from '../public/icons/indicators/checkmark-round.svg';
import IndicatorsCheckmarkWarnSvg from '../public/icons/indicators/checkmark-warn.svg';
import IndicatorsCheckmarkRoundDarkSvg from '../public/icons/indicators/checkmark-round-dark.svg';
import IndicatorsCheckmarkSimpleSvg from '../public/icons/indicators/checkmark-simple.svg';
import IndicatorsCheckmarkSimpleAccentSvg from '../public/icons/indicators/checkmark-simple-accent.svg';
import IndicatorsFastShippingSvg from '../public/icons/indicators/fast-shipping.svg';
import IndicatorsInfoDarkSvg from '../public/icons/indicators/info-dark.svg';
import IndicatorsInfoOutlineSvg from '../public/icons/indicators/info-outline.svg';
import IndicatorsProgressSvg from '../public/icons/indicators/progress.svg';
import IndicatorsProgressDarkSvg from '../public/icons/indicators/progress-dark.svg';
import IndicatorsProgressLightSvg from '../public/icons/indicators/progress-light.svg';
import IndicatorsStarSvg from '../public/icons/indicators/star.svg';
import IndicatorsStepCloseSvg from '../public/icons/indicators/step-close.svg';
import IndicatorsWarningSvg from '../public/icons/indicators/warning.svg';
import NavigationArrowDown20PxSvg from '../public/icons/navigation/arrow-down-20px.svg';
import NavigationArrowDownSmallSvg from '../public/icons/navigation/arrow-down-small.svg';
import NavigationArrowDownSvg from '../public/icons/navigation/arrow-down.svg';
import NavigationArrowLeft20PxSvg from '../public/icons/navigation/arrow-left-20px.svg';
import NavigationArrowLeftSmallSvg from '../public/icons/navigation/arrow-left-small.svg';
import NavigationArrowLeftSvg from '../public/icons/navigation/arrow-left.svg';
import NavigationArrowRight20PxSvg from '../public/icons/navigation/arrow-right-20px.svg';
import NavigationArrowRightSmallSvg from '../public/icons/navigation/arrow-right-small.svg';
import NavigationArrowRightSvg from '../public/icons/navigation/arrow-right.svg';
import NavigationArrowTop20PxSvg from '../public/icons/navigation/arrow-top-20px.svg';
import NavigationArrowUpSmallSvg from '../public/icons/navigation/arrow-up-small.svg';
import NavigationArrowUpSvg from '../public/icons/navigation/arrow-up.svg';
import NavigationClockSvg from '../public/icons/navigation/clock.svg';
import NavigationClose20PxSvg from '../public/icons/navigation/close-20px.svg';
import NavigationClose24PxSvg from '../public/icons/navigation/close-24px.svg';
import NavigationClose36PxSvg from '../public/icons/navigation/close-36px.svg';
import NavigationFilterNewSvg from '../public/icons/navigation/filter-new.svg';
import NavigationCloseRed20PxSvg from '../public/icons/navigation/close-red-20px.svg';
import NavigationFilterSvg from '../public/icons/navigation/filter.svg';
import NavigationHelpSvg from '../public/icons/navigation/help.svg';
import NavigationHomeSvg from '../public/icons/navigation/home.svg';
import NavigationInfo from '../public/icons/navigation/info.svg';
import NavigationInfoFillSvg from '../public/icons/navigation/info-fill.svg';
import NavigationInfoFill16Svg from '../public/icons/navigation/info-fill-16.svg';
import NavigationInfoOrangeSvg from '../public/icons/navigation/info-orange.svg';
import NavigationInfoGreenSvg from '../public/icons/navigation/info-green.svg';
import NavigationInfoRedSvg from '../public/icons/navigation/info-red.svg';
import NavigationInfoOutlineSvg from '../public/icons/navigation/info-outline.svg';
import NavigationInfoYellowSvg from '../public/icons/navigation/info-yellow.svg';
import NavigationLkSvg from '../public/icons/navigation/lk.svg';
import NavigationLocationSvg from '../public/icons/navigation/location.svg';
import NavigationLoginSvg from '../public/icons/navigation/login.svg';
import NavigationLogin20pxSvg from '../public/icons/navigation/login-20px.svg';
import NavigationMainArrowSvg from '../public/icons/navigation/main-arrow.svg';
import NavigationMenuSvg from '../public/icons/navigation/menu.svg';
import NavigationSearchSvg from '../public/icons/navigation/search.svg';
import NavigationSmallArrowDownSvg from '../public/icons/navigation/small-arrow-down.svg';
import NavigationSmallArrowLeftSvg from '../public/icons/navigation/small-arrow-left.svg';
import NavigationSmallArrowRightSvg from '../public/icons/navigation/small-arrow-right.svg';
import NavigationSmallArrowUpSvg from '../public/icons/navigation/small-arrow-up.svg';
import NavigationSortingSvg from '../public/icons/navigation/sorting.svg';
import NavigationArrowBack from '../public/icons/navigation/arrow-back.svg';
import NavigationArrowLongDown20pxSvg from '../public/icons/navigation/arrow-long-down-20px.svg';
import NavigationOpenNewTabSvg from '../public/icons/navigation/open-new-tab.svg';
import SocialTelegramLightSvg from '../public/icons/social/telegram-light.svg';
import SocialTelegramSvg from '../public/icons/social/telegram.svg';
import SocialYoutubeLightSvg from '../public/icons/social/youtube-light.svg';
import SocialYoutubeSvg from '../public/icons/social/youtube.svg';
import SystemHandSvg from '../public/icons/system/hand.svg';
import TextFormattingAlignSvg from '../public/icons/text-formatting/align.svg';
import TextFormattingBackgroundColorSvg from '../public/icons/text-formatting/background-color.svg';
import TextFormattingBoldSvg from '../public/icons/text-formatting/bold.svg';
import TextFormattingBulletListSvg from '../public/icons/text-formatting/bullet-list.svg';
import TextFormattingColorSvg from '../public/icons/text-formatting/color.svg';
import TextFormattingHeadingSvg from '../public/icons/text-formatting/heading.svg';
import TextFormattingIndentRightSvg from '../public/icons/text-formatting/indent-right.svg';
import TextFormattingItalicsSvg from '../public/icons/text-formatting/italics.svg';
import TextFormattingOrderedListSvg from '../public/icons/text-formatting/ordered-list.svg';
import TextFormattingOutdentSvg from '../public/icons/text-formatting/outdent.svg';
import TextFormattingPolygonSvg from '../public/icons/text-formatting/polygon.svg';
import TextFormattingRedoSvg from '../public/icons/text-formatting/redo.svg';
import TextFormattingStrikeSvg from '../public/icons/text-formatting/strike.svg';
import TextFormattingUnderlineSvg from '../public/icons/text-formatting/underline.svg';
import TextFormattingUndoSvg from '../public/icons/text-formatting/undo.svg';
import LogoDescOffSvg from '../public/icons/logo-desc-off.svg';
import PlaySvg from '../public/icons/action/play.svg';
import PlayInvertedSvg from '../public/icons/action/play-inverted.svg';
import ChevronBackSvg from '../public/icons/navigation/chevron-back.svg';
import LockSvg from '../public/icons/action/lock.svg';
import UnLockSvg from '../public/icons/action/unlock.svg';
/**
 * images
 */
import ImagesLogoInvertedDescOn from '../public/images/logo-inverted-desc-on.svg';
import ImagesLogoDescOn from '../public/images/logo-desc-on.svg';
import ImagesLogoInvertedDescOff from '../public/images/logo-inverted-desc-off.svg';
import ImagesLogoDescOff from '../public/images/logo-desc-off.svg';
import ImagesLogoIcon from '../public/images/logo-icon.svg';
import ImagesLogoIconInverted from '../public/images/logo-icon-inverted.svg';

export const ALL_ICONS_BY_PATH = new Map<EIconPath, Component>([
  /**
   * Icons
   */
  [EIconPath.ActionAttachSvg, ActionAttachSvg],
  [EIconPath.ActionCartMiniSvg, ActionCartMiniSvg],
  [EIconPath.ActionCartSvg, ActionCartSvg],
  [EIconPath.ActionCatalogSvg, ActionCatalog],
  [EIconPath.ActionCloseSvg, ActionCloseSvg],
  [EIconPath.ActionComment24PxSvg, ActionComment24PxSvg],
  [EIconPath.ActionCommentReview24PxSvg, ActionCommentReview24PxSvg],
  [EIconPath.ActionCommentReviewSvg, ActionCommentReviewSvg],
  [EIconPath.ActionCommentSvg, ActionCommentSvg],
  [EIconPath.ActionCompanySvg, ActionCompanySvg],
  [EIconPath.ActionCompareSvg, ActionCompareSvg],
  [EIconPath.ActionDateSvg, ActionDateSvg],
  [EIconPath.ActionDownloadSvg, ActionDownloadSvg],
  [EIconPath.ActionDownloadYellowSvg, ActionDownloadYellowSvg],
  [EIconPath.ActionDeliverySvg, ActionDeliverySvg],
  [EIconPath.ActionEyeSvg, ActionEyeSvg],
  [EIconPath.ActionFavouritesSvg, ActionFavouritesSvg],
  [EIconPath.ActionFileErrorSvg, ActionFileErrorSvg],
  [EIconPath.ActionGalleryViewSvg, ActionGalleryViewSvg],
  [EIconPath.ActionGluingSvg, ActionGluingSvg],
  [EIconPath.ActionIconMinusDarkSvg, ActionIconMinusDarkSvg],
  [EIconPath.ActionIconMinusSvg, ActionIconMinusSvg],
  [EIconPath.ActionIconPlusDarkSvg, ActionIconPlusDarkSvg],
  [EIconPath.ActionIconPlusSvg, ActionIconPlusSvg],
  [EIconPath.ActionInfoSvg, ActionInfoSvg],
  [EIconPath.ActionLinkSvg, ActionLinkSvg],
  [EIconPath.ActionLimitsSvg, ActionLimitsSvg],
  [EIconPath.ActionListingViewMiniSvg, ActionListingViewMiniSvg],
  [EIconPath.ActionListingViewSvg, ActionListingViewSvg],
  [EIconPath.ActionCopySvg, ActionCopySvg],
  [EIconPath.ActionMinusSvg, ActionMinusSvg],
  [EIconPath.ActionModerationSvg, ActionModerationSvg],
  [EIconPath.ActionMoreDotsSvg, ActionMoreDotsSvg],
  [EIconPath.ActionOrderSvg, ActionOrderSvg],
  [EIconPath.ActionPauseSvg, ActionPauseSvg],
  [EIconPath.ActionPdfPerforatedSvg, ActionPdfPerforatedSvg],
  [EIconPath.ActionPdfSvg, ActionPdfSvg],
  [EIconPath.ActionPenEditSvg, ActionPenEditSvg],
  [EIconPath.ActionPlusSvg, ActionPlusSvg],
  [EIconPath.ActionPriceRangeSvg, ActionPriceRangeSvg],
  [EIconPath.ActionPrintSvg, ActionPrintSvg],
  [EIconPath.ActionRangeSvg, ActionRangeSvg],
  [EIconPath.ActionRepeatSvg, ActionRepeatSvg],
  [EIconPath.ActionReportSvg, ActionReportSvg],
  [EIconPath.ActionReviewSvg, ActionReviewSvg],
  [EIconPath.ActionSettingsSvg, ActionSettingsSvg],
  [EIconPath.ActionSettings2Svg, ActionSettings2Svg],
  [EIconPath.ActionSpinnerSvg, ActionSpinnerSvg],
  [EIconPath.ActionTaskListSvg, ActionTaskListSvg],
  [EIconPath.ActionQuestionSvg, ActionQuestionSvg],
  [EIconPath.ActionThumbsDownSvg, ActionThumbsDownSvg],
  [EIconPath.ActionThumbsUpSvg, ActionThumbsUpSvg],
  [EIconPath.ActionTrashSvg, ActionTrashSvg],
  [EIconPath.ActionTrashTransparentSvg, ActionTrashTransparentSvg],
  [EIconPath.ActionTransferSvg, ActionTransferSvg],
  [EIconPath.ActionUploadProcessingSvg, ActionUploadProcessingSvg],
  [EIconPath.ActionUploadSvg, ActionUploadSvg],
  [EIconPath.ActionZoomInSvg, ActionZoomInSvg],
  [EIconPath.ActionVideoWhiteSvg, ActionVideoWhiteSvg],
  [EIconPath.ActionVideoGradientSvg, ActionVideoGradientSvg],
  [EIconPath.ActionNotificationSvg, ActionNotificationSvg],
  [EIconPath.ActionTimeSvg, ActionTimeSvg],
  [EIconPath.ActionTimeAccentSvg, ActionTimeAccentSvg],
  [EIconPath.ActionMagicSvg, ActionMagicSvg],
  [EIconPath.DragHandler, DragHandlerSvg],
  [EIconPath.ActionUpdateSvg, ActionUpdateSvg],
  [EIconPath.IndicatorsBestPriceSvg, IndicatorsBestPriceSvg],
  [EIconPath.IndicatorsBulletDotSvg, IndicatorsBulletDotSvg],
  [EIconPath.IndicatorsBulletNumberSvg, IndicatorsBulletNumberSvg],
  [EIconPath.IndicatorsCartItemsSvg, IndicatorsCartItemsSvg],
  [EIconPath.IndicatorsCheckmarkRoundErrorSvg, IndicatorsCheckmarkRoundErrorSvg],
  [EIconPath.IndicatorsCheckmarkRoundFileSvg, IndicatorsCheckmarkRoundFileSvg],
  [EIconPath.IndicatorsCheckmarkRoundWatchSvg, IndicatorsCheckmarkRoundWatchSvg],
  [EIconPath.IndicatorsCheckmarkRoundDarkSvg, IndicatorsCheckmarkRoundDarkSvg],
  [EIconPath.IndicatorsCheckmarkRoundSvg, IndicatorsCheckmarkRoundSvg],
  [EIconPath.IndicatorsCheckmarkWarnSvg, IndicatorsCheckmarkWarnSvg],
  [EIconPath.IndicatorsCheckmarkSimpleSvg, IndicatorsCheckmarkSimpleSvg],
  [EIconPath.IndicatorsCheckmarkSimpleAccentSvg, IndicatorsCheckmarkSimpleAccentSvg],
  [EIconPath.IndicatorsFastShippingSvg, IndicatorsFastShippingSvg],
  [EIconPath.IndicatorsInfoDarkSvg, IndicatorsInfoDarkSvg],
  [EIconPath.IndicatorsInfoOutlineSvg, IndicatorsInfoOutlineSvg],
  [EIconPath.IndicatorsProgressSvg, IndicatorsProgressSvg],
  [EIconPath.IndicatorsProgressDarkSvg, IndicatorsProgressDarkSvg],
  [EIconPath.IndicatorsProgressLightSvg, IndicatorsProgressLightSvg],
  [EIconPath.IndicatorsStarSvg, IndicatorsStarSvg],
  [EIconPath.IndicatorsStepCloseSvg, IndicatorsStepCloseSvg],
  [EIconPath.IndicatorsWarningSvg, IndicatorsWarningSvg],
  [EIconPath.NavigationArrowDown20PxSvg, NavigationArrowDown20PxSvg],
  [EIconPath.NavigationArrowDownSmallSvg, NavigationArrowDownSmallSvg],
  [EIconPath.NavigationArrowDownSvg, NavigationArrowDownSvg],
  [EIconPath.NavigationArrowLeft20PxSvg, NavigationArrowLeft20PxSvg],
  [EIconPath.NavigationArrowLeftSmallSvg, NavigationArrowLeftSmallSvg],
  [EIconPath.NavigationArrowLeftSvg, NavigationArrowLeftSvg],
  [EIconPath.NavigationArrowRight20PxSvg, NavigationArrowRight20PxSvg],
  [EIconPath.NavigationArrowRightSmallSvg, NavigationArrowRightSmallSvg],
  [EIconPath.NavigationArrowRightSvg, NavigationArrowRightSvg],
  [EIconPath.NavigationArrowTop20PxSvg, NavigationArrowTop20PxSvg],
  [EIconPath.NavigationArrowUpSmallSvg, NavigationArrowUpSmallSvg],
  [EIconPath.NavigationArrowUpSvg, NavigationArrowUpSvg],
  [EIconPath.NavigationClockSvg, NavigationClockSvg],
  [EIconPath.NavigationClose20PxSvg, NavigationClose20PxSvg],
  [EIconPath.NavigationClose24PxSvg, NavigationClose24PxSvg],
  [EIconPath.NavigationClose36PxSvg, NavigationClose36PxSvg],
  [EIconPath.NavigationCloseRed20PxSvg, NavigationCloseRed20PxSvg],
  [EIconPath.NavigationFilterNewSvg, NavigationFilterNewSvg],
  [EIconPath.NavigationFilterSvg, NavigationFilterSvg],
  [EIconPath.NavigationHelpSvg, NavigationHelpSvg],
  [EIconPath.NavigationHomeSvg, NavigationHomeSvg],
  [EIconPath.NavigationInfo, NavigationInfo],
  [EIconPath.NavigationInfoFillSvg, NavigationInfoFillSvg],
  [EIconPath.NavigationInfoFill16Svg, NavigationInfoFill16Svg],
  [EIconPath.NavigationInfoOrangeSvg, NavigationInfoOrangeSvg],
  [EIconPath.NavigationInfoGreenSvg, NavigationInfoGreenSvg],
  [EIconPath.NavigationInfoRedSvg, NavigationInfoRedSvg],
  [EIconPath.NavigationInfoOutlineSvg, NavigationInfoOutlineSvg],
  [EIconPath.NavigationInfoYellowSvg, NavigationInfoYellowSvg],
  [EIconPath.NavigationLkSvg, NavigationLkSvg],
  [EIconPath.NavigationLocationSvg, NavigationLocationSvg],
  [EIconPath.NavigationLoginSvg, NavigationLoginSvg],
  [EIconPath.NavigationLogin20pxSvg, NavigationLogin20pxSvg],
  [EIconPath.NavigationMainArrowSvg, NavigationMainArrowSvg],
  [EIconPath.NavigationMenuSvg, NavigationMenuSvg],
  [EIconPath.NavigationSearchSvg, NavigationSearchSvg],
  [EIconPath.NavigationSmallArrowDownSvg, NavigationSmallArrowDownSvg],
  [EIconPath.NavigationSmallArrowLeftSvg, NavigationSmallArrowLeftSvg],
  [EIconPath.NavigationSmallArrowRightSvg, NavigationSmallArrowRightSvg],
  [EIconPath.NavigationSmallArrowUpSvg, NavigationSmallArrowUpSvg],
  [EIconPath.NavigationSortingSvg, NavigationSortingSvg],
  [EIconPath.NavigationArrowBack, NavigationArrowBack],
  [EIconPath.NavigationArrowLongDown20pxSvg, NavigationArrowLongDown20pxSvg],
  [EIconPath.NavigationOpenNewTabSvg, NavigationOpenNewTabSvg],
  [EIconPath.SocialTelegramLightSvg, SocialTelegramLightSvg],
  [EIconPath.SocialTelegramSvg, SocialTelegramSvg],
  [EIconPath.SocialYoutubeLightSvg, SocialYoutubeLightSvg],
  [EIconPath.SocialYoutubeSvg, SocialYoutubeSvg],
  [EIconPath.SystemHandSvg, SystemHandSvg],
  [EIconPath.TextFormattingAlignSvg, TextFormattingAlignSvg],
  [EIconPath.TextFormattingBackgroundColorSvg, TextFormattingBackgroundColorSvg],
  [EIconPath.TextFormattingBoldSvg, TextFormattingBoldSvg],
  [EIconPath.TextFormattingBulletListSvg, TextFormattingBulletListSvg],
  [EIconPath.TextFormattingColorSvg, TextFormattingColorSvg],
  [EIconPath.TextFormattingHeadingSvg, TextFormattingHeadingSvg],
  [EIconPath.TextFormattingIndentRightSvg, TextFormattingIndentRightSvg],
  [EIconPath.TextFormattingItalicsSvg, TextFormattingItalicsSvg],
  [EIconPath.TextFormattingOrderedListSvg, TextFormattingOrderedListSvg],
  [EIconPath.TextFormattingOutdentSvg, TextFormattingOutdentSvg],
  [EIconPath.TextFormattingPolygonSvg, TextFormattingPolygonSvg],
  [EIconPath.TextFormattingRedoSvg, TextFormattingRedoSvg],
  [EIconPath.TextFormattingStrikeSvg, TextFormattingStrikeSvg],
  [EIconPath.TextFormattingUnderlineSvg, TextFormattingUnderlineSvg],
  [EIconPath.TextFormattingUndoSvg, TextFormattingUndoSvg],
  [EIconPath.LogoDescOffSvg, LogoDescOffSvg],
  [EIconPath.PlaySvg, PlaySvg],
  [EIconPath.PlayInvertedSvg, PlayInvertedSvg],
  [EIconPath.ChevronBackSvg, ChevronBackSvg],
  [EIconPath.LockSvg, LockSvg],
  [EIconPath.UnLockSvg, UnLockSvg],

  /**
   * Images
   */
  [EIconPath.ImagesLogoInvertedDescOn, ImagesLogoInvertedDescOn],
  [EIconPath.ImagesLogoDescOn, ImagesLogoDescOn],
  [EIconPath.ImagesLogoInvertedDescOff, ImagesLogoInvertedDescOff],
  [EIconPath.ImagesLogoDescOff, ImagesLogoDescOff],
  [EIconPath.ImagesLogoIcon, ImagesLogoIcon],
  [EIconPath.ImagesLogoIconInverted, ImagesLogoIconInverted],
]);
