<template>
  <div class="mm-notification-message">
    {{ message }}
    <a
      class="mm-text-undecorated-link"
      @click="onNotificationClick"
    >
      {{ linkMessage }}
    </a>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  // Сообщение
  message: string;
  // Ссылка сообщения
  linkMessage: string;
  // Параметры запроса
  queryParams: Record<string, string>;
}>();

function onNotificationClick() {
  navigateTo({ path: useRoute()?.fullPath, query: props.queryParams });
}
</script>
