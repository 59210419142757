import { ProviderService } from '../models/providerService.model';

export default class SearchService extends ProviderService {
  static readonly serviceName = 'searchService';

  searchText = ref('');
  categoryId = ref('');

  private readonly ociMode = false;

  private static instance: SearchService;

  private constructor(private readonly route = useRoute()) {
    super();
  }

  public static getInstance(): SearchService {
    if (!SearchService.instance) {
      SearchService.instance = new SearchService();
    }

    return SearchService.instance;
  }

  public setOciMode(ociMode: boolean): void {
    this.ociMode = ociMode;
  }

  search(searchText: string, categoryId?: string): void {
    if (!searchText) {
      return;
    }

    this.searchText.value = searchText;
    this.categoryId.value = categoryId;
    navigateTo({
      path: this.getSearchPath(),
      query: {
        ...this.route.query,
        search: searchText,
        categoryId: Number(categoryId) || undefined,
        filter: undefined,
        page: undefined,
      },
    });
  }

  clearSearchText(): void {
    this.searchText.value = '';
  }

  clearCategoryId(): void {
    this.categoryId.value = '';
  }

  setParams(searchText: string | undefined, categoryId: string | undefined): void {
    this.searchText.value = searchText || this.searchText.value;
    this.categoryId.value = categoryId || this.categoryId.value;
  }

  private getSearchPath(): string {
    return `${this.ociMode ? '/oci' : ''}/search`;
  }
}
