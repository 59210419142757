export function getFileNameWithoutExtension(fileName: string): string {
  return fileName.slice(0, fileName.length - getFileExtension(fileName).length) || '-';
}

export function getFileExtension(fileName = '0'): string {
  const splitFileName = fileName?.split('.').reverse();
  return splitFileName?.length > 1
    ? `.${splitFileName[0]}`
    : '';
}
