import { AbortControllerUtil } from 'shared/utils/abortController.util';
import { NitroFetchOptions } from 'nitropack';
import { FORBIDDEN, FORBIDDEN_FILE } from 'shared/constants/errorExceptionsMessage.const';
import { SearchParamsUtils } from 'shared/utils/searchParams.util';
import { FORBIDDEN_ERROR_CODE } from 'shared/constants/error.const';
import { ORDER_UNAVAILABLE } from 'constants/orderAccess.const';
import { SKIP_AUTH_ITEMS } from 'server/consts/skipAuthItems.const';

export class AuthHelper {
  static cookieHeaders = '';
  static accessTokenName = '';

  static async fetch<T>(
    path: string,
    fetchOptions: NitroFetchOptions<string> = {},
    ignoreForbiddenForReload = false,
  ): Promise<T> {
    const signal = AbortControllerUtil.getSignalAbortController();
    try {
      if (!fetchOptions) {
        fetchOptions = {};
      }

      if (!fetchOptions.headers) {
        fetchOptions.headers = {};
      }

      const cookie = this.cookieHeaders;
      if (
        process.server &&
        fetchOptions &&
        (path?.startsWith('/api/v1/products') || !SKIP_AUTH_ITEMS.find((item) => path?.startsWith(item))) &&
        cookie?.includes?.(this.accessTokenName)
      ) {
        fetchOptions.headers['cookie'] = cookie;
      }

      SearchParamsUtils.deleteEmpty(fetchOptions.params);
      return await $fetch(
        path,
        {
          retry: 0,
          ...fetchOptions,
          signal,
          onResponse: async (context) => {
            await fetchOptions?.onResponse?.(context);
            if (process.client) {
              return;
            }

            this.accessTokenFromSetCookieToCookie(context?.response?.headers?.get('set-cookie'));
          },
        },
      );
    } catch (error) {
      if (
        process.client &&
        error?.data?.statusCode === FORBIDDEN_ERROR_CODE &&
        ![FORBIDDEN, ORDER_UNAVAILABLE, FORBIDDEN_FILE].includes(error?.data?.statusMessage) &&
        !ignoreForbiddenForReload
      ) {
        location?.reload(); // for god mode through different browser`s tabs
        return;
      }
      if (signal?.aborted) {
        throw AbortControllerUtil.createAbortControllerError();
      }
      throw error;
    }
  }

  public static accessTokenFromSetCookieToCookie(setCookieHeadersString): void {
    if (!setCookieHeadersString?.trim?.()) {
      return;
    }

    const accessTokenRegexp = `${this.accessTokenName}=[\\d\\w\\.\\-\\_]+`;
    const newAccessToken = setCookieHeadersString?.match(accessTokenRegexp)?.[0];
    if (AuthHelper.cookieHeaders?.match(accessTokenRegexp)?.[0]) {
      AuthHelper.cookieHeaders.replace(new RegExp(accessTokenRegexp), newAccessToken);
      return;
    }

    if (AuthHelper.cookieHeaders?.length > 1) {
      AuthHelper.cookieHeaders += '; ';
    }

    if (newAccessToken) {
      AuthHelper.cookieHeaders = (AuthHelper.cookieHeaders || '') + newAccessToken;
    }
  }
}
