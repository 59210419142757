import { ESignEsdErrorCode } from '../../enums/services/signEsdErrorCode.enum';

export class SignEsdError extends Error {
  name = 'Sign esd error';

  constructor(
    public readonly errorCode: ESignEsdErrorCode,
    public message: string,
    public readonly originalError?: Error,
  ) {
    super(message);
  }
}
