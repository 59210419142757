import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import accept_45cookies_45global from "/app/src/projects/marketplace/middleware/accept-cookies.global.ts";
import route_45global from "/app/src/projects/marketplace/middleware/route.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  accept_45cookies_45global,
  route_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "catalog-auth": () => import("/app/src/projects/marketplace/middleware/catalog-auth.ts"),
  product: () => import("/app/src/projects/marketplace/middleware/product.ts"),
  "supplier-qualification": () => import("/app/src/projects/marketplace/middleware/supplier-qualification.ts"),
  "supplier-settings-admin": () => import("/app/src/projects/marketplace/middleware/supplier-settings-admin.ts"),
  "update-basis": () => import("/app/src/projects/marketplace/middleware/update-basis.ts")
}