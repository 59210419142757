import { initMaksifyPlugins } from '@maksmart/maksify/dist/plugins';
import { ClientOnly } from '#components';
export default defineNuxtPlugin(({ vueApp }) => {
  /**
   * Инициализация плагинов/директив :
   * - vue tippy
   * - sanitize
   *
   * */
  initMaksifyPlugins(vueApp);
  /**
   * Для корректного использования ClientOnly заглушки в библиотеке.
   * Позволяет использовать ClientOnly-заглушку во vue3 библиотеке
   * */
  vueApp.component('ClientOnly', ClientOnly);
});
