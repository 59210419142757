import { default as indexgMyV9CNAdlMeta } from "/app/src/projects/marketplace/pages/[categorySlug]/index.vue?macro=true";
import { default as aboutCbt69wqchOMeta } from "/app/src/projects/marketplace/pages/about.vue?macro=true";
import { default as _91basketId_93vbmCAoUnW3Meta } from "/app/src/projects/marketplace/pages/basket/[basketId].vue?macro=true";
import { default as indexBk4MLtJIA0Meta } from "/app/src/projects/marketplace/pages/basket/index.vue?macro=true";
import { default as indexPaXVOstEr2Meta } from "/app/src/projects/marketplace/pages/categories/index.vue?macro=true";
import { default as indexZHCgX2k1JgMeta } from "/app/src/projects/marketplace/pages/client/~delivery/index.vue?macro=true";
import { default as indexr6Fo8vschLMeta } from "/app/src/projects/marketplace/pages/client/analytics/index.vue?macro=true";
import { default as addresseseYDwmeZn7nMeta } from "/app/src/projects/marketplace/pages/client/company/[id]/addresses.vue?macro=true";
import { default as order_45rules9JGarbLNZ9Meta } from "/app/src/projects/marketplace/pages/client/company/[id]/order-rules.vue?macro=true";
import { default as subdivisionstu5cbYqp5fMeta } from "/app/src/projects/marketplace/pages/client/company/[id]/subdivisions.vue?macro=true";
import { default as indexdulP0QeNj1Meta } from "/app/src/projects/marketplace/pages/client/company/index.vue?macro=true";
import { default as indexvwRBZnpZlzMeta } from "/app/src/projects/marketplace/pages/client/employees/index.vue?macro=true";
import { default as indexhRPaMKI1XEMeta } from "/app/src/projects/marketplace/pages/client/help/index.vue?macro=true";
import { default as listI0Pnqkv9G9Meta } from "/app/src/projects/marketplace/pages/client/limits/index/list.vue?macro=true";
import { default as pfmsgNOIgNMCYKMeta } from "/app/src/projects/marketplace/pages/client/limits/index/pfms.vue?macro=true";
import { default as indexNGaqnbXcxjMeta } from "/app/src/projects/marketplace/pages/client/limits/index.vue?macro=true";
import { default as _91supplierId_93XaBn8Cm1R6Meta } from "/app/src/projects/marketplace/pages/client/orders/[id]/[supplierId].vue?macro=true";
import { default as indexsAdHlFZInwMeta } from "/app/src/projects/marketplace/pages/client/orders/[id]/index.vue?macro=true";
import { default as draftR6NfuwUn36Meta } from "/app/src/projects/marketplace/pages/client/orders/draft.vue?macro=true";
import { default as _91id_93efTyvKsUWPMeta } from "/app/src/projects/marketplace/pages/client/orders/erp/[id].vue?macro=true";
import { default as success_45orderSQyMC5ruSkMeta } from "/app/src/projects/marketplace/pages/client/orders/erp/success-order.vue?macro=true";
import { default as index7MoIvZa862Meta } from "/app/src/projects/marketplace/pages/client/orders/index.vue?macro=true";
import { default as indexi1Iy70O7lXMeta } from "/app/src/projects/marketplace/pages/client/ozm/[categoryId]/index.vue?macro=true";
import { default as indexaoitVpWVPOMeta } from "/app/src/projects/marketplace/pages/client/ozm/index.vue?macro=true";
import { default as index1hsooD3h5gMeta } from "/app/src/projects/marketplace/pages/client/profile/index.vue?macro=true";
import { default as indexq8FpsaQJGWMeta } from "/app/src/projects/marketplace/pages/client/reports/index.vue?macro=true";
import { default as _91type_936wVcUUFjLXMeta } from "/app/src/projects/marketplace/pages/client/shipments/index/[type].vue?macro=true";
import { default as indexujYBkSJKwMMeta } from "/app/src/projects/marketplace/pages/client/shipments/index.vue?macro=true";
import { default as contactXkkEO1QxKRMeta } from "/app/src/projects/marketplace/pages/contact.vue?macro=true";
import { default as indexMza7Yzd35nMeta } from "/app/src/projects/marketplace/pages/index.vue?macro=true";
import { default as main_45pagejwfuqBmmfkMeta } from "/app/src/projects/marketplace/pages/main-page.vue?macro=true";
import { default as _91id_93X6RtP4gh03Meta } from "/app/src/projects/marketplace/pages/news/[id].vue?macro=true";
import { default as indexqLxUHZH1WzMeta } from "/app/src/projects/marketplace/pages/news/index.vue?macro=true";
import { default as indexDNtKn5bMdQMeta } from "/app/src/projects/marketplace/modules/oci/pages/oci/[categorySlug]/index.vue?macro=true";
import { default as indexAaxnUO1lTAMeta } from "/app/src/projects/marketplace/modules/oci/pages/oci/basket/index.vue?macro=true";
import { default as index7ByOwN60EfMeta } from "/app/src/projects/marketplace/modules/oci/pages/oci/categories/index.vue?macro=true";
import { default as _91productSlug_937dlmEzYKiKMeta } from "/app/src/projects/marketplace/modules/oci/pages/oci/product/[productSlug].vue?macro=true";
import { default as search3l6XByXL6QMeta } from "/app/src/projects/marketplace/modules/oci/pages/oci/search.vue?macro=true";
import { default as pokupatelyamz9lMHoDHCDMeta } from "/app/src/projects/marketplace/pages/pokupatelyam.vue?macro=true";
import { default as postavschikamKo7tw6o7GnMeta } from "/app/src/projects/marketplace/pages/postavschikam.vue?macro=true";
import { default as _91productSlug_93ZkshvT2whJMeta } from "/app/src/projects/marketplace/pages/product/[productSlug].vue?macro=true";
import { default as profileQEVuQD67wjMeta } from "/app/src/projects/marketplace/pages/profile.vue?macro=true";
import { default as indexRovtnsefGGMeta } from "/app/src/projects/marketplace/pages/redirect/[action]/index.vue?macro=true";
import { default as test_45getjRTsv895TJMeta } from "/app/src/projects/marketplace/pages/sap/test-get.vue?macro=true";
import { default as test_45postwVA7BMFTdlMeta } from "/app/src/projects/marketplace/pages/sap/test-post.vue?macro=true";
import { default as testjLzz8MNHR7Meta } from "/app/src/projects/marketplace/pages/sap/test.vue?macro=true";
import { default as searchP0jhsFjIKBMeta } from "/app/src/projects/marketplace/pages/search.vue?macro=true";
import { default as indexi8GokoNpGHMeta } from "/app/src/projects/marketplace/pages/subscription/index/index.vue?macro=true";
import { default as successtUZzW6jUu3Meta } from "/app/src/projects/marketplace/pages/subscription/index/success.vue?macro=true";
import { default as indexFjVaDjsc3XMeta } from "/app/src/projects/marketplace/pages/subscription/index.vue?macro=true";
import { default as success_45orderC55U7eO0XcMeta } from "/app/src/projects/marketplace/pages/success-order.vue?macro=true";
import { default as editl01p4VBD2UMeta } from "/app/src/projects/marketplace/pages/supplier/catalogs/[catalogId]/edit.vue?macro=true";
import { default as errorsruhVQnw2WeMeta } from "/app/src/projects/marketplace/pages/supplier/catalogs/[catalogId]/errors.vue?macro=true";
import { default as indexEW2Kf8JvBsMeta } from "/app/src/projects/marketplace/pages/supplier/catalogs/[catalogId]/index.vue?macro=true";
import { default as indexFjzCiULDYJMeta } from "/app/src/projects/marketplace/pages/supplier/catalogs/index.vue?macro=true";
import { default as offernxvluWuGnTMeta } from "/app/src/projects/marketplace/pages/supplier/documents/index/offer.vue?macro=true";
import { default as upd_45registryNFRFxSauh4Meta } from "/app/src/projects/marketplace/pages/supplier/documents/index/upd-registry.vue?macro=true";
import { default as indexVrbMNlxCkpMeta } from "/app/src/projects/marketplace/pages/supplier/documents/index.vue?macro=true";
import { default as needsfqU2dIFPJwMeta } from "/app/src/projects/marketplace/pages/supplier/needs.vue?macro=true";
import { default as indexUBPgxkDdl8Meta } from "/app/src/projects/marketplace/pages/supplier/order/[id]/index.vue?macro=true";
import { default as splitMix4Jyz7O4Meta } from "/app/src/projects/marketplace/pages/supplier/order/[id]/split.vue?macro=true";
import { default as _91id_9316je5emM2HMeta } from "/app/src/projects/marketplace/pages/supplier/order/[id].vue?macro=true";
import { default as orders9EvqhPy1HcMeta } from "/app/src/projects/marketplace/pages/supplier/orders.vue?macro=true";
import { default as indexuohaZ6YUeWMeta } from "/app/src/projects/marketplace/pages/supplier/qualifications/index.vue?macro=true";
import { default as introdB9rxdyTvxMeta } from "/app/src/projects/marketplace/pages/supplier/qualifications/intro.vue?macro=true";
import { default as startW5KwFhBbLUMeta } from "/app/src/projects/marketplace/pages/supplier/qualifications/start.vue?macro=true";
import { default as indexaAXoCJGilAMeta } from "/app/src/projects/marketplace/pages/supplier/reports/index.vue?macro=true";
import { default as bank_45details351EUigO6JMeta } from "/app/src/projects/marketplace/pages/supplier/settings/index/bank-details.vue?macro=true";
import { default as employeesjahmicIzfGMeta } from "/app/src/projects/marketplace/pages/supplier/settings/index/employees.vue?macro=true";
import { default as change_45passwordtYKHhk7G2iMeta } from "/app/src/projects/marketplace/pages/supplier/settings/index/index/change-password.vue?macro=true";
import { default as index2ztbbJW7n9Meta } from "/app/src/projects/marketplace/pages/supplier/settings/index/index/index.vue?macro=true";
import { default as indexpaSxY9L2PVMeta } from "/app/src/projects/marketplace/pages/supplier/settings/index/index.vue?macro=true";
import { default as order_45rulesiK4QYSGZG5Meta } from "/app/src/projects/marketplace/pages/supplier/settings/index/order-rules.vue?macro=true";
import { default as indexxcsz65IGj2Meta } from "/app/src/projects/marketplace/pages/supplier/settings/index.vue?macro=true";
import { default as _91shipmentId_93TbHvRHf9YtMeta } from "/app/src/projects/marketplace/pages/supplier/shipments/[shipmentId].vue?macro=true";
import { default as indexy8FXdJzAyTMeta } from "/app/src/projects/marketplace/pages/supplier/shipments/index.vue?macro=true";
import { default as brandslPaHIOMOVQMeta } from "/app/src/shared/pages/ui-kit/components/brands.vue?macro=true";
import { default as category_45cardJwybzgSZxyMeta } from "/app/src/shared/pages/ui-kit/components/category-card.vue?macro=true";
import { default as choicenYoH2o3SBWMeta } from "/app/src/shared/pages/ui-kit/components/choice.vue?macro=true";
import { default as data_45import_45sourceTuQGTEUyIqMeta } from "/app/src/shared/pages/ui-kit/components/data-import-source.vue?macro=true";
import { default as drawerAKDjh2uw4DMeta } from "/app/src/shared/pages/ui-kit/components/drawer.vue?macro=true";
import { default as errorrsDYfjd7HXMeta } from "/app/src/shared/pages/ui-kit/components/error.vue?macro=true";
import { default as expansion_45panelqdZGbLSQqFMeta } from "/app/src/shared/pages/ui-kit/components/expansion-panel.vue?macro=true";
import { default as filesNtvBKFnyAwMeta } from "/app/src/shared/pages/ui-kit/components/files.vue?macro=true";
import { default as filtersdj27YZCG6tMeta } from "/app/src/shared/pages/ui-kit/components/filters.vue?macro=true";
import { default as imageseNtZ75YRu1Meta } from "/app/src/shared/pages/ui-kit/components/images.vue?macro=true";
import { default as info_45frameOd7b83Ht5aMeta } from "/app/src/shared/pages/ui-kit/components/info-frame.vue?macro=true";
import { default as modalsVDY2WqYF3cMeta } from "/app/src/shared/pages/ui-kit/components/modals.vue?macro=true";
import { default as notification_45messageIN1RpCE80mMeta } from "/app/src/shared/pages/ui-kit/components/notification-message.vue?macro=true";
import { default as pagination5qBo2ao9l7Meta } from "/app/src/shared/pages/ui-kit/components/pagination.vue?macro=true";
import { default as productUkYHZaVRrrMeta } from "/app/src/shared/pages/ui-kit/components/product.vue?macro=true";
import { default as recaptchamZmQ4nwyrpMeta } from "/app/src/shared/pages/ui-kit/components/recaptcha.vue?macro=true";
import { default as scrolling_45listyjlzx1Ai5uMeta } from "/app/src/shared/pages/ui-kit/components/scrolling-list.vue?macro=true";
import { default as select4QfjXxikpxMeta } from "/app/src/shared/pages/ui-kit/components/select.vue?macro=true";
import { default as tablesRz50vkaooDMeta } from "/app/src/shared/pages/ui-kit/components/tables.vue?macro=true";
import { default as toggle_45switche92NyiDZj2Meta } from "/app/src/shared/pages/ui-kit/components/toggle-switch.vue?macro=true";
import { default as treeviewIApL8eqL9BMeta } from "/app/src/shared/pages/ui-kit/components/treeview.vue?macro=true";
import { default as componentsM0212bhKHNMeta } from "/app/src/shared/pages/ui-kit/components.vue?macro=true";
import { default as actionsRBmSU0HJhtMeta } from "/app/src/shared/pages/ui-kit/controls/actions.vue?macro=true";
import { default as breadcrumbsOqDJPc3koFMeta } from "/app/src/shared/pages/ui-kit/controls/breadcrumbs.vue?macro=true";
import { default as checkboxes7D9A4mo8k8Meta } from "/app/src/shared/pages/ui-kit/controls/checkboxes.vue?macro=true";
import { default as radiogroupo5uuyYIKvLMeta } from "/app/src/shared/pages/ui-kit/controls/radiogroup.vue?macro=true";
import { default as controlsObPzpvOxtuMeta } from "/app/src/shared/pages/ui-kit/controls.vue?macro=true";
import { default as indexNqBcMTitwtMeta } from "/app/src/shared/pages/ui-kit/index.vue?macro=true";
import { default as date_45pickercDrP1cJpc2Meta } from "/app/src/shared/pages/ui-kit/inputs/date-picker.vue?macro=true";
import { default as file_45uploaderUsnubXngQkMeta } from "/app/src/shared/pages/ui-kit/inputs/file-uploader.vue?macro=true";
import { default as othereEc89QxPEIMeta } from "/app/src/shared/pages/ui-kit/inputs/other.vue?macro=true";
import { default as text_45editorPycMbcjOMPMeta } from "/app/src/shared/pages/ui-kit/inputs/text-editor.vue?macro=true";
import { default as vee_45validatecwAE7wO4dgMeta } from "/app/src/shared/pages/ui-kit/inputs/vee-validate.vue?macro=true";
import { default as inputsQYeuGv6XyiMeta } from "/app/src/shared/pages/ui-kit/inputs.vue?macro=true";
import { default as bannersH8yWFA4paJMeta } from "/app/src/shared/pages/ui-kit/visuals/banners.vue?macro=true";
import { default as buttons1tUMAYiOxXMeta } from "/app/src/shared/pages/ui-kit/visuals/buttons.vue?macro=true";
import { default as iconsc0AdboYmzkMeta } from "/app/src/shared/pages/ui-kit/visuals/icons.vue?macro=true";
import { default as informationtipTZlk6l2E4sMeta } from "/app/src/shared/pages/ui-kit/visuals/informationtip.vue?macro=true";
import { default as loadernEigWrh2qMMeta } from "/app/src/shared/pages/ui-kit/visuals/loader.vue?macro=true";
import { default as rulesGzb8tVdlkpMeta } from "/app/src/shared/pages/ui-kit/visuals/rules.vue?macro=true";
import { default as scrollbaraHGzJ0Lc9oMeta } from "/app/src/shared/pages/ui-kit/visuals/scrollbar.vue?macro=true";
import { default as skeletonIAPvi21f8uMeta } from "/app/src/shared/pages/ui-kit/visuals/skeleton.vue?macro=true";
import { default as statusblockUnM7vwqlGHMeta } from "/app/src/shared/pages/ui-kit/visuals/statusblock.vue?macro=true";
import { default as stylesRj4fp9guEaMeta } from "/app/src/shared/pages/ui-kit/visuals/styles.vue?macro=true";
import { default as tabsEdAN1DBAXEMeta } from "/app/src/shared/pages/ui-kit/visuals/tabs.vue?macro=true";
import { default as visualsGeOSWCyFraMeta } from "/app/src/shared/pages/ui-kit/visuals.vue?macro=true";
import { default as ui_45kitUoIOLOltwdMeta } from "/app/src/shared/pages/ui-kit.vue?macro=true";
export default [
  {
    name: indexgMyV9CNAdlMeta?.name ?? "categorySlug",
    path: indexgMyV9CNAdlMeta?.path ?? "/:categorySlug()",
    meta: indexgMyV9CNAdlMeta || {},
    alias: indexgMyV9CNAdlMeta?.alias || [],
    redirect: indexgMyV9CNAdlMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/[categorySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: aboutCbt69wqchOMeta?.name ?? "about",
    path: aboutCbt69wqchOMeta?.path ?? "/about",
    meta: aboutCbt69wqchOMeta || {},
    alias: aboutCbt69wqchOMeta?.alias || [],
    redirect: aboutCbt69wqchOMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91basketId_93vbmCAoUnW3Meta?.name ?? "basket-basketId",
    path: _91basketId_93vbmCAoUnW3Meta?.path ?? "/basket/:basketId()",
    meta: _91basketId_93vbmCAoUnW3Meta || {},
    alias: _91basketId_93vbmCAoUnW3Meta?.alias || [],
    redirect: _91basketId_93vbmCAoUnW3Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/basket/[basketId].vue").then(m => m.default || m)
  },
  {
    name: indexBk4MLtJIA0Meta?.name ?? "basket",
    path: indexBk4MLtJIA0Meta?.path ?? "/basket",
    meta: indexBk4MLtJIA0Meta || {},
    alias: indexBk4MLtJIA0Meta?.alias || [],
    redirect: indexBk4MLtJIA0Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/basket/index.vue").then(m => m.default || m)
  },
  {
    name: indexPaXVOstEr2Meta?.name ?? "categories",
    path: indexPaXVOstEr2Meta?.path ?? "/categories",
    meta: indexPaXVOstEr2Meta || {},
    alias: indexPaXVOstEr2Meta?.alias || [],
    redirect: indexPaXVOstEr2Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexZHCgX2k1JgMeta?.name ?? "client-~delivery",
    path: indexZHCgX2k1JgMeta?.path ?? "/client/~delivery",
    meta: indexZHCgX2k1JgMeta || {},
    alias: indexZHCgX2k1JgMeta?.alias || [],
    redirect: indexZHCgX2k1JgMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/~delivery/index.vue").then(m => m.default || m)
  },
  {
    name: indexr6Fo8vschLMeta?.name ?? "client-analytics",
    path: indexr6Fo8vschLMeta?.path ?? "/client/analytics",
    meta: indexr6Fo8vschLMeta || {},
    alias: indexr6Fo8vschLMeta?.alias || [],
    redirect: indexr6Fo8vschLMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: addresseseYDwmeZn7nMeta?.name ?? "client-company-id-addresses",
    path: addresseseYDwmeZn7nMeta?.path ?? "/client/company/:id()/addresses",
    meta: addresseseYDwmeZn7nMeta || {},
    alias: addresseseYDwmeZn7nMeta?.alias || [],
    redirect: addresseseYDwmeZn7nMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/company/[id]/addresses.vue").then(m => m.default || m)
  },
  {
    name: order_45rules9JGarbLNZ9Meta?.name ?? "client-company-id-order-rules",
    path: order_45rules9JGarbLNZ9Meta?.path ?? "/client/company/:id()/order-rules",
    meta: order_45rules9JGarbLNZ9Meta || {},
    alias: order_45rules9JGarbLNZ9Meta?.alias || [],
    redirect: order_45rules9JGarbLNZ9Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/company/[id]/order-rules.vue").then(m => m.default || m)
  },
  {
    name: subdivisionstu5cbYqp5fMeta?.name ?? "client-company-id-subdivisions",
    path: subdivisionstu5cbYqp5fMeta?.path ?? "/client/company/:id()/subdivisions",
    meta: subdivisionstu5cbYqp5fMeta || {},
    alias: subdivisionstu5cbYqp5fMeta?.alias || [],
    redirect: subdivisionstu5cbYqp5fMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/company/[id]/subdivisions.vue").then(m => m.default || m)
  },
  {
    name: indexdulP0QeNj1Meta?.name ?? "client-company",
    path: indexdulP0QeNj1Meta?.path ?? "/client/company",
    meta: indexdulP0QeNj1Meta || {},
    alias: indexdulP0QeNj1Meta?.alias || [],
    redirect: indexdulP0QeNj1Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexvwRBZnpZlzMeta?.name ?? "client-employees",
    path: indexvwRBZnpZlzMeta?.path ?? "/client/employees",
    meta: indexvwRBZnpZlzMeta || {},
    alias: indexvwRBZnpZlzMeta?.alias || [],
    redirect: indexvwRBZnpZlzMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/employees/index.vue").then(m => m.default || m)
  },
  {
    name: indexhRPaMKI1XEMeta?.name ?? "client-help",
    path: indexhRPaMKI1XEMeta?.path ?? "/client/help",
    meta: indexhRPaMKI1XEMeta || {},
    alias: indexhRPaMKI1XEMeta?.alias || [],
    redirect: indexhRPaMKI1XEMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexNGaqnbXcxjMeta?.name ?? "client-limits",
    path: indexNGaqnbXcxjMeta?.path ?? "/client/limits",
    children: [
  {
    name: listI0Pnqkv9G9Meta?.name ?? "client-limits-index-list",
    path: listI0Pnqkv9G9Meta?.path ?? "list",
    meta: listI0Pnqkv9G9Meta || {},
    alias: listI0Pnqkv9G9Meta?.alias || [],
    redirect: listI0Pnqkv9G9Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/limits/index/list.vue").then(m => m.default || m)
  },
  {
    name: pfmsgNOIgNMCYKMeta?.name ?? "client-limits-index-pfms",
    path: pfmsgNOIgNMCYKMeta?.path ?? "pfms",
    meta: pfmsgNOIgNMCYKMeta || {},
    alias: pfmsgNOIgNMCYKMeta?.alias || [],
    redirect: pfmsgNOIgNMCYKMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/limits/index/pfms.vue").then(m => m.default || m)
  }
],
    meta: indexNGaqnbXcxjMeta || {},
    alias: indexNGaqnbXcxjMeta?.alias || [],
    redirect: indexNGaqnbXcxjMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/limits/index.vue").then(m => m.default || m)
  },
  {
    name: _91supplierId_93XaBn8Cm1R6Meta?.name ?? "client-orders-id-supplierId",
    path: _91supplierId_93XaBn8Cm1R6Meta?.path ?? "/client/orders/:id()/:supplierId()",
    meta: _91supplierId_93XaBn8Cm1R6Meta || {},
    alias: _91supplierId_93XaBn8Cm1R6Meta?.alias || [],
    redirect: _91supplierId_93XaBn8Cm1R6Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/orders/[id]/[supplierId].vue").then(m => m.default || m)
  },
  {
    name: indexsAdHlFZInwMeta?.name ?? "client-orders-id",
    path: indexsAdHlFZInwMeta?.path ?? "/client/orders/:id()",
    meta: indexsAdHlFZInwMeta || {},
    alias: indexsAdHlFZInwMeta?.alias || [],
    redirect: indexsAdHlFZInwMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: draftR6NfuwUn36Meta?.name ?? "client-orders-draft",
    path: draftR6NfuwUn36Meta?.path ?? "/client/orders/draft",
    meta: draftR6NfuwUn36Meta || {},
    alias: draftR6NfuwUn36Meta?.alias || [],
    redirect: draftR6NfuwUn36Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/orders/draft.vue").then(m => m.default || m)
  },
  {
    name: _91id_93efTyvKsUWPMeta?.name ?? "client-orders-erp-id",
    path: _91id_93efTyvKsUWPMeta?.path ?? "/client/orders/erp/:id()",
    meta: _91id_93efTyvKsUWPMeta || {},
    alias: _91id_93efTyvKsUWPMeta?.alias || [],
    redirect: _91id_93efTyvKsUWPMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/orders/erp/[id].vue").then(m => m.default || m)
  },
  {
    name: success_45orderSQyMC5ruSkMeta?.name ?? "client-orders-erp-success-order",
    path: success_45orderSQyMC5ruSkMeta?.path ?? "/client/orders/erp/success-order",
    meta: success_45orderSQyMC5ruSkMeta || {},
    alias: success_45orderSQyMC5ruSkMeta?.alias || [],
    redirect: success_45orderSQyMC5ruSkMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/orders/erp/success-order.vue").then(m => m.default || m)
  },
  {
    name: index7MoIvZa862Meta?.name ?? "client-orders",
    path: index7MoIvZa862Meta?.path ?? "/client/orders",
    meta: index7MoIvZa862Meta || {},
    alias: index7MoIvZa862Meta?.alias || [],
    redirect: index7MoIvZa862Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1Iy70O7lXMeta?.name ?? "client-ozm-categoryId",
    path: indexi1Iy70O7lXMeta?.path ?? "/client/ozm/:categoryId()",
    meta: indexi1Iy70O7lXMeta || {},
    alias: indexi1Iy70O7lXMeta?.alias || [],
    redirect: indexi1Iy70O7lXMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/ozm/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexaoitVpWVPOMeta?.name ?? "client-ozm",
    path: indexaoitVpWVPOMeta?.path ?? "/client/ozm",
    meta: indexaoitVpWVPOMeta || {},
    alias: indexaoitVpWVPOMeta?.alias || [],
    redirect: indexaoitVpWVPOMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/ozm/index.vue").then(m => m.default || m)
  },
  {
    name: index1hsooD3h5gMeta?.name ?? "client-profile",
    path: index1hsooD3h5gMeta?.path ?? "/client/profile",
    meta: index1hsooD3h5gMeta || {},
    alias: index1hsooD3h5gMeta?.alias || [],
    redirect: index1hsooD3h5gMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexq8FpsaQJGWMeta?.name ?? "client-reports",
    path: indexq8FpsaQJGWMeta?.path ?? "/client/reports",
    meta: indexq8FpsaQJGWMeta || {},
    alias: indexq8FpsaQJGWMeta?.alias || [],
    redirect: indexq8FpsaQJGWMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexujYBkSJKwMMeta?.name ?? "client-shipments",
    path: indexujYBkSJKwMMeta?.path ?? "/client/shipments",
    children: [
  {
    name: _91type_936wVcUUFjLXMeta?.name ?? "client-shipments-index-type",
    path: _91type_936wVcUUFjLXMeta?.path ?? ":type()",
    meta: _91type_936wVcUUFjLXMeta || {},
    alias: _91type_936wVcUUFjLXMeta?.alias || [],
    redirect: _91type_936wVcUUFjLXMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/shipments/index/[type].vue").then(m => m.default || m)
  }
],
    meta: indexujYBkSJKwMMeta || {},
    alias: indexujYBkSJKwMMeta?.alias || [],
    redirect: indexujYBkSJKwMMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/client/shipments/index.vue").then(m => m.default || m)
  },
  {
    name: contactXkkEO1QxKRMeta?.name ?? "contact",
    path: contactXkkEO1QxKRMeta?.path ?? "/contact",
    meta: contactXkkEO1QxKRMeta || {},
    alias: contactXkkEO1QxKRMeta?.alias || [],
    redirect: contactXkkEO1QxKRMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexMza7Yzd35nMeta?.name ?? "index",
    path: indexMza7Yzd35nMeta?.path ?? "/",
    meta: indexMza7Yzd35nMeta || {},
    alias: indexMza7Yzd35nMeta?.alias || [],
    redirect: indexMza7Yzd35nMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: main_45pagejwfuqBmmfkMeta?.name ?? "main-page",
    path: main_45pagejwfuqBmmfkMeta?.path ?? "/main-page",
    meta: main_45pagejwfuqBmmfkMeta || {},
    alias: main_45pagejwfuqBmmfkMeta?.alias || [],
    redirect: main_45pagejwfuqBmmfkMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/main-page.vue").then(m => m.default || m)
  },
  {
    name: _91id_93X6RtP4gh03Meta?.name ?? "news-id",
    path: _91id_93X6RtP4gh03Meta?.path ?? "/news/:id()",
    meta: _91id_93X6RtP4gh03Meta || {},
    alias: _91id_93X6RtP4gh03Meta?.alias || [],
    redirect: _91id_93X6RtP4gh03Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: indexqLxUHZH1WzMeta?.name ?? "news",
    path: indexqLxUHZH1WzMeta?.path ?? "/news",
    meta: indexqLxUHZH1WzMeta || {},
    alias: indexqLxUHZH1WzMeta?.alias || [],
    redirect: indexqLxUHZH1WzMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexDNtKn5bMdQMeta?.name ?? "oci-categorySlug",
    path: indexDNtKn5bMdQMeta?.path ?? "/oci/:categorySlug()",
    meta: indexDNtKn5bMdQMeta || {},
    alias: indexDNtKn5bMdQMeta?.alias || [],
    redirect: indexDNtKn5bMdQMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/modules/oci/pages/oci/[categorySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAaxnUO1lTAMeta?.name ?? "oci-basket",
    path: indexAaxnUO1lTAMeta?.path ?? "/oci/basket",
    meta: indexAaxnUO1lTAMeta || {},
    alias: indexAaxnUO1lTAMeta?.alias || [],
    redirect: indexAaxnUO1lTAMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/modules/oci/pages/oci/basket/index.vue").then(m => m.default || m)
  },
  {
    name: index7ByOwN60EfMeta?.name ?? "oci-categories",
    path: index7ByOwN60EfMeta?.path ?? "/oci/categories",
    meta: index7ByOwN60EfMeta || {},
    alias: index7ByOwN60EfMeta?.alias || [],
    redirect: index7ByOwN60EfMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/modules/oci/pages/oci/categories/index.vue").then(m => m.default || m)
  },
  {
    name: _91productSlug_937dlmEzYKiKMeta?.name ?? "oci-product-productSlug",
    path: _91productSlug_937dlmEzYKiKMeta?.path ?? "/oci/product/:productSlug()",
    meta: _91productSlug_937dlmEzYKiKMeta || {},
    alias: _91productSlug_937dlmEzYKiKMeta?.alias || [],
    redirect: _91productSlug_937dlmEzYKiKMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/modules/oci/pages/oci/product/[productSlug].vue").then(m => m.default || m)
  },
  {
    name: search3l6XByXL6QMeta?.name ?? "oci-search",
    path: search3l6XByXL6QMeta?.path ?? "/oci/search",
    meta: search3l6XByXL6QMeta || {},
    alias: search3l6XByXL6QMeta?.alias || [],
    redirect: search3l6XByXL6QMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/modules/oci/pages/oci/search.vue").then(m => m.default || m)
  },
  {
    name: pokupatelyamz9lMHoDHCDMeta?.name ?? "pokupatelyam",
    path: pokupatelyamz9lMHoDHCDMeta?.path ?? "/pokupatelyam",
    meta: pokupatelyamz9lMHoDHCDMeta || {},
    alias: pokupatelyamz9lMHoDHCDMeta?.alias || [],
    redirect: pokupatelyamz9lMHoDHCDMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/pokupatelyam.vue").then(m => m.default || m)
  },
  {
    name: postavschikamKo7tw6o7GnMeta?.name ?? "postavschikam",
    path: postavschikamKo7tw6o7GnMeta?.path ?? "/postavschikam",
    meta: postavschikamKo7tw6o7GnMeta || {},
    alias: postavschikamKo7tw6o7GnMeta?.alias || [],
    redirect: postavschikamKo7tw6o7GnMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/postavschikam.vue").then(m => m.default || m)
  },
  {
    name: _91productSlug_93ZkshvT2whJMeta?.name ?? "product-productSlug",
    path: _91productSlug_93ZkshvT2whJMeta?.path ?? "/product/:productSlug()",
    meta: _91productSlug_93ZkshvT2whJMeta || {},
    alias: _91productSlug_93ZkshvT2whJMeta?.alias || [],
    redirect: _91productSlug_93ZkshvT2whJMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/product/[productSlug].vue").then(m => m.default || m)
  },
  {
    name: profileQEVuQD67wjMeta?.name ?? "profile",
    path: profileQEVuQD67wjMeta?.path ?? "/profile",
    meta: profileQEVuQD67wjMeta || {},
    alias: profileQEVuQD67wjMeta?.alias || [],
    redirect: profileQEVuQD67wjMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: indexRovtnsefGGMeta?.name ?? "redirect-action",
    path: indexRovtnsefGGMeta?.path ?? "/redirect/:action()",
    meta: indexRovtnsefGGMeta || {},
    alias: indexRovtnsefGGMeta?.alias || [],
    redirect: indexRovtnsefGGMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/redirect/[action]/index.vue").then(m => m.default || m)
  },
  {
    name: test_45getjRTsv895TJMeta?.name ?? "sap-test-get",
    path: test_45getjRTsv895TJMeta?.path ?? "/sap/test-get",
    meta: test_45getjRTsv895TJMeta || {},
    alias: test_45getjRTsv895TJMeta?.alias || [],
    redirect: test_45getjRTsv895TJMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/sap/test-get.vue").then(m => m.default || m)
  },
  {
    name: test_45postwVA7BMFTdlMeta?.name ?? "sap-test-post",
    path: test_45postwVA7BMFTdlMeta?.path ?? "/sap/test-post",
    meta: test_45postwVA7BMFTdlMeta || {},
    alias: test_45postwVA7BMFTdlMeta?.alias || [],
    redirect: test_45postwVA7BMFTdlMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/sap/test-post.vue").then(m => m.default || m)
  },
  {
    name: testjLzz8MNHR7Meta?.name ?? "sap-test",
    path: testjLzz8MNHR7Meta?.path ?? "/sap/test",
    meta: testjLzz8MNHR7Meta || {},
    alias: testjLzz8MNHR7Meta?.alias || [],
    redirect: testjLzz8MNHR7Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/sap/test.vue").then(m => m.default || m)
  },
  {
    name: searchP0jhsFjIKBMeta?.name ?? "search",
    path: searchP0jhsFjIKBMeta?.path ?? "/search",
    meta: searchP0jhsFjIKBMeta || {},
    alias: searchP0jhsFjIKBMeta?.alias || [],
    redirect: searchP0jhsFjIKBMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/search.vue").then(m => m.default || m)
  },
  {
    path: indexFjVaDjsc3XMeta?.path ?? "/subscription",
    children: [
  {
    name: indexi8GokoNpGHMeta?.name ?? "subscription-index",
    path: indexi8GokoNpGHMeta?.path ?? "",
    meta: indexi8GokoNpGHMeta || {},
    alias: indexi8GokoNpGHMeta?.alias || [],
    redirect: indexi8GokoNpGHMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/subscription/index/index.vue").then(m => m.default || m)
  },
  {
    name: successtUZzW6jUu3Meta?.name ?? "subscription-index-success",
    path: successtUZzW6jUu3Meta?.path ?? "success",
    meta: successtUZzW6jUu3Meta || {},
    alias: successtUZzW6jUu3Meta?.alias || [],
    redirect: successtUZzW6jUu3Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/subscription/index/success.vue").then(m => m.default || m)
  }
],
    name: indexFjVaDjsc3XMeta?.name ?? undefined,
    meta: indexFjVaDjsc3XMeta || {},
    alias: indexFjVaDjsc3XMeta?.alias || [],
    redirect: indexFjVaDjsc3XMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: success_45orderC55U7eO0XcMeta?.name ?? "success-order",
    path: success_45orderC55U7eO0XcMeta?.path ?? "/success-order",
    meta: success_45orderC55U7eO0XcMeta || {},
    alias: success_45orderC55U7eO0XcMeta?.alias || [],
    redirect: success_45orderC55U7eO0XcMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/success-order.vue").then(m => m.default || m)
  },
  {
    name: editl01p4VBD2UMeta?.name ?? "supplier-catalogs-catalogId-edit",
    path: editl01p4VBD2UMeta?.path ?? "/supplier/catalogs/:catalogId()/edit",
    meta: editl01p4VBD2UMeta || {},
    alias: editl01p4VBD2UMeta?.alias || [],
    redirect: editl01p4VBD2UMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/catalogs/[catalogId]/edit.vue").then(m => m.default || m)
  },
  {
    name: errorsruhVQnw2WeMeta?.name ?? "supplier-catalogs-catalogId-errors",
    path: errorsruhVQnw2WeMeta?.path ?? "/supplier/catalogs/:catalogId()/errors",
    meta: errorsruhVQnw2WeMeta || {},
    alias: errorsruhVQnw2WeMeta?.alias || [],
    redirect: errorsruhVQnw2WeMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/catalogs/[catalogId]/errors.vue").then(m => m.default || m)
  },
  {
    name: indexEW2Kf8JvBsMeta?.name ?? "supplier-catalogs-catalogId",
    path: indexEW2Kf8JvBsMeta?.path ?? "/supplier/catalogs/:catalogId()",
    meta: indexEW2Kf8JvBsMeta || {},
    alias: indexEW2Kf8JvBsMeta?.alias || [],
    redirect: indexEW2Kf8JvBsMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/catalogs/[catalogId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFjzCiULDYJMeta?.name ?? "supplier-catalogs",
    path: indexFjzCiULDYJMeta?.path ?? "/supplier/catalogs",
    meta: indexFjzCiULDYJMeta || {},
    alias: indexFjzCiULDYJMeta?.alias || [],
    redirect: indexFjzCiULDYJMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/catalogs/index.vue").then(m => m.default || m)
  },
  {
    name: indexVrbMNlxCkpMeta?.name ?? "supplier-documents",
    path: indexVrbMNlxCkpMeta?.path ?? "/supplier/documents",
    children: [
  {
    name: offernxvluWuGnTMeta?.name ?? "supplier-documents-index-offer",
    path: offernxvluWuGnTMeta?.path ?? "offer",
    meta: offernxvluWuGnTMeta || {},
    alias: offernxvluWuGnTMeta?.alias || [],
    redirect: offernxvluWuGnTMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/documents/index/offer.vue").then(m => m.default || m)
  },
  {
    name: upd_45registryNFRFxSauh4Meta?.name ?? "supplier-documents-index-upd-registry",
    path: upd_45registryNFRFxSauh4Meta?.path ?? "upd-registry",
    meta: upd_45registryNFRFxSauh4Meta || {},
    alias: upd_45registryNFRFxSauh4Meta?.alias || [],
    redirect: upd_45registryNFRFxSauh4Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/documents/index/upd-registry.vue").then(m => m.default || m)
  }
],
    meta: indexVrbMNlxCkpMeta || {},
    alias: indexVrbMNlxCkpMeta?.alias || [],
    redirect: indexVrbMNlxCkpMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/documents/index.vue").then(m => m.default || m)
  },
  {
    name: needsfqU2dIFPJwMeta?.name ?? "supplier-needs",
    path: needsfqU2dIFPJwMeta?.path ?? "/supplier/needs",
    meta: needsfqU2dIFPJwMeta || {},
    alias: needsfqU2dIFPJwMeta?.alias || [],
    redirect: needsfqU2dIFPJwMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/needs.vue").then(m => m.default || m)
  },
  {
    path: _91id_9316je5emM2HMeta?.path ?? "/supplier/order/:id()",
    children: [
  {
    name: indexUBPgxkDdl8Meta?.name ?? "supplier-order-id",
    path: indexUBPgxkDdl8Meta?.path ?? "",
    meta: indexUBPgxkDdl8Meta || {},
    alias: indexUBPgxkDdl8Meta?.alias || [],
    redirect: indexUBPgxkDdl8Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/order/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: splitMix4Jyz7O4Meta?.name ?? "supplier-order-id-split",
    path: splitMix4Jyz7O4Meta?.path ?? "split",
    meta: splitMix4Jyz7O4Meta || {},
    alias: splitMix4Jyz7O4Meta?.alias || [],
    redirect: splitMix4Jyz7O4Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/order/[id]/split.vue").then(m => m.default || m)
  }
],
    name: _91id_9316je5emM2HMeta?.name ?? undefined,
    meta: _91id_9316je5emM2HMeta || {},
    alias: _91id_9316je5emM2HMeta?.alias || [],
    redirect: _91id_9316je5emM2HMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/order/[id].vue").then(m => m.default || m)
  },
  {
    name: orders9EvqhPy1HcMeta?.name ?? "supplier-orders",
    path: orders9EvqhPy1HcMeta?.path ?? "/supplier/orders",
    meta: orders9EvqhPy1HcMeta || {},
    alias: orders9EvqhPy1HcMeta?.alias || [],
    redirect: orders9EvqhPy1HcMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/orders.vue").then(m => m.default || m)
  },
  {
    name: indexuohaZ6YUeWMeta?.name ?? "supplier-qualifications",
    path: indexuohaZ6YUeWMeta?.path ?? "/supplier/qualifications",
    meta: indexuohaZ6YUeWMeta || {},
    alias: indexuohaZ6YUeWMeta?.alias || [],
    redirect: indexuohaZ6YUeWMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/qualifications/index.vue").then(m => m.default || m)
  },
  {
    name: introdB9rxdyTvxMeta?.name ?? "supplier-qualifications-intro",
    path: introdB9rxdyTvxMeta?.path ?? "/supplier/qualifications/intro",
    meta: introdB9rxdyTvxMeta || {},
    alias: introdB9rxdyTvxMeta?.alias || [],
    redirect: introdB9rxdyTvxMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/qualifications/intro.vue").then(m => m.default || m)
  },
  {
    name: startW5KwFhBbLUMeta?.name ?? "supplier-qualifications-start",
    path: startW5KwFhBbLUMeta?.path ?? "/supplier/qualifications/start",
    meta: startW5KwFhBbLUMeta || {},
    alias: startW5KwFhBbLUMeta?.alias || [],
    redirect: startW5KwFhBbLUMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/qualifications/start.vue").then(m => m.default || m)
  },
  {
    name: indexaAXoCJGilAMeta?.name ?? "supplier-reports",
    path: indexaAXoCJGilAMeta?.path ?? "/supplier/reports",
    meta: indexaAXoCJGilAMeta || {},
    alias: indexaAXoCJGilAMeta?.alias || [],
    redirect: indexaAXoCJGilAMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/reports/index.vue").then(m => m.default || m)
  },
  {
    path: indexxcsz65IGj2Meta?.path ?? "/supplier/settings",
    children: [
  {
    name: bank_45details351EUigO6JMeta?.name ?? "supplier-settings-index-bank-details",
    path: bank_45details351EUigO6JMeta?.path ?? "bank-details",
    meta: bank_45details351EUigO6JMeta || {},
    alias: bank_45details351EUigO6JMeta?.alias || [],
    redirect: bank_45details351EUigO6JMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/settings/index/bank-details.vue").then(m => m.default || m)
  },
  {
    name: employeesjahmicIzfGMeta?.name ?? "supplier-settings-index-employees",
    path: employeesjahmicIzfGMeta?.path ?? "employees",
    meta: employeesjahmicIzfGMeta || {},
    alias: employeesjahmicIzfGMeta?.alias || [],
    redirect: employeesjahmicIzfGMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/settings/index/employees.vue").then(m => m.default || m)
  },
  {
    children: [
  {
    name: change_45passwordtYKHhk7G2iMeta?.name ?? "supplier-settings-index-index-change-password",
    path: change_45passwordtYKHhk7G2iMeta?.path ?? "change-password",
    meta: change_45passwordtYKHhk7G2iMeta || {},
    alias: change_45passwordtYKHhk7G2iMeta?.alias || [],
    redirect: change_45passwordtYKHhk7G2iMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/settings/index/index/change-password.vue").then(m => m.default || m)
  },
  {
    name: index2ztbbJW7n9Meta?.name ?? "supplier-settings-index-index",
    path: index2ztbbJW7n9Meta?.path ?? "",
    meta: index2ztbbJW7n9Meta || {},
    alias: index2ztbbJW7n9Meta?.alias || [],
    redirect: index2ztbbJW7n9Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/settings/index/index/index.vue").then(m => m.default || m)
  }
],
    name: indexpaSxY9L2PVMeta?.name ?? undefined,
    path: indexpaSxY9L2PVMeta?.path ?? "",
    meta: indexpaSxY9L2PVMeta || {},
    alias: indexpaSxY9L2PVMeta?.alias || [],
    redirect: indexpaSxY9L2PVMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/settings/index/index.vue").then(m => m.default || m)
  },
  {
    name: order_45rulesiK4QYSGZG5Meta?.name ?? "supplier-settings-index-order-rules",
    path: order_45rulesiK4QYSGZG5Meta?.path ?? "order-rules",
    meta: order_45rulesiK4QYSGZG5Meta || {},
    alias: order_45rulesiK4QYSGZG5Meta?.alias || [],
    redirect: order_45rulesiK4QYSGZG5Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/settings/index/order-rules.vue").then(m => m.default || m)
  }
],
    name: indexxcsz65IGj2Meta?.name ?? undefined,
    meta: indexxcsz65IGj2Meta || {},
    alias: indexxcsz65IGj2Meta?.alias || [],
    redirect: indexxcsz65IGj2Meta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/settings/index.vue").then(m => m.default || m)
  },
  {
    name: _91shipmentId_93TbHvRHf9YtMeta?.name ?? "supplier-shipments-shipmentId",
    path: _91shipmentId_93TbHvRHf9YtMeta?.path ?? "/supplier/shipments/:shipmentId()",
    meta: _91shipmentId_93TbHvRHf9YtMeta || {},
    alias: _91shipmentId_93TbHvRHf9YtMeta?.alias || [],
    redirect: _91shipmentId_93TbHvRHf9YtMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/shipments/[shipmentId].vue").then(m => m.default || m)
  },
  {
    name: indexy8FXdJzAyTMeta?.name ?? "supplier-shipments",
    path: indexy8FXdJzAyTMeta?.path ?? "/supplier/shipments",
    meta: indexy8FXdJzAyTMeta || {},
    alias: indexy8FXdJzAyTMeta?.alias || [],
    redirect: indexy8FXdJzAyTMeta?.redirect || undefined,
    component: () => import("/app/src/projects/marketplace/pages/supplier/shipments/index.vue").then(m => m.default || m)
  },
  {
    path: ui_45kitUoIOLOltwdMeta?.path ?? "/ui-kit",
    children: [
  {
    name: componentsM0212bhKHNMeta?.name ?? "ui-kit-components",
    path: componentsM0212bhKHNMeta?.path ?? "components",
    children: [
  {
    name: brandslPaHIOMOVQMeta?.name ?? "ui-kit-components-brands",
    path: brandslPaHIOMOVQMeta?.path ?? "brands",
    meta: brandslPaHIOMOVQMeta || {},
    alias: brandslPaHIOMOVQMeta?.alias || [],
    redirect: brandslPaHIOMOVQMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/brands.vue").then(m => m.default || m)
  },
  {
    name: category_45cardJwybzgSZxyMeta?.name ?? "ui-kit-components-category-card",
    path: category_45cardJwybzgSZxyMeta?.path ?? "category-card",
    meta: category_45cardJwybzgSZxyMeta || {},
    alias: category_45cardJwybzgSZxyMeta?.alias || [],
    redirect: category_45cardJwybzgSZxyMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/category-card.vue").then(m => m.default || m)
  },
  {
    name: choicenYoH2o3SBWMeta?.name ?? "ui-kit-components-choice",
    path: choicenYoH2o3SBWMeta?.path ?? "choice",
    meta: choicenYoH2o3SBWMeta || {},
    alias: choicenYoH2o3SBWMeta?.alias || [],
    redirect: choicenYoH2o3SBWMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/choice.vue").then(m => m.default || m)
  },
  {
    name: data_45import_45sourceTuQGTEUyIqMeta?.name ?? "ui-kit-components-data-import-source",
    path: data_45import_45sourceTuQGTEUyIqMeta?.path ?? "data-import-source",
    meta: data_45import_45sourceTuQGTEUyIqMeta || {},
    alias: data_45import_45sourceTuQGTEUyIqMeta?.alias || [],
    redirect: data_45import_45sourceTuQGTEUyIqMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/data-import-source.vue").then(m => m.default || m)
  },
  {
    name: drawerAKDjh2uw4DMeta?.name ?? "ui-kit-components-drawer",
    path: drawerAKDjh2uw4DMeta?.path ?? "drawer",
    meta: drawerAKDjh2uw4DMeta || {},
    alias: drawerAKDjh2uw4DMeta?.alias || [],
    redirect: drawerAKDjh2uw4DMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/drawer.vue").then(m => m.default || m)
  },
  {
    name: errorrsDYfjd7HXMeta?.name ?? "ui-kit-components-error",
    path: errorrsDYfjd7HXMeta?.path ?? "error",
    meta: errorrsDYfjd7HXMeta || {},
    alias: errorrsDYfjd7HXMeta?.alias || [],
    redirect: errorrsDYfjd7HXMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/error.vue").then(m => m.default || m)
  },
  {
    name: expansion_45panelqdZGbLSQqFMeta?.name ?? "ui-kit-components-expansion-panel",
    path: expansion_45panelqdZGbLSQqFMeta?.path ?? "expansion-panel",
    meta: expansion_45panelqdZGbLSQqFMeta || {},
    alias: expansion_45panelqdZGbLSQqFMeta?.alias || [],
    redirect: expansion_45panelqdZGbLSQqFMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/expansion-panel.vue").then(m => m.default || m)
  },
  {
    name: filesNtvBKFnyAwMeta?.name ?? "ui-kit-components-files",
    path: filesNtvBKFnyAwMeta?.path ?? "files",
    meta: filesNtvBKFnyAwMeta || {},
    alias: filesNtvBKFnyAwMeta?.alias || [],
    redirect: filesNtvBKFnyAwMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/files.vue").then(m => m.default || m)
  },
  {
    name: filtersdj27YZCG6tMeta?.name ?? "ui-kit-components-filters",
    path: filtersdj27YZCG6tMeta?.path ?? "filters",
    meta: filtersdj27YZCG6tMeta || {},
    alias: filtersdj27YZCG6tMeta?.alias || [],
    redirect: filtersdj27YZCG6tMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/filters.vue").then(m => m.default || m)
  },
  {
    name: imageseNtZ75YRu1Meta?.name ?? "ui-kit-components-images",
    path: imageseNtZ75YRu1Meta?.path ?? "images",
    meta: imageseNtZ75YRu1Meta || {},
    alias: imageseNtZ75YRu1Meta?.alias || [],
    redirect: imageseNtZ75YRu1Meta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/images.vue").then(m => m.default || m)
  },
  {
    name: info_45frameOd7b83Ht5aMeta?.name ?? "ui-kit-components-info-frame",
    path: info_45frameOd7b83Ht5aMeta?.path ?? "info-frame",
    meta: info_45frameOd7b83Ht5aMeta || {},
    alias: info_45frameOd7b83Ht5aMeta?.alias || [],
    redirect: info_45frameOd7b83Ht5aMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/info-frame.vue").then(m => m.default || m)
  },
  {
    name: modalsVDY2WqYF3cMeta?.name ?? "ui-kit-components-modals",
    path: modalsVDY2WqYF3cMeta?.path ?? "modals",
    meta: modalsVDY2WqYF3cMeta || {},
    alias: modalsVDY2WqYF3cMeta?.alias || [],
    redirect: modalsVDY2WqYF3cMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/modals.vue").then(m => m.default || m)
  },
  {
    name: notification_45messageIN1RpCE80mMeta?.name ?? "ui-kit-components-notification-message",
    path: notification_45messageIN1RpCE80mMeta?.path ?? "notification-message",
    meta: notification_45messageIN1RpCE80mMeta || {},
    alias: notification_45messageIN1RpCE80mMeta?.alias || [],
    redirect: notification_45messageIN1RpCE80mMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/notification-message.vue").then(m => m.default || m)
  },
  {
    name: pagination5qBo2ao9l7Meta?.name ?? "ui-kit-components-pagination",
    path: pagination5qBo2ao9l7Meta?.path ?? "pagination",
    meta: pagination5qBo2ao9l7Meta || {},
    alias: pagination5qBo2ao9l7Meta?.alias || [],
    redirect: pagination5qBo2ao9l7Meta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/pagination.vue").then(m => m.default || m)
  },
  {
    name: productUkYHZaVRrrMeta?.name ?? "ui-kit-components-product",
    path: productUkYHZaVRrrMeta?.path ?? "product",
    meta: productUkYHZaVRrrMeta || {},
    alias: productUkYHZaVRrrMeta?.alias || [],
    redirect: productUkYHZaVRrrMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/product.vue").then(m => m.default || m)
  },
  {
    name: recaptchamZmQ4nwyrpMeta?.name ?? "ui-kit-components-recaptcha",
    path: recaptchamZmQ4nwyrpMeta?.path ?? "recaptcha",
    meta: recaptchamZmQ4nwyrpMeta || {},
    alias: recaptchamZmQ4nwyrpMeta?.alias || [],
    redirect: recaptchamZmQ4nwyrpMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/recaptcha.vue").then(m => m.default || m)
  },
  {
    name: scrolling_45listyjlzx1Ai5uMeta?.name ?? "ui-kit-components-scrolling-list",
    path: scrolling_45listyjlzx1Ai5uMeta?.path ?? "scrolling-list",
    meta: scrolling_45listyjlzx1Ai5uMeta || {},
    alias: scrolling_45listyjlzx1Ai5uMeta?.alias || [],
    redirect: scrolling_45listyjlzx1Ai5uMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/scrolling-list.vue").then(m => m.default || m)
  },
  {
    name: select4QfjXxikpxMeta?.name ?? "ui-kit-components-select",
    path: select4QfjXxikpxMeta?.path ?? "select",
    meta: select4QfjXxikpxMeta || {},
    alias: select4QfjXxikpxMeta?.alias || [],
    redirect: select4QfjXxikpxMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/select.vue").then(m => m.default || m)
  },
  {
    name: tablesRz50vkaooDMeta?.name ?? "ui-kit-components-tables",
    path: tablesRz50vkaooDMeta?.path ?? "tables",
    meta: tablesRz50vkaooDMeta || {},
    alias: tablesRz50vkaooDMeta?.alias || [],
    redirect: tablesRz50vkaooDMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/tables.vue").then(m => m.default || m)
  },
  {
    name: toggle_45switche92NyiDZj2Meta?.name ?? "ui-kit-components-toggle-switch",
    path: toggle_45switche92NyiDZj2Meta?.path ?? "toggle-switch",
    meta: toggle_45switche92NyiDZj2Meta || {},
    alias: toggle_45switche92NyiDZj2Meta?.alias || [],
    redirect: toggle_45switche92NyiDZj2Meta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/toggle-switch.vue").then(m => m.default || m)
  },
  {
    name: treeviewIApL8eqL9BMeta?.name ?? "ui-kit-components-treeview",
    path: treeviewIApL8eqL9BMeta?.path ?? "treeview",
    meta: treeviewIApL8eqL9BMeta || {},
    alias: treeviewIApL8eqL9BMeta?.alias || [],
    redirect: treeviewIApL8eqL9BMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components/treeview.vue").then(m => m.default || m)
  }
],
    meta: componentsM0212bhKHNMeta || {},
    alias: componentsM0212bhKHNMeta?.alias || [],
    redirect: componentsM0212bhKHNMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/components.vue").then(m => m.default || m)
  },
  {
    name: controlsObPzpvOxtuMeta?.name ?? "ui-kit-controls",
    path: controlsObPzpvOxtuMeta?.path ?? "controls",
    children: [
  {
    name: actionsRBmSU0HJhtMeta?.name ?? "ui-kit-controls-actions",
    path: actionsRBmSU0HJhtMeta?.path ?? "actions",
    meta: actionsRBmSU0HJhtMeta || {},
    alias: actionsRBmSU0HJhtMeta?.alias || [],
    redirect: actionsRBmSU0HJhtMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/controls/actions.vue").then(m => m.default || m)
  },
  {
    name: breadcrumbsOqDJPc3koFMeta?.name ?? "ui-kit-controls-breadcrumbs",
    path: breadcrumbsOqDJPc3koFMeta?.path ?? "breadcrumbs",
    meta: breadcrumbsOqDJPc3koFMeta || {},
    alias: breadcrumbsOqDJPc3koFMeta?.alias || [],
    redirect: breadcrumbsOqDJPc3koFMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/controls/breadcrumbs.vue").then(m => m.default || m)
  },
  {
    name: checkboxes7D9A4mo8k8Meta?.name ?? "ui-kit-controls-checkboxes",
    path: checkboxes7D9A4mo8k8Meta?.path ?? "checkboxes",
    meta: checkboxes7D9A4mo8k8Meta || {},
    alias: checkboxes7D9A4mo8k8Meta?.alias || [],
    redirect: checkboxes7D9A4mo8k8Meta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/controls/checkboxes.vue").then(m => m.default || m)
  },
  {
    name: radiogroupo5uuyYIKvLMeta?.name ?? "ui-kit-controls-radiogroup",
    path: radiogroupo5uuyYIKvLMeta?.path ?? "radiogroup",
    meta: radiogroupo5uuyYIKvLMeta || {},
    alias: radiogroupo5uuyYIKvLMeta?.alias || [],
    redirect: radiogroupo5uuyYIKvLMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/controls/radiogroup.vue").then(m => m.default || m)
  }
],
    meta: controlsObPzpvOxtuMeta || {},
    alias: controlsObPzpvOxtuMeta?.alias || [],
    redirect: controlsObPzpvOxtuMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/controls.vue").then(m => m.default || m)
  },
  {
    name: indexNqBcMTitwtMeta?.name ?? "ui-kit",
    path: indexNqBcMTitwtMeta?.path ?? "",
    meta: indexNqBcMTitwtMeta || {},
    alias: indexNqBcMTitwtMeta?.alias || [],
    redirect: indexNqBcMTitwtMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/index.vue").then(m => m.default || m)
  },
  {
    name: inputsQYeuGv6XyiMeta?.name ?? "ui-kit-inputs",
    path: inputsQYeuGv6XyiMeta?.path ?? "inputs",
    children: [
  {
    name: date_45pickercDrP1cJpc2Meta?.name ?? "ui-kit-inputs-date-picker",
    path: date_45pickercDrP1cJpc2Meta?.path ?? "date-picker",
    meta: date_45pickercDrP1cJpc2Meta || {},
    alias: date_45pickercDrP1cJpc2Meta?.alias || [],
    redirect: date_45pickercDrP1cJpc2Meta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/inputs/date-picker.vue").then(m => m.default || m)
  },
  {
    name: file_45uploaderUsnubXngQkMeta?.name ?? "ui-kit-inputs-file-uploader",
    path: file_45uploaderUsnubXngQkMeta?.path ?? "file-uploader",
    meta: file_45uploaderUsnubXngQkMeta || {},
    alias: file_45uploaderUsnubXngQkMeta?.alias || [],
    redirect: file_45uploaderUsnubXngQkMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/inputs/file-uploader.vue").then(m => m.default || m)
  },
  {
    name: othereEc89QxPEIMeta?.name ?? "ui-kit-inputs-other",
    path: othereEc89QxPEIMeta?.path ?? "other",
    meta: othereEc89QxPEIMeta || {},
    alias: othereEc89QxPEIMeta?.alias || [],
    redirect: othereEc89QxPEIMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/inputs/other.vue").then(m => m.default || m)
  },
  {
    name: text_45editorPycMbcjOMPMeta?.name ?? "ui-kit-inputs-text-editor",
    path: text_45editorPycMbcjOMPMeta?.path ?? "text-editor",
    meta: text_45editorPycMbcjOMPMeta || {},
    alias: text_45editorPycMbcjOMPMeta?.alias || [],
    redirect: text_45editorPycMbcjOMPMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/inputs/text-editor.vue").then(m => m.default || m)
  },
  {
    name: vee_45validatecwAE7wO4dgMeta?.name ?? "ui-kit-inputs-vee-validate",
    path: vee_45validatecwAE7wO4dgMeta?.path ?? "vee-validate",
    meta: vee_45validatecwAE7wO4dgMeta || {},
    alias: vee_45validatecwAE7wO4dgMeta?.alias || [],
    redirect: vee_45validatecwAE7wO4dgMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/inputs/vee-validate.vue").then(m => m.default || m)
  }
],
    meta: inputsQYeuGv6XyiMeta || {},
    alias: inputsQYeuGv6XyiMeta?.alias || [],
    redirect: inputsQYeuGv6XyiMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/inputs.vue").then(m => m.default || m)
  },
  {
    name: visualsGeOSWCyFraMeta?.name ?? "ui-kit-visuals",
    path: visualsGeOSWCyFraMeta?.path ?? "visuals",
    children: [
  {
    name: bannersH8yWFA4paJMeta?.name ?? "ui-kit-visuals-banners",
    path: bannersH8yWFA4paJMeta?.path ?? "banners",
    meta: bannersH8yWFA4paJMeta || {},
    alias: bannersH8yWFA4paJMeta?.alias || [],
    redirect: bannersH8yWFA4paJMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/visuals/banners.vue").then(m => m.default || m)
  },
  {
    name: buttons1tUMAYiOxXMeta?.name ?? "ui-kit-visuals-buttons",
    path: buttons1tUMAYiOxXMeta?.path ?? "buttons",
    meta: buttons1tUMAYiOxXMeta || {},
    alias: buttons1tUMAYiOxXMeta?.alias || [],
    redirect: buttons1tUMAYiOxXMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/visuals/buttons.vue").then(m => m.default || m)
  },
  {
    name: iconsc0AdboYmzkMeta?.name ?? "ui-kit-visuals-icons",
    path: iconsc0AdboYmzkMeta?.path ?? "icons",
    meta: iconsc0AdboYmzkMeta || {},
    alias: iconsc0AdboYmzkMeta?.alias || [],
    redirect: iconsc0AdboYmzkMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/visuals/icons.vue").then(m => m.default || m)
  },
  {
    name: informationtipTZlk6l2E4sMeta?.name ?? "ui-kit-visuals-informationtip",
    path: informationtipTZlk6l2E4sMeta?.path ?? "informationtip",
    meta: informationtipTZlk6l2E4sMeta || {},
    alias: informationtipTZlk6l2E4sMeta?.alias || [],
    redirect: informationtipTZlk6l2E4sMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/visuals/informationtip.vue").then(m => m.default || m)
  },
  {
    name: loadernEigWrh2qMMeta?.name ?? "ui-kit-visuals-loader",
    path: loadernEigWrh2qMMeta?.path ?? "loader",
    meta: loadernEigWrh2qMMeta || {},
    alias: loadernEigWrh2qMMeta?.alias || [],
    redirect: loadernEigWrh2qMMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/visuals/loader.vue").then(m => m.default || m)
  },
  {
    name: rulesGzb8tVdlkpMeta?.name ?? "ui-kit-visuals-rules",
    path: rulesGzb8tVdlkpMeta?.path ?? "rules",
    meta: rulesGzb8tVdlkpMeta || {},
    alias: rulesGzb8tVdlkpMeta?.alias || [],
    redirect: rulesGzb8tVdlkpMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/visuals/rules.vue").then(m => m.default || m)
  },
  {
    name: scrollbaraHGzJ0Lc9oMeta?.name ?? "ui-kit-visuals-scrollbar",
    path: scrollbaraHGzJ0Lc9oMeta?.path ?? "scrollbar",
    meta: scrollbaraHGzJ0Lc9oMeta || {},
    alias: scrollbaraHGzJ0Lc9oMeta?.alias || [],
    redirect: scrollbaraHGzJ0Lc9oMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/visuals/scrollbar.vue").then(m => m.default || m)
  },
  {
    name: skeletonIAPvi21f8uMeta?.name ?? "ui-kit-visuals-skeleton",
    path: skeletonIAPvi21f8uMeta?.path ?? "skeleton",
    meta: skeletonIAPvi21f8uMeta || {},
    alias: skeletonIAPvi21f8uMeta?.alias || [],
    redirect: skeletonIAPvi21f8uMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/visuals/skeleton.vue").then(m => m.default || m)
  },
  {
    name: statusblockUnM7vwqlGHMeta?.name ?? "ui-kit-visuals-statusblock",
    path: statusblockUnM7vwqlGHMeta?.path ?? "statusblock",
    meta: statusblockUnM7vwqlGHMeta || {},
    alias: statusblockUnM7vwqlGHMeta?.alias || [],
    redirect: statusblockUnM7vwqlGHMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/visuals/statusblock.vue").then(m => m.default || m)
  },
  {
    name: stylesRj4fp9guEaMeta?.name ?? "ui-kit-visuals-styles",
    path: stylesRj4fp9guEaMeta?.path ?? "styles",
    meta: stylesRj4fp9guEaMeta || {},
    alias: stylesRj4fp9guEaMeta?.alias || [],
    redirect: stylesRj4fp9guEaMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/visuals/styles.vue").then(m => m.default || m)
  },
  {
    name: tabsEdAN1DBAXEMeta?.name ?? "ui-kit-visuals-tabs",
    path: tabsEdAN1DBAXEMeta?.path ?? "tabs",
    meta: tabsEdAN1DBAXEMeta || {},
    alias: tabsEdAN1DBAXEMeta?.alias || [],
    redirect: tabsEdAN1DBAXEMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/visuals/tabs.vue").then(m => m.default || m)
  }
],
    meta: visualsGeOSWCyFraMeta || {},
    alias: visualsGeOSWCyFraMeta?.alias || [],
    redirect: visualsGeOSWCyFraMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit/visuals.vue").then(m => m.default || m)
  }
],
    name: ui_45kitUoIOLOltwdMeta?.name ?? undefined,
    meta: ui_45kitUoIOLOltwdMeta || {},
    alias: ui_45kitUoIOLOltwdMeta?.alias || [],
    redirect: ui_45kitUoIOLOltwdMeta?.redirect || undefined,
    component: () => import("/app/src/shared/pages/ui-kit.vue").then(m => m.default || m)
  }
]