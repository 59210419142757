import { Ref } from 'vue';
import { IColumnHeader } from 'shared/models/table.model';
import { ILimitInfo } from 'models/basket.model';

export abstract class BaseBasketCategory<T = Record<string, unknown>> {
  public categoryName = ref<string>();
  public limitInfo: ILimitInfo = {
    limit: ref(),
    delta: ref(),
    spent: ref(),
    limitInfoDetails: ref(),
    isOverPermissible: ref(),
  };

  abstract tableData: Ref<Array<T>>;
  abstract headerList: Ref<IColumnHeader[]>;
}