<template>
  <NuxtImg
    ref="imageComponentRef"
    :src="src"
    :alt="alt"
    :placeholder="placeholder"
    :sizes="sizes"
    :preset="preset"
    :format="format"
    :quality="quality"
    :fit="fit"
    :preload="preload"
    :width="width"
    :height="height"
    :loading="loading"
    @load="$emit('onImageLoad', imageComponentRef?.$el)"
  />
</template>

<script lang="ts" setup>
interface IBaseImgProps {
  src: string,
  alt?: string;
  placeholder?: string | boolean | number | _VTI_TYPE_Array,
  sizes?: string,
  preset?: string,
  format?: 'webp' | 'avif' | 'jpeg' | 'jpg' | 'png' | 'gif' | 'svg',
  quality?: number,
  fit?: string,
  preload?: boolean,
  loading?: string,
  width?: number,
  height?: number,
}
const imageComponentRef = ref<HTMLImageElement>();
defineEmits<{
  (e: 'onImageLoad', value: HTMLImageElement): void;
}>();
// Документация для props - https://image.nuxtjs.org/components/nuxt-img#props
withDefaults(defineProps<IBaseImgProps>(), { alt: '' });
</script>
