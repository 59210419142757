import IMask from 'imask';
import { EInputMasks } from 'shared/enums/inputMasks.enum';

export function getValueByMask(phone: string, options: IMask.AnyMaskedOptions): string {
  const input = document.createElement('input');
  input.value = phone;
  const mask = IMask(input, options);
  return mask.value;
}

export function maskedText(text: string, mask = EInputMasks.Phone): string {
  const options = {
    mask: mask,
  } as IMask.AnyMaskedOptions;

  const input = document.createElement('input');
  input.value = text;
  const currentMask = IMask(input, options);
  return currentMask.value;
}
