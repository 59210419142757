import {
  IAuthor,
  IBankRequisites,
  IFileSupplier,
  IFinanceReport,
  IOkved,
  IOwner,
  ISupplierInfo,
  ISupplierInfoResponse,
  ISupplierNameUpdateRequest,
} from 'models/supplier/supplier.model';
import { IFilesValidateResponse } from 'server/models/filesApiValidate.model';
import { ICatalogData, ICatalogsParams, ICatalogsResponse, ICatalogWarehouse } from 'models/catalog.model';
import { ICatalog } from 'shared/models/catalog.model';
import { ECatalogStatus } from 'shared/enums/catalogStatuses.enum';
import { ESupplierResponseTypes } from 'enums/supplier/supplierResponseTypes.enum';
import { FileUtil } from 'shared/utils/file.util';
import { IMappedCert } from 'shared/models/sign-esd/certificate.model';
import { SignEsd } from 'shared/services/signEsd.service';
import { StringsHelper } from 'shared/utils/strings.util';
import axios from 'axios';
import { AuthHelper } from 'utils/authHelper.util';
import { IBrand } from 'models/brand.model';
import { IBasePagedResponse } from 'shared/models/baseApi.model';
import { ICreateSupplierEmployeeData, ISupplierEmployee, ISupplierEmployeeParams } from 'models/supplier/supplierEmployees.model';
import { ISupplierOfferPoliciesParams, ISupplierOfferPolicy } from 'models/supplier/supplierOfferPolicy.model';
import { ISupplierRegistrationFromFormData } from 'models/supplier/supplierRegistrationFromFormData.model';
import { ESupplierOrderRuleType } from 'enums/supplier/supplierOrderRuleType.enum';
import { ISupplierOrderRules, ISupplierOrderRulesUpdate } from 'models/supplier/supplierOrderRules.model';

export class SupplierApiService {
  public static async getSupplierStatusInfo(): Promise<{ supplierInfo: ISupplierInfoResponse }> {
    return await AuthHelper.fetch<{ supplierInfo: ISupplierInfoResponse }>('/api/v1/suppliers/info');
  }

  public static async confirmOffer(): Promise<ISupplierInfoResponse> {
    return await AuthHelper.fetch<ISupplierInfoResponse>('/api/v1/suppliers/confirm_offer');
  }

  public static async getSupplierInfo(): Promise<{ supplier: ISupplierInfo }> {
    const { supplierInfo } = await this.getSupplierStatusInfo();
    return await AuthHelper.fetch<{ supplier: ISupplierInfo }>(`/api/v1/suppliers/${supplierInfo?.id}`);
  }

  public static async getSupplierInfoById(supplierId: string): Promise<ISupplierInfo> {
    return (await AuthHelper.fetch<{ supplier: ISupplierInfo }>(`/api/v1/suppliers/${supplierId}`))?.supplier;
  }

  public static async getSupplierXml(): Promise<{ supplier: string, supplierId?: string }> {
    const { supplierInfo } = await this.getSupplierStatusInfo();
    return await AuthHelper.fetch<{ supplier: string, supplierId?: string }>(`/api/v1/suppliers/${supplierInfo?.id}`, {
      params: {
        format: ESupplierResponseTypes.Xml,
      },
    });
  }

  public static async signSupplier(cert: IMappedCert) {
    const { supplier, supplierId } = await this.getSupplierXml();

    const supplierBlob = new Blob([supplier], { type: 'text/xml;charset=utf-8' });
    const xmlSupplierFile = new File([supplierBlob], `${supplierId}.xml`);
    const supplierBase64 = await FileUtil.fileToBase64(xmlSupplierFile);

    const signature = await SignEsd.signDetached(StringsHelper.toBase64ForSign(supplierBase64 as string), cert.thumbprint);
    const signatureBlob = new Blob([signature], { type: 'text/plain;charset=utf-8' });
    const signatureFile = new File([signatureBlob], `${supplierId}.xml.sig`);

    const formData = new FormData();
    formData.append('data_file', xmlSupplierFile);
    formData.append('sign_file', signatureFile);

    return await axios.post(`/api/v1/suppliers/${supplierId}/sign/`, formData, {
      headers: { 'ContentType': 'Multipart/form-data' },
    });
  }

  public static async updateSupplier(id: number, supplier: ISupplierInfo): Promise<ISupplierInfo> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${id}`, {
      method: 'PATCH',
      body: supplier,
    });
  }

  public static async updateSupplierBankRequisitesStep(id: number, requisites: IBankRequisites, supplier: ISupplierInfo, requisitesId?: number) {
    await SupplierApiService.updateSupplier(id, supplier);

    if (requisitesId != null) {
      return await SupplierApiService.updateBankRequisites(requisitesId, requisites);
    }
    return await SupplierApiService.createSupplierBankRequisites(id, requisites);
  }

  public static async createSupplierFromDadata(inn: string) {
    return await AuthHelper.fetch('/api/v1/suppliers/from_dadata', {
      method: 'POST',
      body: {
        inn,
      },
    });
  }

  public static async createSupplierFromDadataV2(inn: string): Promise<void> {
    return await AuthHelper.fetch('/api/v2/suppliers/from_dadata', {
      method: 'POST',
      body: {
        inn,
      },
    });
  }

  public static async createSupplierAuthor(id: number, author: Partial<IAuthor>, userName: ISupplierNameUpdateRequest): Promise<ISupplierInfo> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${id}/author`, {
      method: 'POST',
      body: [author, userName],
    });
  }

  public static async createSupplierBankRequisites(id: number, requisites: IBankRequisites): Promise<ISupplierInfo> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${id}/bank_requisites`, {
      method: 'POST',
      body: requisites,
    });
  }

  public static async addSupplierOwner(id: number, owner: IOwner): Promise<ISupplierInfo> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${id}/add_owner`, {
      method: 'POST',
      body: owner,
    });
  }

  public static async addSupplierOkved(id: number, okved: IOkved): Promise<ISupplierInfo> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${id}/add_okved`, {
      method: 'POST',
      body: okved,
    });
  }

  public static async addSupplierFile(id: number, file: Partial<IFileSupplier>): Promise<ISupplierInfo> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${id}/add_file`, {
      method: 'POST',
      body: file,
    });
  }

  public static async addSupplierFinanceReport(id: number, report: Omit<IFinanceReport, 'id'>): Promise<ISupplierInfo> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${id}/finance_report`, {
      method: 'POST',
      body: report,
    });
  }

  public static async validateSupplierFiles(id: number): Promise<IFilesValidateResponse> {
    return await AuthHelper.fetch(`/api/v1/suuppliers/${id}/files/validate`, {
      method: 'GET',
    });
  }

  public static async deleteFinanceReport(id: number): Promise<string> {
    return await AuthHelper.fetch(`/api/v1/finance_reports/${id}`, {
      method: 'DELETE',
    });
  }

  public static async updateFinanceReport(id: number, report: Partial<IFinanceReport>): Promise<string> {
    return await AuthHelper.fetch(`/api/v1/finance_reports/${id}`, {
      method: 'PATCH',
      body: report,
    });
  }

  public static async deleteAuthor(id: number): Promise<string> {
    return await AuthHelper.fetch(`/api/v1/authors/${id}`, {
      method: 'DELETE',
    });
  }

  public static async updateAuthor(id: number, author: Partial<IAuthor>, userName: ISupplierNameUpdateRequest): Promise<string> {
    return await AuthHelper.fetch(`/api/v1/authors/${id}`, {
      method: 'PATCH',
      body: [author, userName],
    });
  }

  public static async deleteBankRequisites(id: number): Promise<string> {
    return await AuthHelper.fetch(`/api/v1/bank_requisites/${id}`, {
      method: 'DELETE',
    });
  }

  public static async updateBankRequisites(id: number, requisites: IBankRequisites): Promise<string> {
    return await AuthHelper.fetch(`/api/v1/bank_requisites/${id}`, {
      method: 'PATCH',
      body: requisites,
    });
  }

  public static async deleteFile(id: number): Promise<string> {
    return await AuthHelper.fetch(`/api/v1/files/${id}`, {
      method: 'DELETE',
    });
  }

  public static async deleteOkved(id: number): Promise<string> {
    return await AuthHelper.fetch(`/api/v1/okveds/${id}`, {
      method: 'DELETE',
    });
  }

  public static async deleteOwner(id: number): Promise<string> {
    return await AuthHelper.fetch(`/api/v1/owners/${id}`, {
      method: 'DELETE',
    });
  }

  public static async updateOwner(id: number, owner: IOwner): Promise<string> {
    return await AuthHelper.fetch(`/api/v1/owners/${id}`, {
      method: 'PATCH',
      body: owner,
    });
  }

  public static get getBaseApiPath(): string {
    return '/api/v1';
  }

  public static makeApiPath(path: string): string {
    return `${this.getBaseApiPath}${path}`;
  }

  public static makeCatalogsPath(path = ''): string {
    return `${this.makeApiPath('/catalogs')}${path}`;
  }

  /*
   * @deprecated использовать V2
   * */
  public static async createCatalog(supplierId: number, name: string): Promise<ICatalogData> {
    return await AuthHelper.fetch(this.makeCatalogsPath(), {
      method: 'POST',
      params: { supplierId },
      body: { name },
    });
  }

  public static async createCatalogV2(supplierId: number, name: string): Promise<ICatalogData> {
    return await AuthHelper.fetch(
      `/api/v2/suppliers/${supplierId}/catalogs`,
      {
        method: 'POST',
        body: {
          name,
        },
      },
    );
  }

  /*
   * @deprecated использовать V2
   * */
  public static async deleteCatalog(catalogId: number): Promise<void> {
    return await AuthHelper.fetch(this.makeCatalogsPath(`/${catalogId}`), {
      method: 'DELETE',
    });
  }

  public static async deleteCatalogV2(catalogId: number, supplierId: number): Promise<void> {
    return await AuthHelper.fetch(
      `/api/v2/suppliers/${supplierId}/catalogs/${catalogId}`,
      {
        method: 'DELETE',
      },
    );
  }

  public static async getAllCatalogs(params: ICatalogsParams): Promise<ICatalogsResponse> {
    return await AuthHelper.fetch<ICatalogsResponse>(this.makeCatalogsPath(), {
      method: 'GET',
      params,
    });
  }

  /*
   * @deprecated использовать V2
   * */
  public static async changeCatalogStatus(id: number, status: ECatalogStatus): Promise<ICatalog> {
    return await AuthHelper.fetch<ICatalog>(this.makeCatalogsPath(`/${id}/status`), {
      method: 'PATCH',
      body: {
        status,
      },
    });
  }

  public static async changeCatalogStatusV2(catalogId: number, supplierId: number, status: ECatalogStatus): Promise<ICatalog> {
    return await AuthHelper.fetch<ICatalog>(
      `/api/v2/suppliers/${supplierId}/catalogs/${catalogId}/status`,
      {
        method: 'PATCH',
        body: {
          status,
        },
      },
    );
  }

  /*
   * @deprecated использовать V2
   * */
  public static async getCatalog(id: number, withMinSchedule = false): Promise<ICatalogData> {
    return await AuthHelper.fetch<ICatalogData>(this.makeCatalogsPath(`/${id}?withMinSchedule=${withMinSchedule}`), {
      method: 'GET',
    });
  }

  public static async getCatalogV2(catalogId: number, supplierId: number, withMinSchedule = false): Promise<ICatalogData> {
    return await AuthHelper.fetch<ICatalogData>(
      `/api/v2/suppliers/${supplierId}/catalogs/${catalogId}`,
      {
        method: 'GET',
        params: {
          withMinSchedule,
        },
      },
    );
  }

  /*
   * @deprecated использовать V2
   * */
  public static async updateCatalogWarehouse(id: number, body: ICatalogWarehouse): Promise<ICatalogWarehouse> {
    return await AuthHelper.fetch<ICatalogWarehouse>(this.makeCatalogsPath(`/${id}/warehouse`), {
      method: 'PATCH',
      body,
    });
  }

  public static async updateCatalogWarehouseV2(catalogId: number, supplierId: number, body: ICatalogWarehouse): Promise<ICatalogWarehouse> {
    return await AuthHelper.fetch<ICatalogWarehouse>(
      `/api/v2/suppliers/${supplierId}/catalogs/${catalogId}/warehouse`,
      {
        method: 'PATCH',
        body,
      },
    );
  }

  /*
   * @deprecated использовать V2
   * */
  public static async updateCatalog(id: number, body: Omit<ICatalogData, 'id' | 'name' | 'status'>): Promise<ICatalogData> {
    return await AuthHelper.fetch<ICatalogData>(this.makeCatalogsPath(`/${id}`), {
      method: 'PATCH',
      body,
    });
  }

  public static async updateCatalogV2(catalogId: number, supplierId: number, body: Omit<ICatalogData, 'id' | 'name' | 'status'>): Promise<ICatalogData> {
    return await AuthHelper.fetch<ICatalogData>(
      `/api/v2/suppliers/${supplierId}/catalogs/${catalogId}`,
      {
        method: 'PATCH',
        body,
      },
    );
  }

  /*
   * @deprecated использовать V2
   * */
  public static async validateCatalog(id: number): Promise<ICatalogData> {
    return await AuthHelper.fetch<ICatalogData>(this.makeCatalogsPath(`/${id}/validate`), {
      method: 'POST',
    });
  }

  public static async validateCatalogV2(catalogId: number, supplierId: number): Promise<ICatalogData> {
    return await AuthHelper.fetch<ICatalogData>(
      `/api/v2/suppliers/${supplierId}/catalogs/${catalogId}/validate`,
      {
        method: 'POST',
      },
    );
  }

  public static async getCatalogBrands(id: number, active = true): Promise<IBasePagedResponse<IBrand>> {
    return await AuthHelper.fetch<IBasePagedResponse<IBrand>>(this.makeCatalogsPath(`/${id}/brands`), {
      method: 'GET',
      params: { active },
    });
  }

  public static async deleteQualification(supplierId: number): Promise<void> {
    return await AuthHelper.fetch<void>(`/api/v1/suppliers/new/${supplierId}`, {
      method: 'DELETE',
    });
  }

  public static async getEmployees(supplierId: number, params: ISupplierEmployeeParams): Promise<IBasePagedResponse<ISupplierEmployee>> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${supplierId}/users`, {
      method: 'GET',
      params,
    });
  }

  public static async addEmployee(supplierId: number, supplierEmployeeData: ICreateSupplierEmployeeData): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${supplierId}/users`, {
      method: 'POST',
      body: supplierEmployeeData,
    });
  }

  public static async changeEmployeeStatus(supplierId: number, userSub: string, active: boolean): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${supplierId}/users/${userSub}/status`, {
      method: 'PATCH',
      params: { active },
    });
  }

  public static async sendEmployeePasswordLink(supplierId: number, userSub: string): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${supplierId}/users/${userSub}/invite`, {
      method: 'POST',
    });
  }

  public static async editEmployee(supplierId: number, userSub: string, supplierEmployeeData: ISupplierEmployee): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${supplierId}/users/${userSub}`, {
      method: 'PATCH',
      body: supplierEmployeeData,
    });
  }

  public static async deleteEmployee(supplierId: number, userSub: string): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${supplierId}/users/${userSub}`, {
      method: 'DELETE',
    });
  }

  public static async getSupplierAccess(supplierId: number, checkIsAdmin = true): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/suppliers/${supplierId}/access`, {
      method: 'GET',
      params: { isAdmin: checkIsAdmin },
    });
  }

  public static async createSupplierFromRegistrationForm(
    body: ISupplierRegistrationFromFormData,
  ): Promise<ISupplierInfo> {
    return await AuthHelper.fetch('/api/v2/suppliers/from_registration_form', {
      method: 'POST',
      body,
    });
  }

  public static async getOfferPolicies(
    params?: ISupplierOfferPoliciesParams,
  ): Promise<IBasePagedResponse<ISupplierOfferPolicy>> {
    return await AuthHelper.fetch('/api/v2/offer_policy/suppliers', {
      method: 'GET',
      params: params,
    });
  }

  public static async getOfferPoliciesForCurrentUser(): Promise<Array<ISupplierOfferPolicy>> {
    return await AuthHelper.fetch('/api/v2/offer_policy/current_user', {
      method: 'POST',
    });
  }

  public static async approveOfferPolicy(offerId: number): Promise<void> {
    return await AuthHelper.fetch('/api/v2/offer_policy/approve', {
      method: 'POST',
      body: {
        offerId,
      },
    });
  }

  public static async isCatalogHasModels(
    params: Record<string, unknown>,
  ): Promise<boolean> {
    return await AuthHelper.fetch('/api/v1/catalog/has_models', {
      method: 'GET',
      params,
    });
  }

  public static async updateVerifiedBankRequisites(supplierId: number, body: Partial<IBankRequisites>): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/bank_requisites/${supplierId}/verified`, {
      method: 'PATCH',
      body,
    });
  }

  public static async getSupplierFullInfo(): Promise<ISupplierInfo> {
    return await AuthHelper.fetch('/api/v2/suppliers/full_info', {
      method: 'POST',
    });
  }

  public static async getSupplierOrderRules(ruleType?: ESupplierOrderRuleType): Promise<ISupplierOrderRules> {
    return await AuthHelper.fetch('/api/v1/supplier_order_rules', {
      method: 'POST',
      params: {
        ruleType,
      },
    });
  }

  public static async updateSupplierOrderRules(rule: ISupplierOrderRulesUpdate, allOrders?: boolean): Promise<ISupplierOrderRules> {
    return await AuthHelper.fetch('/api/v1/supplier_order_rules', {
      method: 'PUT',
      params: {
        allOrders,
      },
      body: rule,
    });
  }
}
