import { ESignAlgorithms } from '../enums/sign-esd/signAlgorithms.enum';
import { IRawSubjectCertInfo } from '../models/sign-esd/certificate.model';

export class ESDCertsConsts {
  static readonly oidToKey: Record<string, keyof typeof ESignAlgorithms> = {
    '1.2.643.2.2.9': 'CADESCOM_HASH_ALGORITHM_CP_GOST_3411',
    '1.2.643.7.1.1.2.2': 'CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256',
    '1.2.643.7.1.1.2.3': 'CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512',
    '1.2.643.7.1.1.4.1': 'CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256_HMAC',
    '1.2.643.7.1.1.4.2': 'CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512_HMAC',
    '1.2.643.2.2.30.1': 'CADESCOM_HASH_ALGORITHM_CP_GOST_3411_HMAC',
    '1.2.643.7.1.1.1.1': 'CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256',
    '1.2.643.7.1.1.1.2': 'CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512',
  };

  static readonly translatedCertKeys: Record<string, keyof IRawSubjectCertInfo> = {
    'ИНН': 'INN',
    'ИНН ЮЛ': 'INNLE',
    'КПП': 'KPP',
    'ОГРН': 'OGRN',
    'ОГРН ИП': 'OGRNIP',
    'СНИЛС': 'SNILS',
  };
}
