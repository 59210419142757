export enum EBasketStatus {
  Default,
  EnoughProduct,
  LimitExceeded,
  MinQuantity,
  StepQuantity,
  Loading,
  Empty,
  MinSumNotReached,
  NoItemCount,
  ErpUser,
  NoDelivery,
  OutOfCount,
  NotChanged,
  NoStock,
  UpdateDataInNeed, // OCI mode
  AutoSelectionInProgress, // OCI: Идет процесс автоподбора корзины.
  MinCost,
  MaxCost,
  NotAcceptedUser,
  Success,
  LimitRequirementsNotMet,
  OfferGone,
}
