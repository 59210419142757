export enum EUserGroup {
  ClientAdmin = 'client_admin', // Администратор
  ClientErp = 'client_erp', // Покупатель ЕРП
  ClientMpErp = 'client_mp_erp', // Покупатель ЕРП МП
  ClientAuditor = 'client_auditor', // Аудитор
  ClientFinContr = 'client_fin_contr', // Финансовый контролер
  ClientBoss = 'client_boss', // Руководитель
  ClientCategoryManager = 'client_category_m', // Категорийный менеджер
  Supplier = 'supplier', // Поставщик менеджер
  MpLawyer = 'mp_lawyer', // Юрист
  MpContentAdmin = 'mp_content_admin', // Администратор контента
  MpAdmin = 'mp_admin', // Администратор МП
  MpContentFreelancer = 'mp_content_freelancer', // Фрилансер МП
}
