import { ECookieKeys } from 'shared/enums/cookieKeys';
import Notificator from 'shared/services/notificator.service';

export default defineNuxtRouteMiddleware((to) => {
  if (process.server) {
    return;
  }

  const notificator = inject<Notificator>(Notificator.getServiceName());
  // Согласно правилам https://developer.chrome.com/blog/cookie-max-age-expires/, кука может жить максимум 400 дней
  const isAcceptCookie = useCookie<boolean>(ECookieKeys.AcceptCookies, { maxAge: new Date().setDate(400) });

  function isShowAcceptCookiesNotification(): boolean {
    return !!(process.client && notificator && !isAcceptCookie.value && !to.path?.startsWith('/oci'));
  }

  isShowAcceptCookiesNotification() && notificator?.showAcceptCookiesNotification();
});
