<template>
  <Modal
    click-to-close
    title-text="Создание черновика"
    :z-index="10000"
    cancel-button-text="Отмена"
    ok-button-text="Создать черновик"
    width="574px"
    classes="modal fixed-bottom-modal bottom-0"
    :disabled-ok-button="okButtonDisabled"
    :disabled-cancel-button="clientCreateOrderFromXlsxService?.loadingCreateOrder.value"
    :disabled-close-button="clientCreateOrderFromXlsxService?.loadingCreateOrder.value"
    @close="$emit('close')"
    @cancel="$emit('close')"
    @ok="onCreateDraft"
  >
    <div
      class="mm-client-create-order-xlsx"
      :class="{
        'mm-client-create-order-xlsx--loading': loadingBases.value,
        'mm-client-create-order-xlsx--empty': basesIsEmpty
      }"
    >
      <ClipLoader
        v-if="loadingBases.value"
        class="mm-client-create-order-xlsx__loader"
      />

      <div
        v-else-if="!basesToShow.length"
        class="mm-client-create-order-xlsx__empty"
      >
        У вас нет базисов. Обратитесь к администратору.
      </div>

      <template v-else>
        <div class="mm-client-create-order-xlsx-file-uploader">
          <div class="mm-client-create-order-xlsx-file-uploader-header">
            <div class="mm-client-create-order-xlsx-file-uploader-header__title">
              Cформируйте заказ из Excel файла
            </div>
            <span
              class="mm-link mm-client-create-order-xlsx-file-uploader-header__link"
              :class="{
                'mm-client-create-order-xlsx-file-uploader-header__link--disabled': clientCreateOrderFromXlsxService?.downloadingFile.value
              }"
              @click="clientCreateOrderFromXlsxService?.downloadTemplate()"
            >
              Скачать шаблон
            </span>
          </div>
          <FileUploader
            button-text="Добавить новый файл"
            accept=".xlsx"
            class="mm-client-create-order-xlsx-file-uploader__uploader"
            :size="30"
            :max-allowed-files="1"
            :is-mobile-design="isMobileView"
            :is-disabled="clientCreateOrderFromXlsxService?.loadingCreateOrder.value"
            @on-change-file-list="onChangeFileList"
          >
            <span class="mm-client-create-order-xlsx-file-uploader__text">
              Файл Excel (XLSX)
            </span>
          </FileUploader>
        </div>

        <div class="mm-client-create-order-xlsx-basis">
          <div class="mm-client-create-order-xlsx-basis__title">
            Выберите базис поставки
          </div>
          <div class="mm-client-create-order-xlsx-basis__content">
            <Select
              v-model="selectedBase"
              placeholder="Адрес доставки"
              :options="basesToShow"
              :clearable="false"
              :searchable="false"
              :type="ESelectType.DescriptionList"
              :disabled="basesToShow.length < 2"
            >
              <template #selected-option-container="{ option }">
                <span class="vs__selected">
                  {{ option.selectedDescription }}
                </span>
              </template>
            </Select>

            <Select
              v-if="needSubdivision && subdivisionsToShow?.length"
              v-model="selectedSubdivision"
              placeholder="Подразделение"
              :options="subdivisionsToShow"
              :clearable="false"
              :searchable="false"
              :type="ESelectType.DescriptionList"
              :disabled="!selectedBase || subdivisionsToShow?.length < 2"
            />
          </div>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from 'shared/components/modals/Modal.vue';
import { FileTemp } from 'shared/services/fileTemp.service';
import WindowWidthManager from 'shared/services/windowWidth.service';
import BasesService from 'services/basesManager.service';
import { ESelectType } from 'shared/enums/select.enum';
import { UserHelper } from 'utils/userHelper.util';
import { useClientBasesForSelect } from 'composables/useClientBasesForSelect';
import { ClientCreateOrderFromXlsxService } from 'services/client/clientCreateOrderFromXlsx.service';
import { IClientCreateOrderFromXlxsParams } from 'models/client/clientCreateOrderXlsx.model';

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'create', file: File, params: IClientCreateOrderFromXlxsParams): void;
}>();

const { isMobile, isSmallMobile } = WindowWidthManager.getAdaptivaRefs();

const basesManager = inject<BasesService>(BasesService.getServiceName());
const clientCreateOrderFromXlsxService = inject<ClientCreateOrderFromXlsxService>(ClientCreateOrderFromXlsxService.getServiceName());

const {
  selectedBase,
  selectedSubdivision,
  loadingBases,
  basesToShow,
  subdivisionsToShow,
} = useClientBasesForSelect(
  basesManager?.currentUserBase?.id,
  basesManager?.currentUserBase?.subdivisionId,
  true,
);

const selectedFiles = ref<FileTemp[]>([]);

const needSubdivision = UserHelper.isAIM || !UserHelper.isIntegration;

const basesIsEmpty = computed(
  () => !loadingBases.value && !basesToShow.value.length,
);

const okButtonDisabled = computed(
  () => basesIsEmpty.value
    || selectedFiles.value.length === 0
    || !selectedBase.value
    || (needSubdivision
      && subdivisionsToShow.value?.length
      && !selectedSubdivision.value
    )
    || clientCreateOrderFromXlsxService?.loadingCreateOrder.value,
);

const isMobileView = computed(
  () => isMobile.value || isSmallMobile.value,
);

function onChangeFileList(fileList: FileTemp[]) {
  selectedFiles.value = fileList;
}

async function onCreateDraft(): Promise<void> {
  const file = selectedFiles.value[0];
  if (!file) {
    return;
  }

  emit('create', file as unknown as File, {
    basisId: selectedBase.value?.value as number,
    subdivisionId: selectedSubdivision.value?.value as number | undefined,
  });
}
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

.mm-client-create-order-xlsx {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 200px;

  &--loading,
  &--empty {
    align-items: center;
    justify-content: center;
  }

  &__empty {
    font-weight: 400;
    font-size: 14px;
    color: $text-black;
    line-height: 20px;
  }

  &__loader {
    position: static;
  }

  &-file-uploader {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      &__link {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        &--disabled {
          color: $text-disabled;
        }
      }
    }

    &__uploader {
      :deep(.file-uploader-wrapper) {
        padding: 24px;
        overflow: unset;

        .file-uploader-wrapper__control {
          align-items: center;
        }
      }
    }
  }

  &-basis {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
</style>
