import { POSITION } from 'vue-toastification/dist/index.mjs';
import { ENotificationActions } from '../enums/notificationActions.enum';

export default class NotificatorSettings {
  static readonly DEFAULT_NOTIFICATION_PLUGIN_SETTINGS = {
    containerClassName: 'mm-notification',
    transition: 'none',
    maxToasts: 5,
    newestOnTop: false,
    filterBeforeCreate: (toast, toasts) => toasts.filter((t) => t.type === toast.type).length === 0 && toast,
  };

  static readonly DEFAULT_NOTIFICATION_SETTINGS = {
    bodyClassName: 'mm-notification__body',
    toastClassName: 'mm-notification__toast',
    position: POSITION.BOTTOM_CENTER,
    hideProgressBar: true,
    timeout: 600000,
    closeOnClick: false,
    draggable: false,
  };

  static readonly CONTENT_FIT_NOTIFICATION_SETTINGS = {
    ...this.DEFAULT_NOTIFICATION_SETTINGS,
    toastClassName: 'mm-notification__toast mm-notification__toast--content-fit',
  };

  private static messageData = {
    [ENotificationActions.UnauthorizedBasket]: 'Для просмотра корзины',
    [ENotificationActions.UnauthorizedProposal]: 'Для просмотра актуальных предложений, стоимости и сроков доставки',
    [ENotificationActions.SuccessfulVerified]: 'Ваш e-mail успешно подтвержден. Теперь вы можете',
  };

  private static linkMessageData = {
    [ENotificationActions.UnauthorizedBasket]: 'авторизуйтесь в системе',
    [ENotificationActions.UnauthorizedProposal]: 'авторизуйтесь в системе',
    [ENotificationActions.SuccessfulVerified]: 'авторизоваться',
  };

  public static getMessageByAction(action: ENotificationActions): string {
    return this.messageData[action] || '';
  }

  public static getLinkMessageByAction(action: ENotificationActions): string {
    return this.linkMessageData[action] || '';
  }
}
