export enum EInputMasks {
  Phone = '{+7}-(000)-000-00-00',
  PhoneSpaces = '{+7} (000) 000 00 00',
  ItemsQuantity = '000000000000',
  Inn = '0000000000',
  InnIp = '000000000000',
  Ogrn = '0000000000000',
  OgrnIp = '000000000000000',
  PhoneSpaced = '{+7} 000 000 00 00'
}
