import { ComputedRef, Ref } from 'vue';

export function readBodyClassModifier(watchingRef: Ref | ComputedRef, className: string): void {
  if (process.server){
    return;
  }

  watch(
    () => watchingRef.value,
    () => {
      watchingRef.value ? document.body.classList.add(className) : document.body.classList.remove(className);
    },
  );
}
