<template>
  <VueFinalModal
    v-slot="slots"
    v-bind="props"
    @update:model-value="$emit('update:modelValue', $event)"
    @click-outside="$emit('clickOutside')"
    @before-open="$emit('beforeOpen')"
    @opened="$emit('opened')"
    @before-close="$emit('beforeClose')"
    @closed="$emit('closed')"
  >
    <slot
      :params="slots?.params"
      :close="slots?.close"
    />
  </VueFinalModal>
</template>

<script lang="ts" setup>
interface _VTI_TYPE_CSSProperties {
    /**
     * The index signature was removed to enable closed typing for style
     * using CSSType. You're able to use type assertion or module augmentation
     * to add properties or an index signature of your own.
     *
     * For examples and more information, visit:
     * https://github.com/frenic/csstype#what-should-i-do-when-i-get-type-errors
     */
    [v: `--${string}`]: string | number | undefined;
}
type _VTI_TYPE_StyleValue = false | null | undefined | string | _VTI_TYPE_CSSProperties | _VTI_TYPE_Array
interface IBaseModalProps {
  contentClass?: string | object | _VTI_TYPE_Array;
  overlayClass?: string | object | _VTI_TYPE_Array;
  classes?: string | object | _VTI_TYPE_Array;
  styles?: _VTI_TYPE_StyleValue;
  overlayStyle?: _VTI_TYPE_StyleValue;
  contentStyle?: _VTI_TYPE_StyleValue;
  modelValue?: boolean;
  lockScroll?: boolean;
  hideOverlay?: boolean;
  clickToClose?: boolean;
  escToClose?: boolean;
  preventClick?: boolean;
  attach?: boolean;
  transition?: string;
  overlayTransition?: string;
  zIndexAuto?: boolean;
  zIndexBase?: number | string;
  zIndex?: string | number;
  focusRetain?: boolean;
  focusTrap?: boolean;
  ssr?: boolean;
  name?: string;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  keepOverlay?: boolean;
  closeModalId?: string;
  okBtnId?: string;
  cancelBtnId?: string;
}
import { VueFinalModal } from 'vue-final-modal'
/**
 * Описание пропсов см. https://vue-final-modal.org/guide/properties
 * Описание событий см. https://vue-final-modal.org/guide/events
 *
 * Данный компонент обертка над библиотекой vue-final-modal.
 * Для того чтобы отобразить данные в модалке необходимо в template default прокинуть свой шаблон.
 */
const props = withDefaults(defineProps<IBaseModalProps>(), {
  // z-index для модалки
  zIndexBase: 1000,
  // Для корректной работы события opened в nuxt ssr = true
  ssr: true,
});
defineEmits<{
  (e: 'clickOutside');
  (e: 'beforeOpen');
  (e: 'opened');
  (e: 'beforeClose');
  (e: 'closed');
  (e: 'update:modelValue', value: boolean);
}>();
</script>
