import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { App } from 'vue';
import { Router } from 'vue-router';
import { Breadcrumb, CaptureContext, ScopeContext, Severity, User } from '@sentry/types';
import { ClientSentryTransport } from './transports/clientSentryTransport.util';

export class ClientSentry {
  private _sentry: typeof Sentry | undefined;
  private ignoredBreadcrumbCategories = ['console'];

  private static instance?: ClientSentry;

  public static getInstance(): ClientSentry {
    ClientSentry.instance ??= new ClientSentry();

    return ClientSentry.instance;
  }

  public init(vueApp: App<Element>, $router: Router): void {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { sentryDsn, sentryAutoSessionTracking, sentryEnabled, environment } = useRuntimeConfig().public;
    const enabled = (sentryEnabled === undefined || sentryEnabled === '') ? true : Boolean(Number(sentryEnabled));

    if (process.env.NODE_ENV === 'development' || !enabled) {
      return;
    }

    if (!sentryDsn) {
      console.error('Not found sentry dsn in .env');
      return;
    }

    Sentry.init({
      app: vueApp,
      dsn: sentryDsn,
      enabled,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation($router),
          tracingOrigins: ['localhost', /^\//],
        }),
      ],
      sampleRate: 1.0,
      environment,
      tracesSampleRate: 1.0,
      release: '1.0.0',
      logErrors: false,
      transport: ClientSentryTransport,
      beforeSend: (event, hint) => this.beforeSendHandler(event, hint),
      beforeBreadcrumb: (breadcrumb) => this.beforeBreadcrumb(breadcrumb),
      normalizeMaxBreadth: 500,
      autoSessionTracking: Boolean((String(sentryAutoSessionTracking) || '')?.toLowerCase?.() === 'true'),
    });

    vueApp.mixin(
      Sentry.createTracingMixins({
        trackComponents: true,
        timeout: 2000,
        hooks: ['activate', 'mount', 'update'],
      }),
    );

    Sentry.attachErrorHandler(vueApp, {
      logErrors: false,
      attachProps: true,
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    });

    this._sentry = Sentry;
  }

  private beforeSendHandler(event: Sentry.Event, hint?: Sentry.EventHint): Sentry.Event {
    if (event.exception) {
      console.error(`[Client exception handled by Sentry]: (${hint?.originalException})`, { event, hint });
    }

    return event;
  }

  private beforeBreadcrumb(breadcrumb: Breadcrumb): Breadcrumb | null {
    if (breadcrumb.category && this.ignoredBreadcrumbCategories.includes(breadcrumb.category)) {
      return null;
    }

    return breadcrumb;
  }

  public get sentry(): typeof Sentry | undefined {
    return this._sentry;
  }

  public setUser(user: User | null): void {
    this.sentry?.setUser(user);
  }

  public captureException(exception: unknown, context?: CaptureContext): string | undefined {
    try {
      return this?.sentry?.captureException?.call(this, exception, context);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public captureServiceException(
    exception: unknown,
    serviceName: string,
    level = Severity.Error,
    context = {} as CaptureContext,
  ): string | undefined {
    return this.captureException(exception, {
      level,
      tags: {
        service: serviceName,
      },
      extra: {
        error: exception,
      },
      ...context,
    });
  }

  public captureComponentException(
    exception: unknown,
    component: string,
    context = {} as Partial<ScopeContext>,
  ): string | undefined {
    return this.captureException(exception, {
      ...context,
      extra: {
        ...(context?.extra || {}),
        error: exception,
      },
      tags: {
        ...(context?.tags || {}),
        component,
      },
    });
  }

  public captureComposableException(
    exception: unknown,
    composable: string,
    context = {} as Partial<ScopeContext>,
  ): string | undefined {
    return this.captureException(exception, {
      ...context,
      extra: {
        ...(context?.extra || {}),
        error: exception,
      },
      tags: {
        ...(context?.tags || {}),
        composable,
      },
    });
  }
}

export const clientSentry = ClientSentry.getInstance();
