<template>
  <Modal
    click-to-close
    z-index="21"
    title-text="Адрес доставки"
    hide-cancel-button
    ok-button-text="Сохранить выбор"
    :hide-close-button="isHideCloseButton"
    :disabled-ok-button="isButtonDisabled"
    :classes="['modal fixed-bottom-modal bottom-0', { 'd-none': isBaseChangeConfirmModalOpened }]"
    ok-btn-id="confirm_basis_button"
    disable-close-on-change-route
    @close="$emit('close')"
    @update:model-value="$emit('update:modelValue', false)"
    @ok="onConfirmBases"
  >
    <div class="delivery-address__main">
      <div
        v-if="loadingBases.value"
        class="loading"
      >
        Загрузка базисов...
      </div>

      <div
        v-else
        class="delivery-address__basis-list"
      >
        <div v-if="!basesToShow?.length">
          У вас нет базисов. Обратитесь к администратору.
        </div>

        <template v-else>
          <Select
            v-model="selectedBase"
            placeholder="Адрес доставки"
            :options="basesToShow"
            :clearable="false"
            :searchable="false"
            :type="ESelectType.DescriptionList"
            :disabled="basesToShow.length < 2"
          >
            <template #selected-option-container="{ option }">
              <span class="vs__selected">
                {{ option.selectedDescription }}
              </span>
            </template>
          </Select>

          <Select
            v-if="isNeedSubdivision && subdivisionsToShow?.length"
            v-model="selectedSubdivision"
            placeholder="Подразделение"
            :options="subdivisionsToShow"
            :clearable="false"
            :searchable="false"
            :type="ESelectType.DescriptionList"
            :disabled="!selectedBase || subdivisionsToShow?.length < 2"
          />
        </template>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from 'shared/components/modals/Modal.vue';
import { IDeliveryBase } from 'shared/models/bases.model';
import BasesService from 'services/basesManager.service';
import { ESelectType } from 'shared/enums/select.enum';
import { ISubdivision } from 'models/client/subdivisions.model';
import { UserHelper } from 'utils/userHelper.util';
import { useClientBasesForSelect } from 'composables/useClientBasesForSelect';
import { BasketManager } from 'services/basket-services/basket/basketManager.service';
import { Basket } from 'services/basket-services/basket/basket.service';

const basesManager = inject<BasesService>(BasesService.getServiceName());

const {
  basesToShow,
  subdivisionsToShow,
  loadingBases,
  selectedBase,
  selectedSubdivision,
} = useClientBasesForSelect(
  basesManager?.currentUserBase?.id,
  basesManager?.currentUserBase?.subdivisionId,
  true,
);

const isBaseChangeConfirmModalOpened = ref(false);
const isNeedSubdivision = ref(UserHelper.isAIM || !UserHelper.isIntegration);

const isButtonDisabled = computed(
  () => !selectedBase?.value
    || (isNeedSubdivision.value
      && !!subdivisionsToShow.value?.length
      && !selectedSubdivision.value
    )
    || basesManager?.savingBasis.value,
);

const isHideCloseButton = computed(
  () => !!basesToShow.value?.length
    && (!basesManager?.currentUserBase?.id
      || (
        isNeedSubdivision.value
          && !basesManager?.currentUserBase?.subdivisionId
          && !!subdivisionsToShow.value?.length
      )
    ),
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'close'): void;
  (e: 'ok'): void;
}>();

async function init() {
  if (isHideCloseButton.value &&
    ((selectedBase.value && basesToShow.value?.length === 1 && !isNeedSubdivision.value) ||
      (selectedBase.value &&
        basesToShow.value?.length === 1 &&
        isNeedSubdivision.value &&
        ((selectedSubdivision.value && subdivisionsToShow.value?.length === 1) || subdivisionsToShow.value?.length === 0)))
  ) {
    await updateBases();
  }
}

async function updateBases(): Promise<void> {
  const base = selectedBase?.value?.base as IDeliveryBase;
  const subdivision = selectedSubdivision?.value?.subdivision as ISubdivision;

  await basesManager?.saveUserBasis(base, subdivision);

  emit('close');
}

async function onConfirmBases(): Promise<void> {
  await updateBases();
  const basketManager = new BasketManager();
  await basketManager.refreshBasketData();
  await Basket.refreshBasket();
}

onMounted(async () => await init());
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

:deep(.delivery-modal) {
  position: fixed;
}

.delivery-address__main {
  .delivery-address__basis-list {
    margin: 0;
    padding: 0;
  }

  .loading {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .delivery-address__basis-item {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 12px;
    border-bottom: 1px solid $delivery-address-basis-item-bb-c;
    margin-bottom: 12px;
    cursor: pointer;

    &--selected {
      cursor: not-allowed;
    }

    .basis__selected-badge {
      position: absolute;
      right: 0;
      top: 0;
      color: $basis-selected-badge-c;
      background: $basis-selected-badge-bg;
      border-radius: 4px;
      padding: 2px 6px;
      font-size: 12px;
      line-height: 16px;
      font-style: normal;
      font-weight: 400;

      svg {
        ::v-deep(path) {
          stroke: $basis-selected-badge-c;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  :deep(.modal) {
    &:has(.delivery-address__main .vs--open) {
      .modal-content {
        height: 574px;
      }

      .modal-inner-content {
        height: 422px;
        overflow: unset;
        padding-right: 0;

        .vs__dropdown-toggle {
          margin-right: 20px;
        }
      }
    }

    .modal-footer {
      margin-top: 20px;
    }
  }

  .delivery-address__main {
    .delivery-address__basis-item {
      .basis__selected-badge {
        font-size: 0;
        height: 24px;
        margin-top: 12px;
        width: 24px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    :deep(.vs__dropdown-toggle) {
      .vs__selected-options {
        flex: 1;
        min-width: 0;

        .vs__selected {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }

      &+.vs__dropdown-menu {
        box-shadow: none;

        .vs__dropdown-option {
          padding-right: 20px;
        }
      }
    }
  }
}
</style>
