import revive_payload_client_Hjmf4WeR2N from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ySWanUoDla from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Or2oaplyd8 from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rpN6vpLoWw from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_I8AhPB5cLY from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_rZ2LG0wREi from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/src/projects/marketplace/.nuxt/components.plugin.mjs";
import prefetch_client_GGXNTOA1qA from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_6SX70DRKzB from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_XwpRSR25R6 from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_Wpj0cDp0U1 from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_Fi7AXeCZsc from "/app/src/shared/plugins/error.ts";
import sanitize_url_QDtzTlIew7 from "/app/src/shared/plugins/sanitize-url.ts";
import sanitize_3a9ExiXop9 from "/app/src/shared/plugins/sanitize.ts";
import sentry_client_zRNvvR1MqF from "/app/src/shared/plugins/sentry.client.ts";
import toastification_client_zL1Lfm21o3 from "/app/src/shared/plugins/toastification.client.ts";
import vue_final_modal_KlHYQLthu1 from "/app/src/shared/plugins/vue-final-modal.ts";
import vue_inject_client_9ypzvHmtRx from "/app/src/shared/plugins/vue-inject.client.ts";
import vue3_perfect_scrollbar_q7mpFrtbMY from "/app/src/shared/plugins/vue3-perfect-scrollbar.ts";
import vue3_popper_YTkYfBwttI from "/app/src/shared/plugins/vue3-popper.ts";
import ymaps_client_1YlVZzGYdQ from "/app/src/shared/plugins/ymaps.client.ts";
import initMaksifyPlugins_QsVNces1qH from "/app/src/projects/marketplace/plugins/initMaksifyPlugins.ts";
import vue_inject_client_Pihcoen97d from "/app/src/projects/marketplace/plugins/vue-inject.client.ts";
import vue_inject_cKMEoEAqgs from "/app/src/projects/marketplace/plugins/vue-inject.ts";
export default [
  revive_payload_client_Hjmf4WeR2N,
  unhead_ySWanUoDla,
  router_Or2oaplyd8,
  payload_client_rpN6vpLoWw,
  check_outdated_build_client_I8AhPB5cLY,
  plugin_vue3_rZ2LG0wREi,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GGXNTOA1qA,
  switch_locale_path_ssr_6SX70DRKzB,
  i18n_XwpRSR25R6,
  chunk_reload_client_Wpj0cDp0U1,
  error_Fi7AXeCZsc,
  sanitize_url_QDtzTlIew7,
  sanitize_3a9ExiXop9,
  sentry_client_zRNvvR1MqF,
  toastification_client_zL1Lfm21o3,
  vue_final_modal_KlHYQLthu1,
  vue_inject_client_9ypzvHmtRx,
  vue3_perfect_scrollbar_q7mpFrtbMY,
  vue3_popper_YTkYfBwttI,
  ymaps_client_1YlVZzGYdQ,
  initMaksifyPlugins_QsVNces1qH,
  vue_inject_client_Pihcoen97d,
  vue_inject_cKMEoEAqgs
]