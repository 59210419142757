<template>
  <Modal
    click-to-close
    :z-index="10"
    close-button-text="Закрыть"
    classes="modal fixed-bottom-modal bottom-0"
    class="mm-choose-approver-modal"
    cancel-button-text="Отменить"
    :disabled-ok-button="!selectedUser || disabledButton"
    @close="$emit('close')"
    @ok="onOk"
    @cancel="$emit('close')"
  >
    <div class="description">
      Некоторые товары в вашей корзине требуют согласования.<br> Выберите лицо согласующее заказ:
    </div>

    <SearchDeputy
      v-if="isLoaded.value"
      v-model="searchText"
      placeholder="Согласующее лицо"
      :is-custom-indicator="false"
      :is-clearable="false"
      :is-search-loading="true"
      :method-api="searchClientApprovers"
      loading-label="Ищем подходящего сотрудника"
      allow-empty-search
      @selected-option="selectedUser = $event"
      @deselected-option="selectedUser = undefined"
    />
  </Modal>
</template>

<script setup lang="ts">
import { ISelectOptionDeputy } from 'shared/models/select.model';
import Modal from 'shared/components/modals/Modal.vue';
import SearchDeputy from 'components/client/SearchDeputy.vue';
import { ClientApi } from 'services/api/clientApi.service';
import { IUserSearchInfo } from 'models/client/userSearchInfo.model';
import { StringsHelper } from 'shared/utils/strings.util';
import Loader from 'shared/utils/loaderHelper.util';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';
import { IBasisApproveRule } from 'models/client/approveRule.model';

const props = defineProps<{
  beId?: number;
  approveRule?: IBasisApproveRule;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'ok', value: string | undefined | IBasisApproveRule): void;
}>();

const disabledButton = ref(false);
const isLoaded = Loader.getReactiveInstance();

const watchSubscription = new WatchSubscription();
const searchText = ref('');
const selectedUser = ref<ISelectOptionDeputy | undefined>();
const approvers = ref<Array<IUserSearchInfo>>([]);

function onOk(): void {
  if (!selectedUser.value) {
    return;
  }

  disabledButton.value = true;
  if (props.approveRule) {
    return emits('ok', {
      ...props.approveRule,
      approveGroups: props.approveRule.approveGroups.map(
        (approveGroup) => ({
          ...approveGroup,
          approvers: approveGroup.isSelectable
            ? approveGroup.approvers.filter((approver) => approver === selectedUser.value.sub)
            : approveGroup.approvers,
        }),
      ),
    });
  }

  emits('ok', selectedUser.value?.sub);
}

function searchClientApprovers(searchText?: string): Array<IUserSearchInfo> {
  return approvers.value.filter((item) =>
    StringsHelper.arrayToString([item.lastName, item.name, item.patronymic], ' ')
    .trim()
    .toLocaleLowerCase()
    .includes(
      String(searchText)
      .trim()
      .toLocaleLowerCase(),
    ),
  );
}

async function loadApprovers(): Promise<void> {
  isLoaded.deactivate();
  try {
    approvers.value = await ClientApi.searchClientApprovers('', props.beId);
  } catch (e) {
    console.error(e);
  } finally {
    isLoaded.activate();
  }
}

function setApproversFromRule(): void {
  if (!props.approveRule) {
    return;
  }

  const selectableGroup = props.approveRule.approveGroups.find(
    (approveGroup) => approveGroup.isSelectable,
  );

  if (!selectableGroup || !selectableGroup.approversFio) {
    return;
  }

  approvers.value = selectableGroup.approversFio;
  isLoaded.activate();
}

async function init(): Promise<void> {
  if (props.approveRule) {
    return setApproversFromRule();
  }

  await loadApprovers();
}

watchSubscription.add(
  watch(
    () => searchText.value,
    (value) => {
      if (value !== selectedUser.value?.value) {
        searchText.value = '';
        selectedUser.value = undefined;
      }
    },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(async () => await init());
</script>

<style lang="scss" scoped>
@import 'shared/assets/styles/base/common/variables';

.mm-choose-approver-modal {
  .description {
    padding-bottom: 24px;
    font-size: 14px;
    line-height: 20px;
  }

  .mm-select {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .mm-choose-approver-modal {
    :deep(.modal) {
      &:has(.vs--open) {
        .modal-content {
          height: 574px;
        }

        .modal-inner-content {
          height: 422px;
          overflow: unset;
        }
      }

      .modal-footer {
        margin-top: 20px;
      }
    }
  }
}
</style>
