import { ESignEsdErrorCode } from '../../enums/services/signEsdErrorCode.enum';

export const SIGN_ESD_ERROR_MAP = new Map<ESignEsdErrorCode, (...args: Array<unknown>) => string>([
  [ESignEsdErrorCode.SetHashToHashContainer, () => 'Ошибка установки хэша в хэш-контейнер'],
  [ESignEsdErrorCode.DuringSignatureVerification, () => 'Ошибка в процессе верификации подписи'],
  [ESignEsdErrorCode.DuringCalculatingHash, () => 'Ошибка вычисления хэша переданных данных'],
  [ESignEsdErrorCode.DuringCreateDetachedSignature, (message) => `Ошибка в процессе создания открепленной подписи: ${message}`],
  [ESignEsdErrorCode.DuringCreateAttachedSignature, (message) => `Ошибка в процессе создания прикрепленной подписи: ${message}`],
  [ESignEsdErrorCode.FailedAccessCertificateContainer, () => 'Не удалось получить доступ к хранилищу сертификатов'],
  [ESignEsdErrorCode.NoCertificatesAvailable, () => 'Нет доступных сертификатов'],
  [ESignEsdErrorCode.ThumbpringCertificateNotFound, (thumbprint) => `Сертификат с отпечатком: '${thumbprint}' не найден`],
  [ESignEsdErrorCode.GetCertificateList, (message) => `Ошибка получения списка сертификатов: ${message}`],
  [ESignEsdErrorCode.PluginInitialization, () => 'Ошибка инициализации CAdES plugin browser'],
  [ESignEsdErrorCode.PluginNotInstalled, (message) => `Плагин CAdES plugin Browser не установлен: ${message}`],
  [ESignEsdErrorCode.InitPlugin, () => 'Ошибка инициализации CAdES plugin'],
]);
