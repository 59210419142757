import { BaseReactiveHelper } from './baseReactiveHelper.util';
import { reactive } from 'vue';

export default class Loader extends BaseReactiveHelper {
  public constructor(value = false) {
    super(value);
  }

  static getReactiveInstance(value = false): Loader {
    return reactive<Loader>(new Loader(value));
  }
}
